/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcCodeVariableType {
    Constant = 'CONSTANT',
    UniqueSequence = 'UNIQUE_SEQUENCE',
    Gtin = 'GTIN',
    CurrentHour = 'CURRENT_HOUR',
    ExtensionGtin = 'EXTENSION_GTIN',
    Gcp = 'GCP',
    GcpGtin = 'GCP_GTIN'
}

export function KvintaEpcCodeVariableTypeFromJSON(json: any): KvintaEpcCodeVariableType {
    return KvintaEpcCodeVariableTypeFromJSONTyped(json, false);
}

export function KvintaEpcCodeVariableTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcCodeVariableType {
    return json as KvintaEpcCodeVariableType;
}

export function KvintaEpcCodeVariableTypeToJSON(value?: KvintaEpcCodeVariableType | null): any {
    return value as any;
}

