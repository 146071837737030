/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCodePoolStatistic
 */
export interface KvintaCodePoolStatistic {
    /**
     * 
     * @type {string}
     * @memberof KvintaCodePoolStatistic
     */
    gtin: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaCodePoolStatistic
     */
    available: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaCodePoolStatistic
     */
    reserved: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaCodePoolStatistic
     */
    used: number;
}

export function KvintaCodePoolStatisticFromJSON(json: any): KvintaCodePoolStatistic {
    return KvintaCodePoolStatisticFromJSONTyped(json, false);
}

export function KvintaCodePoolStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCodePoolStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': json['gtin'],
        'available': json['available'],
        'reserved': json['reserved'],
        'used': json['used'],
    };
}

export function KvintaCodePoolStatisticToJSON(value?: KvintaCodePoolStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'available': value.available,
        'reserved': value.reserved,
        'used': value.used,
    };
}


