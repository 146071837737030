import React, { FC } from 'react';
import { KvintaHierarchyLevelType } from '../../apis/kvinta-load-test-toolkit';
import { ArrowDownButton, ArrowUpButton, DeleteButton } from '../IconButtons';
import { IntlShape } from 'react-intl';
import { TProductFormData } from '../../modules/configuration/products/ProductStore';
import styles from './ProductHierarchyLevel.module.scss';
import { ECheckboxState } from '../../common/formUtils/types';
import { CheckBoxInput } from '../FormFields/Fields_Exp/CheckBoxInput_Exp';
import { SelectInput } from '../FormFields/Fields_Exp/SelectInput_Exp';
import { TextInput } from '../FormFields/Fields_Exp/TextInput_Exp';
import { AutocompleteSelectInputExp } from '../FormFields/Fields_Exp/AutocompleteSelectInputExp';

type THierarchyLevelButtons = {
  disabled: boolean;
  formData: TProductFormData;
  arrayMemberId: string;
  removeMember: (memberId: string) => void;
  moveMemberDown: (memberId: string) => void;
  moveMemberUp: (memberId: string) => void;
};

const HierarchyLevelButtonsExp: FC<THierarchyLevelButtons> = ({
  disabled,
  formData,
  arrayMemberId,
  removeMember,
  moveMemberDown,
  moveMemberUp,
}) => {
  const i = formData['productFormRoot.hierarchy'].children.indexOf(arrayMemberId);

  const isFirstElement = i === 0;
  const isLastElement = i === formData['productFormRoot.hierarchy'].children.length - 1;

  return (
    <div className={styles.levelButtonsContainer}>
      <DeleteButton disabled={disabled} onClick={() => removeMember(arrayMemberId)} />
      <div className={styles.arrowButtonsContainer}>
        {!isFirstElement && <ArrowUpButton disabled={disabled} onClick={() => moveMemberUp(arrayMemberId)} />}
        {!isLastElement && <ArrowDownButton disabled={disabled} onClick={() => moveMemberDown(arrayMemberId)} />}
      </div>
    </div>
  );
};

type TCodeSourceSwitcher = {
  codeSource: ECheckboxState;
  formData: TProductFormData;
  arrayMemberId: string;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
};
const CodeSourceSwitcher: FC<TCodeSourceSwitcher> = ({ formData, codeSource, arrayMemberId, onBlur, onChange }) => {
  switch (codeSource) {
    case ECheckboxState.CHECKED: {
      return (
        <AutocompleteSelectInputExp
          {...formData[`${arrayMemberId}.variants.gtin`]}
          onChange={onChange}
          onBlur={onBlur}
          label="product.create-form.field.gtin"
        />
      );
    }
    case ECheckboxState.NOT_SET:
    case ECheckboxState.UNCHECKED: {
      return (
        <div className={styles.subRow3}>
          <SelectInput
            {...formData[`${arrayMemberId}.variants.epcCodeId`]}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.codeFormat"
          />
          <TextInput
            {...formData[`${arrayMemberId}.variants.gtin`]}
            label="product.create-form.field.gtin"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      );
    }
  }
};

type TGenerateSsccSwitcher = {
  generateSscc: ECheckboxState;
  formData: TProductFormData;
  arrayMemberId: string;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
};
const GenerateSsccSwitcher: FC<TGenerateSsccSwitcher> = ({
  formData,
  generateSscc,
  arrayMemberId,
  onBlur,
  onChange,
}) => {
  switch (generateSscc) {
    case ECheckboxState.CHECKED: {
      return (
        <TextInput
          {...formData[`${arrayMemberId}.variants.gcp`]}
          label="product.create-form.field.gcp"
          onBlur={onBlur}
          onChange={onChange}
        />
      );
    }
    case ECheckboxState.NOT_SET:
    case ECheckboxState.UNCHECKED: {
      return (
        <div className={styles.subRow3}>
          <SelectInput
            {...formData[`${arrayMemberId}.variants.epcCodeId`]}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.codeFormat"
          />
          <TextInput
            {...formData[`${arrayMemberId}.variants.gcp`]}
            label="product.create-form.field.gcp"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      );
    }
  }
};

type THierarchyLevelTypeSwitcher = {
  hierarchyLevelType: string;
  formData: TProductFormData;
  arrayMemberId: string;

  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
};
const HierarchyLevelTypeSwitcher: FC<THierarchyLevelTypeSwitcher> = ({
  hierarchyLevelType,
  formData,
  arrayMemberId,
  onChange,
  onBlur,
}) => {
  switch (hierarchyLevelType) {
    case KvintaHierarchyLevelType.Pk: {
      return (
        <div>
          <CodeSourceSwitcher
            codeSource={formData[`${arrayMemberId}.variants.codeSource`].value}
            formData={formData}
            onBlur={onBlur}
            onChange={onChange}
            arrayMemberId={arrayMemberId}
          />
          <CheckBoxInput
            {...formData[`${arrayMemberId}.variants.codeSource`]}
            label="product.create-form.field.codeSource"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      );
    }
    case KvintaHierarchyLevelType.Sgtin: {
      return (
        <div className={styles.subRow3}>
          <SelectInput
            {...formData[`${arrayMemberId}.epcCodeId`]}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.codeFormat"
          />
          <TextInput
            {...formData[`${arrayMemberId}.gtin`]}
            label="product.create-form.field.gtin"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      );
    }
    case KvintaHierarchyLevelType.Sscc: {
      return (
        <div>
          <GenerateSsccSwitcher
            generateSscc={formData[`${arrayMemberId}.variants.generateSscc`].value}
            formData={formData}
            onBlur={onBlur}
            onChange={onChange}
            arrayMemberId={arrayMemberId}
          />
          <CheckBoxInput
            {...formData[`${arrayMemberId}.variants.generateSscc`]}
            label="product.create-form.field.generateSscc"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      );
    }
    default:
      return <div />;
  }
};

type THierarchyLevelProps = {
  intl: IntlShape;
  formData: TProductFormData;

  arrayMemberId: string;
  showSingleEpcisDocument: boolean;

  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  removeMember: (memberId: string) => void;
  moveMemberDown: (memberId: string) => void;
  moveMemberUp: (memberId: string) => void;
};
export const ProductHierarchyLevelForm: FC<THierarchyLevelProps> = ({
  intl,
  formData,
  arrayMemberId,
  showSingleEpcisDocument,
  onChange,
  onBlur,
  removeMember,
  moveMemberDown,
  moveMemberUp,
}) => {
  return (
    <div className={styles.hierarchyLevelContainer}>
      <div className={styles.row1}>
        <HierarchyLevelButtonsExp
          disabled={false}
          formData={formData}
          arrayMemberId={arrayMemberId}
          removeMember={removeMember}
          moveMemberDown={moveMemberDown}
          moveMemberUp={moveMemberUp}
        />
        <div>
          <SelectInput
            {...formData[`${arrayMemberId}.hierarchyLevelType`]}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.levelType"
          />
          {showSingleEpcisDocument && (
            <CheckBoxInput
              {...formData[`${arrayMemberId}.singleEpcisDocument`]}
              label="product.create-form.field.singleEpcisDocument"
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        </div>
        <TextInput
          {...formData[`${arrayMemberId}.name`]}
          label="product.create-form.field.name"
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      <div className={styles.row2}>
        <AutocompleteSelectInputExp
          {...formData[`${arrayMemberId}.packagingLevelType`]}
          onChange={onChange}
          onBlur={onBlur}
          label="product.create-form.field.packagingLevel"
        />
        <TextInput
          {...formData[`${arrayMemberId}.quantity`]}
          label="product.create-form.field.quantity"
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      <div className={styles.row3}>
        <HierarchyLevelTypeSwitcher
          hierarchyLevelType={formData[`${arrayMemberId}.hierarchyLevelType`].value}
          formData={formData}
          onBlur={onBlur}
          onChange={onChange}
          arrayMemberId={arrayMemberId}
        />
      </div>
    </div>
  );
};
