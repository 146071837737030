import React from 'react';
import {EPaths,} from "./kvinta/common/HistoryStore";
import {Home} from "./kvinta/modules/main";
import {Logs} from "./kvinta/modules/logs/Logs";
import {CodeFormatList} from "./kvinta/modules/configuration/codeFormats/CodeFormatList";
import {InactivityHoursSummary} from "./kvinta/modules/configuration/InactivityHours/InactivityHoursSummary";
import {TaskList} from "./kvinta/modules/tasks/TaskList";
import {NewTask} from "./kvinta/modules/tasks/NewTask";
import {TaskSummary} from "./kvinta/modules/tasks/TaskSummary";
import {TaskProductList} from "./kvinta/modules/tasks/TaskProductList";
import {TaskProductSummary} from "./kvinta/modules/tasks/TaskProductSummary";
import {NewTaskProduct} from "./kvinta/modules/tasks/NewTaskProduct";
import {ProductList} from "./kvinta/modules/configuration/products/ProductList";
import {NewProduct} from "./kvinta/modules/configuration/products/NewProduct";
import {ProductSummary} from "./kvinta/modules/configuration/products/ProductSummary";
import {NewCodeFormat} from "./kvinta/modules/configuration/codeFormats/NewCodeFormat";
import {CodeFormatSummary} from "./kvinta/modules/configuration/codeFormats/CodeFormatSummary";
import {LocationSummary} from "./kvinta/modules/configuration/locations/LocationSummary";
import {LocationList} from "./kvinta/modules/configuration/locations/LocationList";
import {NewLocation} from "./kvinta/modules/configuration/locations/NewLocation";
import {TaskResultList} from "./kvinta/modules/tasks/TaskResultList";
import {CodePoolList} from "./kvinta/modules/configuration/codePool/CodePoolList";
import {TaskLogsList} from "./kvinta/modules/tasks/TaskLogsList";

type TRoutes={
  path:EPaths,
  component:(props:any)=>JSX.Element
}

export const routes:TRoutes[] = [
  {
    path: EPaths.MainPath,
    component: (props) => <Home {...props} />,
  },
  {
    path: EPaths.LogsPath,
    component: (props) => <Logs {...props} />,
  },
  {
    path: EPaths.LocationListPath,
    component: (props) => <LocationList {...props} />,
  },
  {
    path: EPaths.NewLocationPath,
    component: (props) => <NewLocation {...props} />,
  },
  {
    path: EPaths.LocationPath,
    component: (props) => <LocationSummary {...props} />,
  },
  {
    path: EPaths.CodeFormatsPath,
    component: (props) => <CodeFormatList {...props} />,
  },
  {
    path: EPaths.NewCodeFormatPath,
    component: (props) => <NewCodeFormat {...props} />,
  },
  {
    path: EPaths.CodeFormatPath,
    component: (props) => <CodeFormatSummary {...props} />,
  },
  {
    path: EPaths.NewTaskProductPath,
    component: (props) => <NewTaskProduct {...props} />,
  },
  {
    path: EPaths.TaskProductListPath,
    component: (props) => <TaskProductList {...props} />,
  },
  {
    path: EPaths.TaskProductPath,
    component: (props) => <TaskProductSummary {...props} />,
  },
  {
    path: EPaths.TaskResultListPath,
    component: (props) => <TaskResultList {...props} />,
  },
  {
    path: EPaths.TaskLogsListPath,
    component: (props) => <TaskLogsList {...props} />,
  },


  {
    path: EPaths.NewTaskPath,
    component: (props) => <NewTask {...props} />,
  },
  {
    path: EPaths.TasksPath,
    component: (props) => <TaskList {...props} />,
  },
  {
    path: EPaths.TaskPath,
    component: (props) => <TaskSummary {...props} />,
  },


  {
    path: EPaths.NewProductPath,
    component: (props) => <NewProduct {...props} />,
  },
  {
    path: EPaths.ProductPath,
    component: (props) => <ProductSummary {...props} />,
  },
  {
    path: EPaths.ProductListPath,
    component: (props) => <ProductList {...props} />,
  },


  {
    path: EPaths.CodePoolPath,
    component: (props) => <CodePoolList {...props} />,
  },


  {
    path: EPaths.InactivityTimePath,
    component: (props) => <InactivityHoursSummary {...props} />,
  },


  {
    path: EPaths.NotFoundPath,
    component: (props) => <Home {...props} />,
  },
];
