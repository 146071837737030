import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  CodePoolApi,
  DefaultApi,
  KvintaCodePoolStatistic,
  KvintaCodeSource,
  KvintaEndMarket,
  KvintaEpcCode,
  KvintaGeneratedTopLevelUnitInfo,
  KvintaHierarchyLevelType,
  KvintaLocation,
  KvintaLocationCodeSpecifics,
  KvintaProduct,
  KvintaProductPackLevel,
  KvintaTaskDefinition,
  KvintaTaskDefinitionCreateUpdateRequest,
  KvintaTaskDefinitionProduct,
  KvintaTaskDefinitionResponse,
  KvintaTaskPriority,
  KvintaTaskStatusEnum,
  ProductApi,
} from '../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../common/HistoryStore';
import {
  generalProductFormDataFields,
  normalizeProductDataView,
  notNeeded,
  PRODUCT_FORM_ROOT_ID,
  TOptions,
  TProductDataView,
  TProductFormData,
  transformProductFormData,
} from '../configuration/products/ProductStore';
import { downloadFile } from '../../service/fileExport';
import { TApis } from '../../../stores';
import { normalizeApiResponse } from '../../apis/apis';
import { validateForm } from '../../common/formUtils/core';
import {
  addArrayMember,
  handleFormBlur,
  handleFormChange,
  moveArrayMemberDown,
  moveArrayMemberUp,
  removeArrayMember,
} from '../../common/formUtils/handlers';
import { formRoot, select, textInput } from '../../common/formUtils/formDataGenerators';
import { hasLength, isInteger, isNotEmpty, maxLength, minLength } from '../../common/formUtils/validators';
import { ECheckboxState, TSelectInputData, TTextInputData, TValidationGroupData } from '../../common/formUtils/types';

export enum EPriorityOptions {
  High = 'HIGH',
  Low = 'LOW',
  Standard = 'STANDARD',
}

type TProductPackLevel = {
  hierarchyLevelType: KvintaHierarchyLevelType;
  packagingLevelType: number;
  name: string;
  quantity: number;
  epcCodeId: string;
  gtin?: string | null;
  gcp?: string | null;
  generateSscc: boolean;
  singleEpcisDocument: boolean;
  codeSource: KvintaCodeSource;
};

type TTaskDefinitionProduct = {
  id?: string;
  rsku: string;
  lsku: string;
  locationId: string;
  shipToLocationId: string | null;
  createStockInQad: boolean;
  tpId: string;
  tpPn: string;
  shippingAndReceiving: boolean;
  hierarchy: TProductPackLevel[];
};

type TTaskDefinitionCreateUpdate = {
  id?: string | null;
  name: string;
  endMarket: KvintaEndMarket;
  priority: KvintaTaskPriority;
  products: TTaskDefinitionProduct[];
};

type TTaskDefinition = TTaskDefinitionCreateUpdate & {
  updated: Date;
};

export type TTaskData = TTaskDefinition & {
  status: KvintaTaskStatusEnum;
  results: TTaskDefinitionResults;
};

export type TTaskFormData = {
  taskFormRoot: TValidationGroupData;
  'taskFormRoot.name': TTextInputData;
  'taskFormRoot.priority': TSelectInputData;
};

type TTaskDefinitionResults = { rsku: string; code: string }[];

export type TTaskProductListDefinition = TTaskDefinitionProduct & { id: string };

export interface ITaskProductRow extends TTaskProductListDefinition, ISelectableRow {}

export class TaskProductStore extends SelectableStore<ITaskProductRow> {
  private _config: TAppOptionsConfig;
  private _tasksApi: DefaultApi;
  private _historyStore: HistoryStore;
  private _productApi: ProductApi;
  private _codePoolApi: CodePoolApi;
  private _notificationManager: NotificationManager;
  private _pollingTimeout: number | null;

  isLoading: boolean;

  currentTask?: TTaskData | null = null;
  taskFormData?: TTaskFormData;
  taskFormIsOpen: boolean;

  currentTaskResults: TTaskDefinitionResults = [];
  currentTaskResultSelector: { options: { [key: string]: string }; currentOption: string } = {
    options: {},
    currentOption: '',
  };

  currentProduct?: TProductDataView | null = null;
  productFormData?: TProductFormData;
  productFormIsOpen: boolean;

  listLocations: KvintaLocation[];
  listCodeFormats: KvintaEpcCode[];
  listIdPool: string[];

  listProductsForOnSetRsku: TOptions;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      setIsLoading: action.bound,
      setUpdateProductFormOpen: action.bound,
      setProductFormData: action.bound,
      setCreateProductFormData: action.bound,
      setCurrentTask: action.bound,

      setProductFormDataOnSetRsku: action.bound,
      setTaskResults: action.bound,
      fetchTaskDataWithTaskStatusById: action.bound,
      fetchProductListOfTask: action.bound,
      fetchCurrentProduct: action.bound,

      onSetRsku: action.bound,
      submitCreateProduct: action.bound,
      fetchListCodeFormats: action.bound,

      fetchListProductsForOnSetRsku: action.bound,

      setCurrentProduct: action.bound,

      openUpdateProductForm: action.bound,

      handleFormBlur: action.bound,
      handleFormChange: action.bound,
      addMember: action.bound,
      removeMember: action.bound,
      moveMemberDown: action.bound,
      moveMemberUp: action.bound,

      closeUpdateProduct: action.bound,
      fetchProductSummaryData: action.bound,
      submitUpdateProduct: action.bound,

      getListProducts: action.bound,
      filterResultList: action.bound,
      fetchTaskResults: action.bound,

      cancelUpdateTaskProduct: action.bound,
      cancelCreateTaskProduct: action.bound,

      fetchTask: action.bound,

      setCreateTaskFormData: action.bound,

      getListCodePools: action.bound,
      setListIdPool: action.bound,
      fetchListIdPools: action.bound,
      getProduct: action.bound,

      getTaskStatusById: action.bound,
      getTaskDataById: action.bound,

      handleTaskFormBlur: action.bound,
      handleTaskFormChange: action.bound,

      closeTaskFormData: action.bound,
      setUpdateTaskFormData: action.bound,
      openUpdateTaskForm: action.bound,
      openCreateTaskForm: action.bound,
      cancelCreateTask: action.bound,
      cancelUpdateTask: action.bound,

      submitUpdateTaskForm: action.bound,
      submitCreateTask: action.bound,

      createTask: action.bound,
      updateTask: action.bound,

      setListLocations: action.bound,
      setListCodeFormats: action.bound,

      isLoading: observable,
      taskFormIsOpen: observable,
      currentTaskResultSelector: observable,

      fetchTaskStatusById: observable,
      currentTask: observable,
      currentTaskResults: observable,
      taskFormData: observable,
      productFormIsOpen: observable,
      productFormData: observable,
      currentProduct: observable,

      listLocations: observable,
      listCodeFormats: observable,
      listIdPool: observable,

      listProductsForOnSetRsku: observable,
    });
    this._tasksApi = apis.defaultApi;
    this._productApi = apis.productApi;
    this._historyStore = apis.historyStore;
    this._codePoolApi = apis.codePoolApi;
    this._config = config;
    this._notificationManager = apis.notificationManager;
    this._pollingTimeout = null;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setListLocations(listLocation: KvintaLocation[]) {
    this.listLocations = listLocation;
  }

  setListCodeFormats(listCodeFormats: KvintaEpcCode[]) {
    this.listCodeFormats = listCodeFormats;
  }

  setListIdPool(result: string[]) {
    this.listIdPool = result;
  }

  setCurrentTask(taskStatus: KvintaTaskDefinitionResponse, taskData: KvintaTaskDefinition) {
    this.currentTask = {
      id: taskData.id,
      name: taskData.name,
      priority: taskData.priority,
      endMarket: taskData.endMarket,
      updated: taskData.updated,
      products: normalizeProductsInCurrentTask(taskData.products),
      status: taskStatus.taskStatus,
      results: taskStatus.generatedTopLevelUnitIds ? flattenTaskResults(taskStatus.generatedTopLevelUnitIds) : [],
    };
  }

  setCreateTaskFormData() {
    const newCurrentTaskData = {
      name: '',
      endMarket: KvintaEndMarket.De,
      priority: KvintaTaskPriority.High,
      products: [],
    };
    this.taskFormData = validateForm<TTaskFormData>(generateTaskFormData(TASK_FORM_ROOT_ID, newCurrentTaskData));
  }

  setUpdateTaskFormData(currentTask: TTaskData) {
    this.taskFormData = validateForm<TTaskFormData>(generateTaskFormData(TASK_FORM_ROOT_ID, this.currentTask));
  }

  async fetchProductListOfTask(taskDefinitionId: string) {
    this.setListData([]);
    this.setIsLoading(true);
    const resultData = await this.fetchTaskDataWithTaskStatusById(taskDefinitionId);
    if (resultData) {
      const listLocations = await this.fetchListLocations();
      const listDada = resultData.products.map((comp) => {
        const location = listLocations.find((location) => location.id === comp.locationId).name;
        return {
          ...comp,
          id: comp.id,
          quantity: comp.hierarchy?.length ? comp.hierarchy[0].quantity : 0,
          topHierarchyLevel: comp.hierarchy?.length ? comp.hierarchy[0].name : '',
          location,
          isSelected: false,
        };
      });
      this.setListData(listDada);
    }
    this.setIsLoading(false);
  }

  async fetchTask(taskDefinitionId: string) {
    this.setIsLoading(true);
    await this.fetchTaskDataWithTaskStatusById(taskDefinitionId);
    this.setIsLoading(false);
  }

  async fetchTaskDataWithTaskStatusById(taskDefinitionId: string) {
    this.currentTask = null;
    const [taskStatus, taskData] = await Promise.all([
      this.fetchTaskStatusById(taskDefinitionId),
      this.fetchTaskDataById(taskDefinitionId),
    ]);
    if (!taskData.error && !taskStatus.error) {
      this.setCurrentTask(taskStatus.result, taskData.result);
      return this.currentTask;
    } else {
      return null;
    }
  }

  handleTaskFormBlur(id: string) {
    const formData = toJS(this.taskFormData);
    this.taskFormData = handleFormBlur(formData, id);
  }

  handleTaskFormChange(id: string, value: any) {
    const formData = toJS(this.taskFormData);
    this.taskFormData = handleFormChange(formData, id, value);
  }

  openUpdateTaskForm() {
    this.setUpdateTaskFormData(this.currentTask);
    this.taskFormIsOpen = true;
  }

  openCreateTaskForm() {
    this.setCreateTaskFormData();
    this.taskFormIsOpen = true;
  }

  closeTaskFormData = () => {
    this.taskFormIsOpen = false;
    this.taskFormData = null;
  };

  cancelCreateTask() {
    this._historyStore.navigateTaskListPath();
    this.closeTaskFormData();
  }

  async cancelUpdateTask(id: string) {
    await this.fetchTaskDataWithTaskStatusById(id);
    this.closeTaskFormData();
  }

  async submitUpdateTaskForm(taskId: string) {
    this.setIsLoading(true);
    const taskData = {
      ...transformTaskFormData(this.taskFormData),
      id: this.currentTask.id,
      products: this.currentTask.products,
      endMarket: this.currentTask.endMarket,
    };

    const resultData = await normalizeApiResponse<KvintaTaskDefinition>(
      () => this.updateTask(taskData),
      `An error occurred when trying to Update task by id:${taskId}`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.closeTaskFormData();
      await this.fetchTaskDataWithTaskStatusById(resultData.result.id);
    }
    this.setIsLoading(false);
  }

  updateTask(taskDefinition: KvintaTaskDefinitionCreateUpdateRequest) {
    return this._tasksApi.updateTaskDefinition({
      kvintaTaskDefinitionCreateUpdateRequest: {
        id: taskDefinition.id,
        name: taskDefinition.name,
        priority: taskDefinition.priority,
        products: taskDefinition.products,
        endMarket: taskDefinition.endMarket,
      },
    });
  }

  async submitCreateTask() {
    this.setIsLoading(true);
    const taskData = {
      ...transformTaskFormData(this.taskFormData),
      endMarket: KvintaEndMarket.De,
      products: [],
    };
    const resultData = await normalizeApiResponse<KvintaTaskDefinition>(
      () => this.createTask(taskData),
      `An error occurred when trying to create new task`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.closeTaskFormData();
      this._historyStore.navigateTaskSummaryPath(resultData.result.id);
    }
    this.setIsLoading(false);
  }

  createTask(taskDefinition: KvintaTaskDefinitionCreateUpdateRequest) {
    return this._tasksApi.createTaskDefinition({
      kvintaTaskDefinitionCreateUpdateRequest: {
        name: taskDefinition.name,
        priority: taskDefinition.priority,
        products: taskDefinition.products,
        endMarket: taskDefinition.endMarket,
      },
    });
  }

  //------------------------ Products section------------------

  setUpdateProductFormOpen(isOpen: boolean) {
    this.productFormIsOpen = isOpen;
  }

  async fetchProductSummaryData(taskDefinitionId: string, productId: string) {
    await Promise.all([
      this.fetchListLocations(),
      this.fetchListCodeFormats(),
      this.fetchListProductsForOnSetRsku(),
      this.fetchListIdPools(),
    ]);
    await this.fetchCurrentProduct(taskDefinitionId, productId);
  }

  async fetchCurrentProduct(taskDefinitionId: string, productId: string) {
    if (!this.currentTask) {
      await this.fetchTaskDataWithTaskStatusById(taskDefinitionId);
    }
    if (this.currentTask) {
      const product = this.currentTask.products.find((product) => product.id === productId);
      if (product) {
        this.setCurrentProduct(product);
      }
    }
  }

  setCurrentProduct(product: TTaskDefinitionProduct) {
    this.currentProduct = normalizeProductDataView(
      { ...product, updated: new Date() },
      this.listLocations,
      this.listCodeFormats,
      this.listIdPool,
    );
  }

  setProductFormData() {
    const currentData = toJS(this.currentProduct);

    this.productFormData = validateForm<TProductFormData>(
      generateTaskProductFormData(
        PRODUCT_FORM_ROOT_ID,
        toJS(currentData),
        this.listCodeFormats,
        this.listIdPool,
        getOptionsForRsku(this.listProductsForOnSetRsku, this.currentProduct.rsku),
      ),
    );
  }

  setCreateProductFormData() {
    const newProduct: KvintaProduct = {
      shipToLocationId: undefined,
      updated: new Date(),
      rsku: '',
      lsku: '',
      createStockInQad: false,
      locationId: '',
      shippingAndReceiving: false,
      tpId: '',
      tpPn: '',
      hierarchy: [],
    };
    const newCurrentData = normalizeProductDataView(
      newProduct,
      this.listLocations,
      this.listCodeFormats,
      this.listIdPool,
    );
    this.productFormData = validateForm<TProductFormData>(
      generateTaskProductFormData(
        PRODUCT_FORM_ROOT_ID,
        toJS(newCurrentData),
        this.listCodeFormats,
        this.listIdPool,
        getOptionsForRsku(this.listProductsForOnSetRsku, newProduct.rsku),
      ),
    );
  }

  handleFormBlur(id: string) {
    const formData = toJS(this.productFormData);
    this.productFormData = handleFormBlur(formData, id);
  }

  handleFormChange(id: string, value: any) {
    if (id === `${PRODUCT_FORM_ROOT_ID}.rsku`) {
      if (this.listProductsForOnSetRsku.find((option) => option.key === value)) {
        this.onSetRsku(value);
      } else {
        const formData = toJS(this.productFormData);
        this.productFormData = handleFormChange(formData, id, value);
      }
    } else {
      let formData = toJS(this.productFormData);
      if (id === 'productFormRoot.shipToLocationId' && value === 'None') {
        formData = toJS(handleFormChange(formData, 'productFormRoot.shippingAndReceiving', ECheckboxState.UNCHECKED));
      }
      if (id === 'productFormRoot.locationId') {
        const codeSpecifics =
          this.listLocations.find((location) => location.id === value)?.codeSpecifics ||
          KvintaLocationCodeSpecifics.None;
        formData = toJS(handleFormChange(formData, 'productFormRoot.codeSpecifics', codeSpecifics));
        if (codeSpecifics === KvintaLocationCodeSpecifics.Codentify) {
          formData = toJS(handleFormChange(formData, 'productFormRoot.tpPn', notNeeded));
          formData = toJS(handleFormChange(formData, 'productFormRoot.tpId', notNeeded));
        } else {
          if (
            formData['productFormRoot.tpPn']?.value === notNeeded ||
            formData['productFormRoot.tpId']?.value === notNeeded
          ) {
            formData = toJS(handleFormChange(formData, 'productFormRoot.tpPn', ''));
            formData = toJS(handleFormChange(formData, 'productFormRoot.tpId', ''));
          }
        }
      }
      this.productFormData = handleFormChange(formData, id, value);
    }
  }

  async onSetRsku(rsku: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaProduct>(() => this.getProduct(rsku), 'error in on set rsku');
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.setProductFormDataOnSetRsku(resultData.result);
    }
    this.setIsLoading(false);
  }

  getProduct(rsku: string) {
    return this._productApi.getProduct({ kvintaProductActionRequest: { rsku } });
  }

  setProductFormDataOnSetRsku(data: KvintaProduct) {
    const currentData = normalizeProductDataView(data, this.listLocations, this.listCodeFormats, this.listIdPool);
    this.productFormData = validateForm<TProductFormData>(
      generateTaskProductFormData(
        PRODUCT_FORM_ROOT_ID,
        toJS(currentData),
        this.listCodeFormats,
        this.listIdPool,
        getOptionsForRsku(this.listProductsForOnSetRsku, currentData.rsku),
      ),
    );
  }

  addMember = (parentId: string, place: number) => {
    const formData = toJS(this.productFormData);
    this.productFormData = addArrayMember(parentId, place, {}, formData);
  };
  removeMember = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = removeArrayMember(memberId, formData);
  };
  moveMemberDown = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = moveArrayMemberDown(memberId, formData);
  };
  moveMemberUp = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = moveArrayMemberUp(memberId, formData);
  };

  async deleteProduct(taskDefinitionId, taskProductId) {
    this.setIsLoading(true);
    const newCurrentTask = {
      ...this.currentTask,
      products: [
        ...this.currentTask.products.filter((product) => {
          return product.id !== taskProductId;
        }),
      ],
    };
    const resultData = await this.submitUpdateTask(newCurrentTask);
    if (!resultData.error) {
      await this.fetchProductListOfTask(taskDefinitionId);
    }
    this.setIsLoading(false);
  }

  async openCreateProductForm(taskId: string) {
    this.setIsLoading(true);
    const [listLocations, listCodeFormats, idPoolList, productsForOnSetRsku] = await Promise.all([
      this.fetchListLocations(),
      this.fetchListCodeFormats(),
      this.fetchListIdPools(),
      this.fetchListProductsForOnSetRsku(),
    ]);
    if (listLocations && listCodeFormats && idPoolList && !productsForOnSetRsku.error) {
      this.setCreateProductFormData();
      this.setUpdateProductFormOpen(true);
    }
    this.setIsLoading(false);
  }

  async openUpdateProductForm() {
    this.setProductFormData();
    this.setUpdateProductFormOpen(true);
  }

  closeUpdateProduct = () => {
    this.productFormIsOpen = false;
    this.productFormData = null;
  };

  cancelCreateTaskProduct(taskId: string) {
    this._historyStore.navigateTaskProductListPath(taskId);
    this.closeUpdateProduct();
  }

  async cancelUpdateTaskProduct(taskId: string, productId: string) {
    await this.fetchProductSummaryData(taskId, productId);
    this.closeUpdateProduct();
  }

  async submitUpdateProduct(taskDefinitionId: string, productId: string) {
    const result = await this.validateAndSubmitProduct(productId);
    if (!result.error) {
      await this.fetchProductSummaryData(taskDefinitionId, productId);
    }
    this.closeUpdateProduct();
  }

  async submitCreateProduct(taskDefinitionId: string) {
    const result = await this.validateAndSubmitProduct();
    this.closeUpdateProduct();
    if (!result.error) {
      this._historyStore.navigateTaskProductListPath(taskDefinitionId);
    }
  }

  async validateAndSubmitProduct(productId?: string) {
    const resultData = await this.submitProduct(productId);
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData;
  }

  async submitProduct(productId: string) {
    const newProduct = transformProductFormData(this.productFormData, productId);
    const products = this.currentTask.products;
    const productIndex = products.findIndex((product) => product.id === productId);
    if (productIndex !== -1) {
      products[productIndex] = newProduct;
    } else {
      products.push(newProduct);
    }
    return this.submitUpdateTask({
      ...this.currentTask,
      products,
    });
  }

  async submitUpdateTask(kvintaTaskDefinition: TTaskData) {
    const resultData = await normalizeApiResponse<KvintaTaskDefinition>(
      () => this.updateTask(kvintaTaskDefinition),
      `An error occurred when trying to update task for name:${kvintaTaskDefinition.name}`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData;
  }

  //-------- Results section---------

  setTaskResults(result: TTaskData) {
    const taskResults = result.results;
    const options = taskResults.reduce(
      (acc, { rsku }) => {
        acc[rsku] = rsku;
        return acc;
      },
      { 'show all': 'show all' },
    );

    this.currentTaskResults = taskResults;
    this.currentTaskResultSelector = {
      options,
      currentOption: 'show all',
    };
  }

  async fetchTaskResults(taskDefinitionId: string) {
    this.setIsLoading(true);
    this.currentTaskResults = [];
    const resultData = await this.fetchTaskDataWithTaskStatusById(taskDefinitionId);
    if (resultData) {
      this.setTaskResults(resultData);
    }
    this.setIsLoading(false);
  }

  filterResultList(_id: string, value: string) {
    this.currentTaskResultSelector.currentOption = value;
    this.currentTaskResults =
      value === 'show all'
        ? this.currentTask.results
        : this.currentTask.results.filter((result) => result.rsku === value);
  }

  async fetchTaskDataById(taskDefinitionId: string) {
    const resultData = await normalizeApiResponse<KvintaTaskDefinition>(
      () => this.getTaskDataById(taskDefinitionId),
      `An error occurred when trying to get task data by id:${taskDefinitionId}`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData;
  }

  getTaskDataById(taskDefinitionId: string) {
    return this._tasksApi.getTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  async fetchTaskStatusById(taskDefinitionId: string) {
    const resultData = await normalizeApiResponse<KvintaTaskDefinitionResponse>(
      () => this.getTaskStatusById(taskDefinitionId),
      `An error occurred when trying to get task status by id:${taskDefinitionId}`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData;
  }

  getTaskStatusById(taskDefinitionId: string) {
    return this._tasksApi.getExecutionStatus({
      kvintaTaskDefinitionActionRequest: { taskDefinitionId },
    });
  }

  async fetchListCodeFormats() {
    const resultData = await normalizeApiResponse<Array<KvintaEpcCode> | null>(
      () => this._tasksApi.listEpcCodes(),
      'An error occurred when trying to get Epc Code by ID',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListCodeFormats(resultData.result);
      return resultData.result;
    }
  }

  async fetchListLocations() {
    const resultData = await normalizeApiResponse<Array<KvintaLocation> | null>(
      () => this.getListLocation(),
      'An error occurred when trying to get a list of location',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListLocations(resultData.result);
      return resultData.result;
    }
  }

  async getListLocation() {
    return this._tasksApi.listLocations();
  }

  async fetchListIdPools() {
    const resultData = await normalizeApiResponse<string[]>(
      this.getListCodePools,
      'An error occurred when trying to fetch Id Pool list',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListIdPool(resultData.result);
      return resultData.result;
    }
  }

  async getListCodePools() {
    return this._codePoolApi.listCodePoolsGtins();
  }

  async fetchListProductsForOnSetRsku() {
    const resultData = await normalizeApiResponse<Array<KvintaProduct> | null>(
      () => this.getListProducts(),
      `An error occurred when trying to get list of products`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.listProductsForOnSetRsku = (resultData.result || []).map((product) => {
        return {
          key: product.rsku,
          label: product.rsku,
        };
      });
    }
    return resultData;
  }

  getListProducts() {
    return this._productApi.listProducts();
  }
}

export function exportResults(data) {
  if (data && data.length) {
    const exportData = data.map((result) => `${result.code},${result.rsku}`).join('\n');
    return downloadFile('results.csv', 'text/csv', exportData);
  }
}

function flattenTaskResults(generatedTopLevelUnitIds: KvintaGeneratedTopLevelUnitInfo[]) {
  return generatedTopLevelUnitIds.reduce((acc, { rsku, ids }) => acc.concat(ids.map((code) => ({ rsku, code }))), []);
}

function normalizeProductsInCurrentTask(products: KvintaTaskDefinitionProduct[]): TTaskDefinitionProduct[] {
  return (products || []).map((value) => {
    return {
      id: value.id,
      rsku: value.rsku,
      lsku: value.lsku,
      locationId: value.locationId,
      shipToLocationId: value.shipToLocationId,
      createStockInQad: value.createStockInQad,
      tpId: value.tpId,
      tpPn: value.tpPn,
      shippingAndReceiving: value.shippingAndReceiving,
      hierarchy: normalizeHierarchy(value.hierarchy),
    };
  });
}

function normalizeHierarchy(hierarchy: KvintaProductPackLevel[]): TProductPackLevel[] {
  return (hierarchy || []).map((hierarchyLevel) => {
    return {
      hierarchyLevelType: hierarchyLevel.hierarchyLevelType,
      packagingLevelType: hierarchyLevel.packagingLevelType,
      name: hierarchyLevel.name,
      quantity: hierarchyLevel.quantity,
      epcCodeId: hierarchyLevel.epcCodeId,
      gtin: hierarchyLevel.gtin,
      gcp: hierarchyLevel.gcp,
      generateSscc: hierarchyLevel.generateSscc,
      singleEpcisDocument: hierarchyLevel.singleEpcisDocument,
      codeSource: hierarchyLevel.codeSource,
    };
  });
}

export const STORE_ID = 'taskProductStore';

export const TASK_FORM_ROOT_ID = 'taskFormRoot';

export const requiredFieldsTask = ['name'];
export const requiredFieldsProduct = ['rsku', 'lsku', 'locationId', 'tpId', 'tpPn'];
const requiredFields = requiredFieldsProduct.concat(requiredFieldsTask);

export function errorRequired(id: string, value: any): boolean {
  return requiredFields.includes(id) && (value === undefined || value === '');
}

export function generateTaskProductFormData(
  rootFormId: string,
  initialValues: TProductDataView,
  listCodeFormats: KvintaEpcCode[],
  idPoolListExp: string[],
  listProductsForOnSetRsku: TOptions,
) {
  return formRoot<TProductFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      select({
        path: 'rsku',
        value: initialValues.rsku || listProductsForOnSetRsku[0]?.key || '',
        options: listProductsForOnSetRsku,
        validations: [
          isNotEmpty({ errorMessage: 'validation.error.required' }),
          isInteger({ errorMessage: 'validation.error.onlyInteger' }),
          maxLength({ errorMessage: 'validation.error.rskuLsku', maxLength: 9 }),
          minLength({ errorMessage: 'validation.error.rskuLsku', minLength: 8 }),
        ],
        isRequiredField: true,
      }),
      ...generalProductFormDataFields({ idPoolListExp, initialValues, listCodeFormats }),
    ],
  });
}

function generateTaskFormData(rootFormId: string, initialValues: TTaskDefinitionCreateUpdate) {
  return formRoot<TTaskFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'name',
        value: initialValues.name || '',
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      select({
        path: 'priority',
        value: initialValues.priority || EPriorityOptions.Standard,
        options: Object.values(EPriorityOptions).map((option) => ({
          key: option,
          label: option,
        })),
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
    ],
  });
}

function transformTaskFormData(locationFormData: TTaskFormData) {
  return {
    name: locationFormData[`${TASK_FORM_ROOT_ID}.name`].value.trim(),
    priority: locationFormData[`${TASK_FORM_ROOT_ID}.priority`].value.trim(),
  };
}

function getOptionsForRsku(rskuOptions: TOptions, rsku: string) {
  if (rsku && !rskuOptions.find((option) => option.key === rsku)) {
    rskuOptions.push({
      key: rsku,
      label: rsku,
    });
  }
  return rskuOptions;
}
