import React, { FC } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from '../../assets/css/partials/modules/button.module.scss';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type TButtonContainer = {
  disabled: boolean;
  onClick: () => void;
};

const ButtonContainer: FC<TButtonContainer> = ({ disabled, onClick, children }) => {
  return (
    <button className={styles.buttonContainer} onClick={() => onClick()} disabled={disabled}>
      {children}
    </button>
  );
};

export const ArrowUpButton: FC<TButtonContainer> = ({ disabled, onClick }) => {
  return (
    <ButtonContainer disabled={disabled} onClick={onClick}>
      <KeyboardArrowUpIcon style={{ fill: disabled ? 'rgba(0,0,0,0.26)' : 'initial' }} />
    </ButtonContainer>
  );
};
export const ArrowDownButton: FC<TButtonContainer> = ({ disabled, onClick }) => {
  return (
    <ButtonContainer disabled={disabled} onClick={onClick}>
      <KeyboardArrowDownIcon style={{ fill: disabled ? 'rgba(0,0,0,0.26)' : 'initial' }} />
    </ButtonContainer>
  );
};

export const DeleteButton: FC<TButtonContainer> = ({ disabled, onClick }) => {
  return (
    <ButtonContainer disabled={disabled} onClick={onClick}>
      <DeleteOutlineIcon style={{ fill: disabled ? 'rgba(0,0,0,0.26)' : 'initial' }} />
    </ButtonContainer>
  );
};
