import { action, makeObservable, observable, toJS } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { ConfApi, KvintaBlackHour, KvintaBlackHours } from '../../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../../common/HistoryStore';
import { TApis } from '../../../../stores';
import { normalizeApiResponse } from '../../../apis/apis';
import { TSelectInputData, TTextInputData, TValidationGroupData } from '../../../common/formUtils/types';
import { validateForm } from '../../../common/formUtils/core';
import { select, textInput, validationGroup, formRoot } from '../../../common/formUtils/formDataGenerators';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';

type DataForDayWeek = {
  '1': TUTCInactivityHoursData;
  '2': TUTCInactivityHoursData;
  '3': TUTCInactivityHoursData;
  '4': TUTCInactivityHoursData;
  '5': TUTCInactivityHoursData;
  '6': TUTCInactivityHoursData;
  '7': TUTCInactivityHoursData;
};

type TUTCInactivityHoursData = {
  start: string;
  finish: string;
};

export type THoursDataForDayWeek = {
  currentTimeZone: string;
  blackHours: DataForDayWeek;
};

export type TUpdateHoursDataForDayWeek = {
  inactivityHoursForm: TValidationGroupData;
  'inactivityHoursForm.currentTimeZone': TSelectInputData;
  'inactivityHoursForm.1.start': TTextInputData;
  'inactivityHoursForm.1.finish': TTextInputData;
  'inactivityHoursForm.2': TValidationGroupData;
  'inactivityHoursForm.2.start': TTextInputData;
  'inactivityHoursForm.2.finish': TTextInputData;
  'inactivityHoursForm.3': TValidationGroupData;
  'inactivityHoursForm.3.start': TTextInputData;
  'inactivityHoursForm.3.finish': TTextInputData;
  'inactivityHoursForm.4': TValidationGroupData;
  'inactivityHoursForm.4.start': TTextInputData;
  'inactivityHoursForm.4.finish': TTextInputData;
  'inactivityHoursForm.5': TValidationGroupData;
  'inactivityHoursForm.5.start': TTextInputData;
  'inactivityHoursForm.5.finish': TTextInputData;
  'inactivityHoursForm.6': TValidationGroupData;
  'inactivityHoursForm.6.start': TTextInputData;
  'inactivityHoursForm.6.finish': TTextInputData;
  'inactivityHoursForm.7': TValidationGroupData;
  'inactivityHoursForm.7.start': TTextInputData;
  'inactivityHoursForm.7.finish': TTextInputData;
};

export class InactivityHoursStore {
  private _config: TAppOptionsConfig;
  private _configApi: ConfApi;
  private _historyStore: HistoryStore;
  private _notificationManager: NotificationManager;
  isLoading: boolean;
  inactivityHoursFormOpen: boolean;

  inactivityHoursData?: THoursDataForDayWeek | null = null;
  inactivityHoursFormData?: TUpdateHoursDataForDayWeek | null = null;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    makeObservable(this, {
      setIsLoading: action.bound,
      setCurrentInactivityHoursData: action.bound,
      setUpdateInactivityHoursFormOpen: action.bound,
      setUpdateInactivityHoursData: action.bound,
      onChangeUpdateInactivityHoursField: action.bound,
      onBlurUpdateInactivityHoursField: action.bound,
      onChangeTimeZoneField: action.bound,
      cancelUpdateInactivityHoursForm: action.bound,

      fetchInactivityHours: action.bound,
      updateInactivityHours: action.bound,
      getInactivityHours: action.bound,

      isLoading: observable,
      inactivityHoursFormOpen: observable,

      inactivityHoursData: observable,
      inactivityHoursFormData: observable,
    });
    this._configApi = apis.configApi;
    this._historyStore = apis.historyStore;
    this._config = config;
    this._notificationManager = apis.notificationManager;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setUpdateInactivityHoursFormOpen(isOpen: boolean) {
    this.inactivityHoursFormOpen = isOpen;
  }

  setCurrentInactivityHoursData(UTCInactivityHoursData: KvintaBlackHours) {
    this.inactivityHoursData = {
      currentTimeZone: getLocalTimeZoneName(),
      blackHours: normalizeBlackHoursResponseAsLocal(UTCInactivityHoursData.blackHours),
    };
  }

  setUpdateInactivityHoursData() {
    this.inactivityHoursFormData = validateForm<TUpdateHoursDataForDayWeek>(
      generateInactivityHoursFormData(INACTIVITY_HOURS_FORM_ROOT_ID, this.inactivityHoursData),
    );
  }

  async fetchInactivityHours() {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaBlackHours>(
      this.getInactivityHours,
      'error in fetchInactivityHours',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.setCurrentInactivityHoursData(resultData.result);
    }
    this.setIsLoading(false);
  }

  openUpdateInactivityHoursForm() {
    this.setUpdateInactivityHoursData();
    this.setUpdateInactivityHoursFormOpen(true);
  }

  cancelUpdateInactivityHoursForm() {
    this.setUpdateInactivityHoursFormOpen(false);
    this.inactivityHoursFormData = null;
  }

  onChangeTimeZoneField(id: string, data: string) {
    if (data === 'Local') {
      this.inactivityHoursFormData = normalizeInactivityHoursFormAsLocal(this.inactivityHoursFormData);
    } else if (data === 'UTC') {
      this.inactivityHoursFormData = normalizeInactivityHoursFormAsUTC(this.inactivityHoursFormData);
    }
  }

  onChangeUpdateInactivityHoursField(id: string, data: string) {
    const formData = toJS(this.inactivityHoursFormData);
    this.inactivityHoursFormData = handleFormChange(formData, id, data);
  }

  onBlurUpdateInactivityHoursField(id: string, data: string) {
    const formData = toJS(this.inactivityHoursFormData);
    this.inactivityHoursFormData = handleFormBlur(formData, id);
  }

  async submitUpdateInactivityHoursForm() {
    let inactivityHoursDataRequest = toJS(this.inactivityHoursFormData);
    if (inactivityHoursDataRequest['inactivityHoursForm.currentTimeZone'].value === 'Local') {
      inactivityHoursDataRequest = normalizeInactivityHoursFormAsUTC(inactivityHoursDataRequest);
    }

    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.updateInactivityHours(transformInactivityHoursFormData(inactivityHoursDataRequest)),
      'error in submitUpdateInactivityHoursForm',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchInactivityHours();
    }
    this.setUpdateInactivityHoursFormOpen(false);
    this.setIsLoading(false);
  }

  async updateInactivityHours(data: DataForDayWeek) {
    return this._configApi.save({
      kvintaBlackHours: {
        blackHours: data,
      },
    });
  }

  async getInactivityHours() {
    return this._configApi.getBlackHours();
  }
}

function setTimeAsUTC(time: string): string | null {
  const { hours, min } = normalizeInput(time);
  if (isNaN(hours) || isNaN(min) || hours < 0 || min < 0) return null;
  const baseDate = new Date();
  baseDate.setHours(hours);
  baseDate.setMinutes(min);
  return `${String(baseDate.getUTCHours()).padStart(2, '0')}:${String(baseDate.getUTCMinutes()).padStart(2, '0')}`;
}

export function setTimeAsLocal(time: string): string | null {
  const { hours, min } = normalizeInput(time);
  if (isNaN(hours) || isNaN(min) || hours < 0 || min < 0) return null;
  const baseDate = new Date();
  baseDate.setUTCHours(hours);
  baseDate.setUTCMinutes(min);
  return `${String(baseDate.getHours()).padStart(2, '0')}:${String(baseDate.getMinutes()).padStart(2, '0')}`;
}

function normalizeInput(time: string) {
  const parseTime = time.split(':');
  return {
    hours: Number(parseTime[0]),
    min: Number(parseTime[1]),
  };
}

function normalizeBlackHoursResponseAsLocal(UTCInactivityHoursData: { [key: string]: KvintaBlackHour }): any {
  const normalaizeHours = {};
  for (let i = 1; i < 8; i++) {
    normalaizeHours[`${i}`] = {
      finish: setTimeAsLocal(UTCInactivityHoursData[i].finish || ''),
      start: setTimeAsLocal(UTCInactivityHoursData[i].start || ''),
    };
  }
  return normalaizeHours;
}

function normalizeInactivityHoursFormAsLocal(UTCInactivityHoursData: TUpdateHoursDataForDayWeek): any {
  const normalizeBlackHoursFormData = toJS(UTCInactivityHoursData);
  normalizeBlackHoursFormData[`inactivityHoursForm.currentTimeZone`].value = 'Local';
  for (let i = 1; i < 8; i++) {
    normalizeBlackHoursFormData[`inactivityHoursForm.${i}.start`].value = setTimeAsLocal(
      normalizeBlackHoursFormData[`inactivityHoursForm.${i}.start`].value || '',
    );
    normalizeBlackHoursFormData[`inactivityHoursForm.${i}.finish`].value = setTimeAsLocal(
      normalizeBlackHoursFormData[`inactivityHoursForm.${i}.finish`].value || '',
    );
  }
  return normalizeBlackHoursFormData;
}

function normalizeInactivityHoursFormAsUTC(UTCInactivityHoursData: TUpdateHoursDataForDayWeek) {
  const normalizeBlackHoursFormData = toJS(UTCInactivityHoursData);
  normalizeBlackHoursFormData[`inactivityHoursForm.currentTimeZone`].value = 'UTC';
  for (let i = 1; i < 8; i++) {
    normalizeBlackHoursFormData[`inactivityHoursForm.${i}.start`].value = setTimeAsUTC(
      normalizeBlackHoursFormData[`inactivityHoursForm.${i}.start`].value || '',
    );
    normalizeBlackHoursFormData[`inactivityHoursForm.${i}.finish`].value = setTimeAsUTC(
      normalizeBlackHoursFormData[`inactivityHoursForm.${i}.finish`].value || '',
    );
  }
  return normalizeBlackHoursFormData;
}

function getTimeFromMin(min: number) {
  return {
    hours: String(Math.abs(Math.trunc(min / 60))).padStart(2, '0'),
    min: String(Math.abs(min % 60)).padStart(2, '0'),
  };
}

function getLocalTimeZoneName() {
  const timezoneOffset = -new Date().getTimezoneOffset();
  const { hours, min } = getTimeFromMin(timezoneOffset);
  const numberSign = timezoneOffset < 0 ? '-' : '+';
  return `Local (UTC${numberSign}${hours}:${min})`;
}

function generateInactivityHoursFormData(rootFormId: string, initialValues: THoursDataForDayWeek) {
  return formRoot<TUpdateHoursDataForDayWeek>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      select({
        path: 'currentTimeZone',
        value: 'Local',
        options: [
          {
            key: 'UTC',
            label: 'UTC',
          },
          {
            key: 'Local',
            label: getLocalTimeZoneName(),
          },
        ],
        validations: [],
        isRequiredField: false,
      }),
      validationGroup({
        path: '1',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['1'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['1'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '2',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['2'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['2'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '3',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['3'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['3'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '4',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['4'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['4'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '5',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['5'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['5'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '6',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['6'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['6'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
      validationGroup({
        path: '7',
        validations: [],
        childrenFactories: [
          textInput({
            path: 'start',
            value: initialValues.blackHours['7'].start,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'finish',
            value: initialValues.blackHours['7'].finish,
            validations: [],
            isRequiredField: false,
          }),
        ],
      }),
    ],
  });
}

function transformInactivityHoursFormData(locationFormData: TUpdateHoursDataForDayWeek) {
  return {
    '1': {
      start: locationFormData['inactivityHoursForm.1.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.1.finish'].value.trim(),
    },
    '2': {
      start: locationFormData['inactivityHoursForm.2.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.2.finish'].value.trim(),
    },
    '3': {
      start: locationFormData['inactivityHoursForm.3.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.3.finish'].value.trim(),
    },
    '4': {
      start: locationFormData['inactivityHoursForm.4.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.4.finish'].value.trim(),
    },
    '5': {
      start: locationFormData['inactivityHoursForm.5.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.5.finish'].value.trim(),
    },
    '6': {
      start: locationFormData['inactivityHoursForm.6.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.6.finish'].value.trim(),
    },
    '7': {
      start: locationFormData['inactivityHoursForm.7.start'].value.trim(),
      finish: locationFormData['inactivityHoursForm.7.finish'].value.trim(),
    },
  };
}

export const STORE_ID = 'inactivityHoursStore';
export const INACTIVITY_HOURS_FORM_ROOT_ID = 'inactivityHoursForm';
