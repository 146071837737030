import React, { Component } from 'react';
import { Container, Paper, Grid, Typography } from '@mui/material';
import notFound from 'assets/img/page_not_found.svg';

export default class NotFound extends Component {
  render() {
    const styles = {
      image: {
        height: '228px',
        width: '420px',
        marginTop: '2em',
        marginBottom: '2em',
      },
      alignment: {
        marginTop: '2em',
      },
    };

    return (
      <Container maxWidth="md">
        <Paper style={styles.alignment}>
          <Grid container direction="column" alignItems="center">
            <Typography variant="h3">This is not the page you are looking for</Typography>
            <img src={notFound} alt="Page not found" style={styles.image} />
          </Grid>
        </Paper>
      </Container>
    );
  }
}
