/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSsccExecutionLogResponse,
    KvintaSsccExecutionLogResponseFromJSON,
    KvintaSsccExecutionLogResponseFromJSONTyped,
    KvintaSsccExecutionLogResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTaskExecutionLogResponse
 */
export interface KvintaTaskExecutionLogResponse {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskExecutionLogResponse
     */
    rsku: string;
    /**
     * 
     * @type {{ [key: string]: Array<KvintaSsccExecutionLogResponse>; }}
     * @memberof KvintaTaskExecutionLogResponse
     */
    ssccLog: { [key: string]: Array<KvintaSsccExecutionLogResponse>; };
}

export function KvintaTaskExecutionLogResponseFromJSON(json: any): KvintaTaskExecutionLogResponse {
    return KvintaTaskExecutionLogResponseFromJSONTyped(json, false);
}

export function KvintaTaskExecutionLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskExecutionLogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rsku': json['rsku'],
        'ssccLog': json['ssccLog'],
    };
}

export function KvintaTaskExecutionLogResponseToJSON(value?: KvintaTaskExecutionLogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rsku': value.rsku,
        'ssccLog': value.ssccLog,
    };
}


