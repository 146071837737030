import React, { FC } from 'react';
import { TTaskFormData } from './TaskProductStore';

import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { TextInput } from '../../components/FormFields/Fields_Exp/TextInput_Exp';
import { SelectInput } from '../../components/FormFields/Fields_Exp/SelectInput_Exp';

interface ITaskSummaryFormBase {
  formData: TTaskFormData;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
}

const TaskSummaryFormBase: FC<ITaskSummaryFormBase> = ({ formData, onChange, onBlur }) => {
  if (!formData) return null;
  return (
    <div className={styles.taskContainer}>
      <TextInput
        {...formData['taskFormRoot.name']}
        label="task.create-form.field.name"
        onBlur={onBlur}
        onChange={onChange}
      />
      <SelectInput
        {...formData['taskFormRoot.priority']}
        onChange={onChange}
        onBlur={onBlur}
        label="task.create-form.field.priority"
      />
    </div>
  );
};

export const TaskSummaryForm = TaskSummaryFormBase;
