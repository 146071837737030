import React from 'react';

export const CopyStringField = ({ children, stringToCopy }) => (
  <span
    className="hover-copy"
    onClick={(e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(stringToCopy);
      e.currentTarget.classList.add('hover-copy-done');
    }}
    onMouseLeave={(e) => {
      e.currentTarget.classList.remove('hover-copy-done');
    }}
    onMouseOut={(e) => {
      e.currentTarget.classList.remove('hover-copy-done');
    }}
  >
    {children}
  </span>
);
