import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { TUpdateHoursDataForDayWeek } from './InactivityHoursStore';
import styles from '../../../../assets/css/partials/modules/InactivityHours.module.scss';
import { SelectInput } from '../../../components/FormFields/Fields_Exp/SelectInput_Exp';
import { TimeInputExp } from '../../../components/FormFields/Fields_Exp/TimeInput_Exp';

type TInactivityHoursForDay = {
  intl: IntlShape;
  dayName: string;
  formData: TUpdateHoursDataForDayWeek;
  dayNumber: string;
  onChangeValue: (id: string, val: string) => void;
  onBlurValue: (id: string, val: string) => void;
};
const InactivityHoursForDay: FC<TInactivityHoursForDay> = ({
  formData,
  dayNumber,
  dayName,
  intl,
  onChangeValue,
  onBlurValue,
}) => {
  return (
    <div className={styles.inactivityHoursRow}>
      <div className={styles.inactivityHoursRowHeader}>{intl.formatMessage({ id: dayName })}</div>
      <TimeInputExp
        {...formData[`inactivityHoursForm.${dayNumber}.start`]}
        label="inactivityHours.create-form.field.start"
        onBlur={onBlurValue}
        onChange={onChangeValue}
      />
      <TimeInputExp
        {...formData[`inactivityHoursForm.${dayNumber}.finish`]}
        label="inactivityHours.create-form.field.finish"
        onBlur={onBlurValue}
        onChange={onChangeValue}
      />
    </div>
  );
};

export const weekdays = [
  { dayNumber: '1', dayName: 'days-week.monday' },
  { dayNumber: '2', dayName: 'days-week.tuesday' },
  { dayNumber: '3', dayName: 'days-week.wednesday' },
  { dayNumber: '4', dayName: 'days-week.thursday' },
  { dayNumber: '5', dayName: 'days-week.friday' },
];
export const weekends = [
  { dayNumber: '6', dayName: 'days-week.saturday' },
  { dayNumber: '7', dayName: 'days-week.sunday' },
];

interface IUpdateHoursForm {
  formData: TUpdateHoursDataForDayWeek;
  onChangeValue: (id: string, data: string) => void;
  onBlurValue: (id: string, data: string) => void;
  onChangeTimeZone: (id: string, data: string) => void;
  intl: IntlShape;
}

const UpdateHoursFormImpl: FC<IUpdateHoursForm> = ({
  onChangeValue,
  onBlurValue,
  onChangeTimeZone,
  formData,
  intl,
}) => {
  const inactivityHoursFromWeekdays = weekdays.map((day) => (
    <InactivityHoursForDay
      intl={intl}
      key={day.dayName}
      dayName={day.dayName}
      dayNumber={day.dayNumber}
      onBlurValue={onBlurValue}
      onChangeValue={onChangeValue}
      formData={formData}
    />
  ));
  const inactivityHoursFromWeekends = weekends.map((day) => (
    <InactivityHoursForDay
      intl={intl}
      key={day.dayName}
      dayName={day.dayName}
      dayNumber={day.dayNumber}
      onBlurValue={onBlurValue}
      onChangeValue={onChangeValue}
      formData={formData}
    />
  ));
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <h1 className={styles.timezoneHeader}>
          {intl.formatMessage({ id: 'inactivityHours.create-form.field.hoursFieldTitle' })}
        </h1>
        <div className={styles.timezoneSelectInput}>
          <SelectInput
            {...formData['inactivityHoursForm.currentTimeZone']}
            onChange={onChangeTimeZone}
            onBlur={onBlurValue}
            label="inactivityHours.create-form.field.timeZone"
          />
        </div>
      </div>
      <div className={styles.inactivityHoursRowContainer}>{inactivityHoursFromWeekdays}</div>
      <div className={styles.inactivityHoursRowContainer}>{inactivityHoursFromWeekends}</div>
      <div className={styles.formContainerSection}>
        {intl.formatMessage({ id: 'inactivityHours.create-form.field.hoursFieldDescription' })}
      </div>
    </div>
  );
};

export const InactivityHoursSummaryForm = UpdateHoursFormImpl;
