import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { Component } from 'react';
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { CodePoolStore, ID_POOL_FORM_ROOT_ID, STORE_ID } from './codePoolStore';
import style from '../../../../assets/css/partials/modules/CodePool.module.scss';
import { EAcceptedFileTypes } from '../../../common';
import { TextInput } from '../../../components/FormFields/Fields_Exp/TextInput_Exp';
import { UploadInput } from '../../../components/FormFields/Fields_Exp/UpoadInput_Exp';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';
import { inject, observer } from 'mobx-react';

interface ICreateCsvForm extends WrappedComponentProps {
  codePoolStore?: CodePoolStore;
  intl: IntlShape;
}

class CreateCsvFormBase extends Component<ICreateCsvForm> {
  render() {
    const intl = this.props.intl;
    const {
      isUploadCsvFormOpen: isOpen,
      csvFormData: formData,
      handleFormBlur,
      handleFormChange,
    } = this.props.codePoolStore;

    if (!isOpen || !formData) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => this.props.codePoolStore.closeUploadCsvForm()}
      >
        <KDialogTitle id="customized-dialog-title" onClose={() => this.props.codePoolStore.closeUploadCsvForm()}>
          {intl.formatMessage({ id: 'codePool.create-form.header' })}
        </KDialogTitle>
        <DialogContent className={style.dialogContainer}>
          <TextInput
            {...formData[`${ID_POOL_FORM_ROOT_ID}.gtin`]}
            label="codePool.create-form.field.gtin"
            onBlur={handleFormBlur}
            onChange={handleFormChange}
          />
          <UploadInput
            {...formData[`${ID_POOL_FORM_ROOT_ID}.file`]}
            label="codePool.create-form.field.button-upload-file"
            onBlur={handleFormBlur}
            onChange={handleFormChange}
            acceptedFileType={EAcceptedFileTypes.csv}
          />
        </DialogContent>
        <DialogActions>
          <FormControlButtons
            buttonState={formDataToButtonState(formData)}
            formIsOpen={isOpen}
            onCancel={this.props.codePoolStore.closeUploadCsvForm}
            onConfirm={this.props.codePoolStore.submitUpload}
            onOpen={() => {
              return null;
            }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export const CreateCsvSummaryForm = injectIntl(inject(STORE_ID)(observer(CreateCsvFormBase)));
