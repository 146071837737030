import {NotificationManager} from 'kvinta/modules/main';
import {KSidebarStore, SIDEBAR_STORE_ID as SIDEBAR_STORE_ID} from 'kvinta/components/Sidebar/KSidebarStore';
import {MainMenu} from './menu';
import {TAppOptionsConfig} from 'kvinta/common';
import {PAGE_CONTENT_STORE_ID, PageContentStore} from 'kvinta/components';
import {createApi, createConfigurationApi, createCodePoolApi, createProductApi} from "./kvinta/apis/apis";
import {History} from "history";
import {HistoryStore, STORE_ID as HISTORY_STORE_ID} from "./kvinta/common/HistoryStore";
import {
  CodeFormatsStore,
  STORE_ID as CODE_FORMATS_STORE_ID
} from "./kvinta/modules/configuration/codeFormats/CodeFormatsStore";
import {STORE_ID as TASK_STORE_ID, TaskStore} from "./kvinta/modules/tasks/TaskStore";
import {ProductStore, STORE_ID as PRODUCT_STORE_ID} from "./kvinta/modules/configuration/products/ProductStore";
import {LocationStore, STORE_ID as LOCATIONS_STORE_ID} from "./kvinta/modules/configuration/locations/LocationStore";
import {
  InactivityHoursStore,
  STORE_ID as INACTIVITY_HOURS_STORE_ID
} from "./kvinta/modules/configuration/InactivityHours/InactivityHoursStore";
import {
  CodePoolStore,
  STORE_ID as CODE_POOL_STORE_ID
} from "./kvinta/modules/configuration/codePool/codePoolStore";
import {PROMPT_POPUP_STORE_ID, PromptPopupStore} from "./kvinta/components/PromptPopup/PromptPopupStore";
import {TaskProductStore, STORE_ID as TASK_PRODUCT_STORE_ID} from "./kvinta/modules/tasks/TaskProductStore";
import {STORE_ID, TaskLogsStore} from "./kvinta/modules/tasks/TaskLogsStore";
import {createContext} from "react";

export type TApis = {
  defaultApi: ReturnType<typeof createApi>
  productApi: ReturnType<typeof createProductApi>
  configApi: ReturnType<typeof createConfigurationApi>
  codePoolApi: ReturnType<typeof createCodePoolApi>
  notificationManager: NotificationManager,
  historyStore: HistoryStore,
  pageContentStore: PageContentStore,
  sidebarStore: KSidebarStore,
  popupAlertStore: PromptPopupStore
}

export function createStores(config: TAppOptionsConfig, history: History<unknown>) {
  const notificationManager = new NotificationManager();
  const historyStore = new HistoryStore(history);
  const pageContentStore = new PageContentStore();
  const sidebarStore = new KSidebarStore(MainMenu);
  const popupAlertStore = new PromptPopupStore()

  const defaultApi = createApi(config);
  const productApi = createProductApi(config);
  const configApi = createConfigurationApi(config);
  const codePoolApi = createCodePoolApi(config);

  const apis:TApis = {
    notificationManager,
    historyStore,
    pageContentStore,
    sidebarStore,
    popupAlertStore,
    defaultApi,
    productApi,
    configApi,
    codePoolApi,
  }

  const locationsStore = new LocationStore(config, apis);
  const codeFormatsStore = new CodeFormatsStore(config, apis);
  const taskStore = new TaskStore(config, apis);
  const taskProductStore = new TaskProductStore(config, apis);
  const productStore = new ProductStore(config, apis);
  const inactivityHoursStore = new InactivityHoursStore(config, apis)
  const codePoolStore = new CodePoolStore(config, apis)
  const taskLogsStore = new TaskLogsStore(config, apis)
  return {
    [HISTORY_STORE_ID]: historyStore,
    [SIDEBAR_STORE_ID]: sidebarStore,
    [PROMPT_POPUP_STORE_ID]:popupAlertStore,
    [PAGE_CONTENT_STORE_ID]: pageContentStore,
    [LOCATIONS_STORE_ID]: locationsStore,
    [CODE_FORMATS_STORE_ID]: codeFormatsStore,
    [TASK_STORE_ID]: taskStore,
    [TASK_PRODUCT_STORE_ID]: taskProductStore,
    [PRODUCT_STORE_ID]: productStore,
    [INACTIVITY_HOURS_STORE_ID]: inactivityHoursStore,
    [CODE_POOL_STORE_ID]: codePoolStore,
    [STORE_ID]: taskLogsStore,
  };
}

export type TStores = ReturnType<typeof createStores>
