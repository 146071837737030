import React, { FC, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Collapse, Divider, List, ListItem } from '@mui/material';
import { matchPath, useHistory } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { isSelectedMenu } from './Menus';
import { EMenuItemType, TChildItemMainMenu, TItemMenu, TParentItemMainMenu } from '../../../menu';
import styles from '../../../assets/css/partials/modules/Sidebar.module.scss';

type TParentItem = {
  intl: IntlShape;
  item: TParentItemMainMenu;
  isExpanded: boolean;
  selectedItem: string;
  onHandlerClick: () => void;
  paddingLeft: number;
  history: any;
  onClick: (label: string) => void;
};
const ParentItem: FC<TParentItem> = ({
  history,
  intl,
  item,
  isExpanded,
  selectedItem,
  onHandlerClick,
  onClick,
  paddingLeft,
}) => {
  const expandIcon = isExpanded ? (
    <ExpandLessIcon className={styles.sidebarItemExpandArrowExpanded} />
  ) : (
    <ExpandMoreIcon className={styles.sidebarItemExpandArrow} />
  );
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <>
      <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={onHandlerClick}>
        <div className={styles.sidebarItemContent} style={{ paddingLeft: paddingLeft }}>
          {iconWithTooltip}
          <div className={styles.sidebarItemText}>
            <FormattedMessage id={item.label} defaultMessage={item.label} />
          </div>
          {expandIcon}
        </div>
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {item.items.map((subItem, index) => {
            const key =
              subItem.type === EMenuItemType.DIVIDER ? subItem.type + '-' + index : `${subItem.label}${index}`;
            return (
              <SidebarItem
                key={key}
                history={history}
                paddingLeft={30}
                item={subItem}
                onClick={() => {
                  if (subItem.type === EMenuItemType.CHILD_ITEM) {
                    onClick(intl.formatMessage({ id: subItem.label }));
                  }
                }}
                isExpanded={false}
                selectedItem={selectedItem}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

type TItemContent = {
  iconWithTooltip: JSX.Element;
  item: TChildItemMainMenu;
  paddingLeft: number;
};

const ItemContent: FC<TItemContent> = ({ paddingLeft, iconWithTooltip, item }) => {
  return (
    <div className={styles.sidebarItemContent} style={{ paddingLeft: paddingLeft }}>
      {iconWithTooltip}
      <div className={styles.sidebarItemText}>
        <FormattedMessage id={item.label} defaultMessage={item.label} />
      </div>
    </div>
  );
};

type TExternalItem = {
  item: TChildItemMainMenu;
  selectedItem: string;
  onHandlerClick: () => void;
  paddingLeft: number;
};
const ExternalItem: FC<TExternalItem> = ({ item, selectedItem, onHandlerClick, paddingLeft }) => {
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={onHandlerClick}>
      <a href={item.path} target="_blank" rel="noreferrer" className={styles.sidebarLinkItem}>
        <ItemContent iconWithTooltip={iconWithTooltip} item={item} paddingLeft={paddingLeft} />
      </a>
    </ListItem>
  );
};

type TChildItem = {
  item: TChildItemMainMenu;
  selectedItem: string;
  onHandlerClick: () => void;
  paddingLeft: number;
};
const ChildItem: FC<TChildItem> = ({ item, selectedItem, onHandlerClick, paddingLeft }) => {
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={onHandlerClick}>
      <ItemContent iconWithTooltip={iconWithTooltip} item={item} paddingLeft={paddingLeft} />
    </ListItem>
  );
};

interface ISidebarItemF {
  history: any;
  isExpanded: boolean;
  item: TItemMenu;
  onClick: (label: string) => void;
  selectedItem: string;
  isExternal?: boolean;
  paddingLeft: number;
}

export const SidebarItem: FC<ISidebarItemF> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const selectedItem = history.location.pathname;

  const [isExpanded, setIsExpanded] = useState(() => {
    if (props.item.type === EMenuItemType.PARENT_ITEM) {
      return props.item.isClickable === false ? isChildSelected(props.item.items, selectedItem) : props.isExpanded;
    } else return false;
  });

  const onHandlerClick = () => {
    if (props.item.type === EMenuItemType.PARENT_ITEM) {
      setIsExpanded((prevState) => !prevState);
    } else if (props.item.type === EMenuItemType.CHILD_ITEM && props.item.isClickable) {
      props.onClick(intl.formatMessage({ id: props.item.label }));
      props.history.push(props.item.path);
    }
  };

  useEffect(() => {
    if (isSelectedMenu(props.item, selectedItem) && props.item.type === EMenuItemType.CHILD_ITEM) {
      props.onClick(intl.formatMessage({ id: props.item.label }));
    }
  }, []);

  switch (props.item.type) {
    case EMenuItemType.DIVIDER: {
      return <Divider />;
    }
    case EMenuItemType.PARENT_ITEM: {
      return (
        <ParentItem
          intl={intl}
          item={props.item}
          isExpanded={isExpanded}
          selectedItem={selectedItem}
          onHandlerClick={onHandlerClick}
          paddingLeft={props.paddingLeft}
          history={props.history}
          onClick={props.onClick}
        />
      );
    }
    case EMenuItemType.EXTERNAL_ITEM: {
      return (
        <ExternalItem
          item={props.item}
          selectedItem={selectedItem}
          onHandlerClick={onHandlerClick}
          paddingLeft={props.paddingLeft}
        />
      );
    }
    case EMenuItemType.CHILD_ITEM: {
      return (
        <ChildItem
          item={props.item}
          selectedItem={selectedItem}
          onHandlerClick={onHandlerClick}
          paddingLeft={props.paddingLeft}
        />
      );
    }
  }
};

function isChildSelected(items: TItemMenu[], selectedItem: string): boolean {
  for (const item of items) {
    if (item.type === EMenuItemType.CHILD_ITEM) {
      if (matchPath(selectedItem, item.path)) {
        return true;
      }
      if (item.selectOnPaths) {
        for (const p of item.selectOnPaths) {
          if (matchPath(selectedItem, p)) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
