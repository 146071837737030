/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaCodePoolActionRequest,
    KvintaCodePoolActionRequestFromJSON,
    KvintaCodePoolActionRequestToJSON,
    KvintaOperationResponseInteger,
    KvintaOperationResponseIntegerFromJSON,
    KvintaOperationResponseIntegerToJSON,
    KvintaOperationResponseListCodePoolStatistic,
    KvintaOperationResponseListCodePoolStatisticFromJSON,
    KvintaOperationResponseListCodePoolStatisticToJSON,
    KvintaOperationResponseListString,
    KvintaOperationResponseListStringFromJSON,
    KvintaOperationResponseListStringToJSON,
    KvintaOperationResponseUnit,
    KvintaOperationResponseUnitFromJSON,
    KvintaOperationResponseUnitToJSON,
    KvintaReleaseWorkflowCodesRequest,
    KvintaReleaseWorkflowCodesRequestFromJSON,
    KvintaReleaseWorkflowCodesRequestToJSON,
} from '../models';

export interface ClearPoolRequest {
    kvintaCodePoolActionRequest?: KvintaCodePoolActionRequest;
}

export interface ClearPool1Request {
    kvintaReleaseWorkflowCodesRequest: KvintaReleaseWorkflowCodesRequest;
}

export interface DeleteCodePoolRequest {
    kvintaCodePoolActionRequest: KvintaCodePoolActionRequest;
}

export interface UploadRequest {
    file: Blob;
    gtin: string;
}

/**
 * 
 */
export class CodePoolApi extends runtime.BaseAPI {

    /**
     * Remove used codes from pool
     * Remove used codes from pool
     */
    async clearPoolRaw(requestParameters: ClearPoolRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/codepool/clear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCodePoolActionRequestToJSON(requestParameters.kvintaCodePoolActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Remove used codes from pool
     * Remove used codes from pool
     */
    async clearPool(requestParameters: ClearPoolRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.clearPoolRaw(requestParameters);
        return await response.value();
    }

    /**
     * Release reserved workflow codes
     * Release reserved workflow codes
     */
    async clearPool1Raw(requestParameters: ClearPool1Request): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaReleaseWorkflowCodesRequest === null || requestParameters.kvintaReleaseWorkflowCodesRequest === undefined) {
            throw new runtime.RequiredError('kvintaReleaseWorkflowCodesRequest','Required parameter requestParameters.kvintaReleaseWorkflowCodesRequest was null or undefined when calling clearPool1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/codepool/release`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaReleaseWorkflowCodesRequestToJSON(requestParameters.kvintaReleaseWorkflowCodesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     * Release reserved workflow codes
     * Release reserved workflow codes
     */
    async clearPool1(requestParameters: ClearPool1Request): Promise<KvintaOperationResponseInteger> {
        const response = await this.clearPool1Raw(requestParameters);
        return await response.value();
    }

    /**
     * Delete pool
     * Delete pool
     */
    async deleteCodePoolRaw(requestParameters: DeleteCodePoolRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaCodePoolActionRequest === null || requestParameters.kvintaCodePoolActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaCodePoolActionRequest','Required parameter requestParameters.kvintaCodePoolActionRequest was null or undefined when calling deleteCodePool.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/codepool/deletePool`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCodePoolActionRequestToJSON(requestParameters.kvintaCodePoolActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Delete pool
     * Delete pool
     */
    async deleteCodePool(requestParameters: DeleteCodePoolRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.deleteCodePoolRaw(requestParameters);
        return await response.value();
    }

    /**
     * List code pools with statistic
     * List code pools
     */
    async listCodePoolsRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseListCodePoolStatistic>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codepool/listCodePools`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListCodePoolStatisticFromJSON(jsonValue));
    }

    /**
     * List code pools with statistic
     * List code pools
     */
    async listCodePools(): Promise<KvintaOperationResponseListCodePoolStatistic> {
        const response = await this.listCodePoolsRaw();
        return await response.value();
    }

    /**
     * List code pools unique gtins
     * List code pools unique gtins
     */
    async listCodePoolsGtinsRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseListString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codepool/listCodePoolsGtins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListStringFromJSON(jsonValue));
    }

    /**
     * List code pools unique gtins
     * List code pools unique gtins
     */
    async listCodePoolsGtins(): Promise<KvintaOperationResponseListString> {
        const response = await this.listCodePoolsGtinsRaw();
        return await response.value();
    }

    /**
     * Upload file with codes
     * Upload file with codes
     */
    async uploadRaw(requestParameters: UploadRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling upload.');
        }

        if (requestParameters.gtin === null || requestParameters.gtin === undefined) {
            throw new runtime.RequiredError('gtin','Required parameter requestParameters.gtin was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.gtin !== undefined) {
            formParams.append('gtin', requestParameters.gtin as any);
        }

        const response = await this.request({
            path: `/codepool/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Upload file with codes
     * Upload file with codes
     */
    async upload(requestParameters: UploadRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.uploadRaw(requestParameters);
        return await response.value();
    }

}
