import React from 'react';
import { InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  outlined: {
    zIndex: 1,
    transform: 'translate(14px, 10px) scale(1)',
    pointerEvents: null,
  },
});

interface IFilterInputLabelProps {
  classes: any;
  htmlFor: string;
  child: any;
}

class FilterInputLabel extends React.Component<IFilterInputLabelProps> {
  render() {
    const { classes, children, child, htmlFor } = this.props;
    return (
      <InputLabel htmlFor={htmlFor} classes={{ outlined: classes.outlined }} style={{ whiteSpace: 'nowrap' }}>
        {child}
      </InputLabel>
    );
  }
}

export default withStyles(styles)(FilterInputLabel);
