/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaHierarchyLevelType {
    Pk = 'PK',
    Sgtin = 'SGTIN',
    Sscc = 'SSCC'
}

export function KvintaHierarchyLevelTypeFromJSON(json: any): KvintaHierarchyLevelType {
    return KvintaHierarchyLevelTypeFromJSONTyped(json, false);
}

export function KvintaHierarchyLevelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaHierarchyLevelType {
    return json as KvintaHierarchyLevelType;
}

export function KvintaHierarchyLevelTypeToJSON(value?: KvintaHierarchyLevelType | null): any {
    return value as any;
}

