import React from 'react';
import { useCallback, FormEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useAuth } from '../../service/auth/useAuth';
import { useIntl } from 'react-intl';
import kvintaLogo from '../../../assets/img/kvinta-logo2.png';
import kvintaBigImg from '../../../assets/img/kvinta-big-img.png';

interface LocationState {
  from: { pathname?: string };
}

export function Login() {
  const location = useLocation();
  const history = useHistory();
  const currentLocationState = (location.state || { from: { pathname: '/' } }) as LocationState;
  const auth = useAuth();
  const intl = useIntl();

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      await auth.login();
    },
    [auth],
  );

  if (auth.isAuth) {
    history.push(currentLocationState?.from);
    return <div></div>;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        md={7}
        sm={4}
        sx={{
          backgroundImage: `url(${kvintaBigImg as string})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        xs={false}
      />
      <Grid
        item
        square
        component={Paper}
        elevation={6}
        md={5}
        sm={8}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        xs={12}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt="Kvinta"
            src={kvintaLogo}
            sx={{ minWidth: 80, width: 'auto', height: 80, m: 3 }}
            variant="rounded"
          />
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'buttons.signIn' })}
          </Typography>
          <Box noValidate component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
            <Button fullWidth sx={{ mt: 3, mb: 2 }} type="submit" variant="contained">
              {intl.formatMessage({ id: 'buttons.signInButton' })}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
