import React, { FC } from 'react';

type TPopupBody = {
  listNames: any[];
};
export const GtinListPopupBody: FC<TPopupBody> = ({ listNames }) => {
  const list = listNames.map((row) => (
    <li key={row.id}>
      <b>{row.gtin}</b>
    </li>
  ));
  return <ul style={{ margin: 0 }}>{list}</ul>;
};
