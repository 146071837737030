import React, { FC } from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGreen } from '../ButtonGreen';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { EButtonState } from '../../common';
import styles from './FormControlButtons.module.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type TFormControlButtons = {
  buttonState: EButtonState;
  formIsOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onOpen: () => void;
} & WrappedComponentProps;

const FormControlButtonsBase: FC<TFormControlButtons> = ({
  intl,
  buttonState,
  formIsOpen,
  onCancel,
  onConfirm,
  onOpen,
}) => {
  return (
    <div className={styles.headerButtonContainer}>
      {formIsOpen ? (
        <>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CloseIcon color="primary" />}
            onClick={(e) => {
              onCancel();
            }}
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <ButtonGreen
            label={intl.formatMessage({ id: 'button.confirm' })}
            icon={<DoneIcon color="secondary" />}
            onClick={onConfirm}
            buttonState={buttonState}
            id={'openOrSubmitButton'}
          />
        </>
      ) : (
        <ButtonGreen
          label={intl.formatMessage({ id: 'button.edit' })}
          icon={<EditIcon color="secondary" />}
          onClick={onOpen}
          buttonState={EButtonState.ACTIVE}
          id={'openOrSubmitButton'}
        />
      )}
    </div>
  );
};

export const FormControlButtons = injectIntl(FormControlButtonsBase);
