import React, {FC} from 'react';
import {Provider} from 'mobx-react';
import {createStores} from './stores';
import ReactDOM from 'react-dom';
import {TAppOptionsConfig} from 'kvinta/common';
import * as config from './config';
import {App} from './app';
import {ResizeObserver} from '@juggle/resize-observer';
import {createBrowserHistory} from "history";

declare const window: any;

if (!('ResizeObserver' in window)) {
  window.ResizeObserver = ResizeObserver;
  // Only use it when you have this trouble: https://github.com/wellyshen/react-cool-dimensions/issues/45
  // window.ResizeObserverEntry = ResizeObserverEntry;
}

type  TWrapperApp ={
  config: TAppOptionsConfig
}
const WrapperApp:FC<TWrapperApp> = ({config}) => {
  const history = createBrowserHistory();
  const store = createStores(config,history);
  return (
    <Provider {...store}>
      <App history={history}/>
    </Provider>
  )
}

function appStarter  (config: TAppOptionsConfig,)  {
  ReactDOM.render(<WrapperApp config={config}/>, document.getElementById('root'));
}
appStarter(config)
