import { TextField } from '@mui/material';
import React from 'react';
import { injectIntl } from 'react-intl';

const FormTextFieldBase: React.FunctionComponent<{
  id: string;
  label: string;
  values: { [id: string]: string };
  touched: { [id: string]: boolean | undefined };
  errors: { [id: string]: string | undefined };
  intl: any;
}> = ({ id, label, values, errors, touched, intl }) => {
  return (
    <div>
      <TextField
        id={id}
        margin="dense"
        label={label}
        fullWidth
        value={values[id] || ''}
        error={Boolean(touched[id] && errors[id])}
      />
      {touched[id] && errors[id] && (
        <div style={{ width: '100%', textAlign: 'right', color: 'red', fontSize: '12px' }}>
          {intl.formatMessage({ id: errors[id] })}
        </div>
      )}
    </div>
  );
};
export const FormTextField = injectIntl(FormTextFieldBase);

const FormDateFieldBase: React.FunctionComponent<{
  id: string;
  label: string;
  values: { [id: string]: string };
  touched: { [id: string]: boolean | undefined };
  errors: { [id: string]: string | undefined };
  intl: any;
}> = ({ id, label, values, errors, touched, intl }) => {
  return (
    <div>
      <TextField
        id={id}
        margin="dense"
        label={label}
        fullWidth
        type="date"
        value={values[id] || ''}
        error={Boolean(touched[id] && errors[id])}
      />
      {touched[id] && errors[id] && (
        <div style={{ width: '100%', textAlign: 'right', color: 'red', fontSize: '12px' }}>
          {intl.formatMessage({ id: errors[id] })}
        </div>
      )}
    </div>
  );
};
export const FormDateField = injectIntl(FormDateFieldBase);
