import React, { FC } from 'react';

type TPopupBody = {
  listNames: any[];
};
export const RskuListPopupBody: FC<TPopupBody> = ({ listNames }) => {
  const list = listNames.map((row) => (
    <li key={row.id}>
      {'RSKU: '}
      <b>{row.rsku}</b>
    </li>
  ));
  return <ul style={{ margin: 0 }}>{list}</ul>;
};
