import { action, makeObservable, observable } from 'mobx';
import { IIsLoadingOnTableRow, ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  CodePoolApi,
  DefaultApi,
  KvintaTaskDefinitionResponse,
  KvintaTaskStatusEnum,
  ProductApi,
} from '../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../common/HistoryStore';
import { TApis } from '../../../stores';
import { normalizeApiResponse } from '../../apis/apis';

export type TTaskListDefinition = KvintaTaskDefinitionResponse & { id: string };

export interface ITaskRow extends TTaskListDefinition, IIsLoadingOnTableRow, ISelectableRow {}

export class TaskStore extends SelectableStore<ITaskRow> {
  private _config: TAppOptionsConfig;
  private _tasksApi: DefaultApi;
  private _historyStore: HistoryStore;
  private _productApi: ProductApi;
  private _codePoolApi: CodePoolApi;
  private _notificationManager: NotificationManager;
  private _pollingTimeout: number | null;
  private _timeoutSeconds = 40;

  pollingTimer = 0;

  taskListRequestTime = 0;
  startPolling = false;
  isLoading: boolean;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      setLoadingForAllTask: action.bound,
      setPollingTimer: action.bound,
      setStartPolling: action.bound,
      setTaskListRequestTime: action.bound,
      setIsLoading: action.bound,
      fetchTaskList: action.bound,
      deleteTask: action.bound,
      deleteListTask: action.bound,
      runTask: action.bound,
      resumeTask: action.bound,
      pauseTask: action.bound,
      stopTask: action.bound,
      setTaskStatusById: action.bound,
      setLoadingTaskById: action.bound,
      pollStatusForAllTasks: action.bound,
      clearPollingTimeouts: action.bound,
      getListTaskDefinitions: action.bound,
      requestPauseTaskDefinition: action.bound,
      requestStopTaskDefinition: action.bound,
      requestRunTaskDefinition: action.bound,
      requestResumeTaskDefinition: action.bound,
      setTaskList: action.bound,
      requestDeleteTaskDefinition: action.bound,

      isLoading: observable,
      taskListRequestTime: observable,
      startPolling: observable,
      pollingTimer: observable,
    });

    this._tasksApi = apis.defaultApi;
    this._productApi = apis.productApi;
    this._historyStore = apis.historyStore;
    this._codePoolApi = apis.codePoolApi;
    this._config = config;
    this._notificationManager = apis.notificationManager;
    this._pollingTimeout = null;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  setPollingTimer(time: number) {
    this.pollingTimer = time;
  }
  setTaskListRequestTime(time: number) {
    this.taskListRequestTime = time;
  }
  setStartPolling(isPolling: boolean) {
    this.startPolling = isPolling;
  }

  async fetchTaskList() {
    this.setIsLoading(true);
    this.setListData([]);
    await this.setTaskList();
    this.setIsLoading(false);
  }

  pollStatusForAllTasks() {
    this.clearPollingTimeouts();
    const runningTaskIds = this.listData.reduce((acc, task) => {
      if (task.taskStatus === KvintaTaskStatusEnum.Running) {
        acc.push(task.id);
      }
      return acc;
    }, [] as string[]);
    if (runningTaskIds.length) {
      const timeout = this._timeoutSeconds * 1000;
      this.setPollingTimer(this._timeoutSeconds);
      this._pollingTimeout = window.setTimeout(async () => {
        this.setLoadingForAllTask(true);
        await this.setTaskList();
      }, timeout);
    } else {
      this.setPollingTimer(0);
    }
  }

  async setTaskList() {
    this.setPollingTimer(0);
    const taskListRequestTime = new Date().getMilliseconds();
    this.setTaskListRequestTime(taskListRequestTime);
    const resultData = await normalizeApiResponse<Array<KvintaTaskDefinitionResponse> | null>(
      () => this.getListTaskDefinitions(),
      'An error occurred when trying to get a list of tasks',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      const oldSelection = new Map();
      if (this.listData) {
        this.listData.forEach((row) => {
          oldSelection.set(row.id, row.isSelected);
        });
      }
      this.setListData(
        (resultData.result || []).map((comp) => {
          const selected = oldSelection.get(comp.id) || false;
          return {
            id: comp.id,
            name: comp.name,
            priority: comp.priority,
            generatedTopLevelUnitIds: comp.generatedTopLevelUnitIds,
            progress: comp.progress,
            rootWorkflowId: comp.rootWorkflowId,
            taskStatus: comp.taskStatus,
            updated: comp.updated,
            isSelected: selected,
            isLoading: false,
          };
        }),
      );
      const isLastRequest = this.taskListRequestTime === taskListRequestTime;
      if (isLastRequest && this.startPolling) {
        this.pollStatusForAllTasks();
      }
    }
  }

  getListTaskDefinitions() {
    return this._tasksApi.listTaskDefinitions({
      kvintaListTasksRequest: {},
    });
  }

  clearPollingTimeouts() {
    if (this._pollingTimeout) {
      window.clearTimeout(this._pollingTimeout);
    }
  }

  async deleteListTask(listTaskForDelete: ITaskRow[]) {
    this.setIsLoading(true);
    const actions = listTaskForDelete.map(
      (taskForDelete) => () =>
        normalizeApiResponse<any>(
          () => this.requestDeleteTaskDefinition(taskForDelete.id),
          `An error occurred when trying to delete task by ID:${taskForDelete.id}`,
        ),
    );
    const responseData = await Promise.all(actions.map((action) => action()));
    const errorMessage = responseData
      .filter((data) => data.error)
      .map((data) => data.error)
      .join('\n');
    if (errorMessage) {
      this._notificationManager.sendError(errorMessage);
    }
    await this.fetchTaskList();
    this.setIsLoading(false);
  }

  async deleteTask(taskDefinitionId: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.requestDeleteTaskDefinition(taskDefinitionId),
      `An error occurred when trying to delete task by ID:${taskDefinitionId}`,
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchTaskList();
    }
    this.setIsLoading(false);
  }

  requestDeleteTaskDefinition(taskDefinitionId: string) {
    return this._tasksApi.deleteTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  async runTask(taskDefinitionId: string) {
    this.clearPollingTimeouts();
    this.setPollingTimer(0);
    this.setLoadingTaskById(taskDefinitionId, true);
    const resultData = await normalizeApiResponse<KvintaTaskDefinitionResponse | null>(
      () => this.requestRunTaskDefinition(taskDefinitionId),
      `Failed to start task`,
    );
    if (resultData.result) {
      const status = resultData.result.taskStatus;
      this.setTaskStatusById(taskDefinitionId, status || KvintaTaskStatusEnum.Running);
      this.pollStatusForAllTasks();
    } else if (resultData.error) {
      this._notificationManager.sendError(
        `Failed to start task ${taskDefinitionId}${resultData.error ? `: ${resultData.error}` : ''}`,
      );
    }
    this.setLoadingTaskById(taskDefinitionId, false);
  }

  requestRunTaskDefinition(taskDefinitionId: string) {
    return this._tasksApi.runTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  async resumeTask(taskDefinitionId: string) {
    this.clearPollingTimeouts();
    this.setPollingTimer(0);
    this.setLoadingTaskById(taskDefinitionId, true);
    const resultData = await normalizeApiResponse<KvintaTaskDefinitionResponse | null>(
      () => this.requestResumeTaskDefinition(taskDefinitionId),
      `Failed to resume task`,
    );
    if (resultData.result) {
      const status = resultData.result.taskStatus;
      this.setTaskStatusById(taskDefinitionId, status || KvintaTaskStatusEnum.Running);
      this.pollStatusForAllTasks();
    } else if (resultData.error) {
      this._notificationManager.sendError(
        `Failed to resume task ${taskDefinitionId}${resultData.error ? `: ${resultData.error}` : ''}`,
      );
    }
    this.setLoadingTaskById(taskDefinitionId, false);
  }

  requestResumeTaskDefinition(taskDefinitionId: string) {
    return this._tasksApi.resumeTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  async pauseTask(taskDefinitionId: string) {
    this.clearPollingTimeouts();
    this.setPollingTimer(0);
    this.setLoadingTaskById(taskDefinitionId, true);
    const resultData = await normalizeApiResponse<KvintaTaskDefinitionResponse | null>(
      () => this.requestPauseTaskDefinition(taskDefinitionId),
      `Failed to pause task`,
    );
    if (resultData.result) {
      const status = resultData.result.taskStatus;
      this.setTaskStatusById(taskDefinitionId, status || KvintaTaskStatusEnum.Paused);
      this.pollStatusForAllTasks();
    } else if (resultData.error) {
      this._notificationManager.sendError(
        `Failed to pause task ${taskDefinitionId}${resultData.error ? `: ${resultData.error}` : ''}`,
      );
    }
    this.setLoadingTaskById(taskDefinitionId, false);
  }

  requestPauseTaskDefinition(taskDefinitionId: string) {
    return this._tasksApi.pauseTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  async stopTask(taskDefinitionId: string) {
    this.clearPollingTimeouts();
    this.setPollingTimer(0);
    this.setLoadingTaskById(taskDefinitionId, true);
    this.setTaskStatusById(taskDefinitionId, KvintaTaskStatusEnum.Scheduled);
    const resultData = await normalizeApiResponse<KvintaTaskDefinitionResponse | null>(
      () => this.requestStopTaskDefinition(taskDefinitionId),
      `Failed to stop task`,
    );
    if (resultData.result) {
      const status = resultData.result.taskStatus;
      this.setTaskStatusById(taskDefinitionId, status || KvintaTaskStatusEnum.Completed);
      this.pollStatusForAllTasks();
    } else if (resultData.error) {
      this._notificationManager.sendError(
        `Failed to stop task ${taskDefinitionId}${resultData.error ? `: ${resultData.error}` : ''}`,
      );
    }
    this.setLoadingTaskById(taskDefinitionId, false);
  }

  requestStopTaskDefinition(taskDefinitionId: string) {
    return this._tasksApi.stopTaskDefinition({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId,
      },
    });
  }

  setLoadingForAllTask(loadingStatus: boolean) {
    this.listData = this.listData.map((dataRow) => {
      dataRow.isLoading = loadingStatus;
      return dataRow;
    });
  }
  setLoadingTaskById(taskDefinitionId: string, loadingStatus: boolean) {
    this.listData = this.listData.map((dataRow) => {
      if (dataRow.id === taskDefinitionId) {
        dataRow.isLoading = loadingStatus;
      }
      return dataRow;
    });
  }

  setTaskStatusById(taskDefinitionId: string, taskStatus: KvintaTaskStatusEnum) {
    this.listData = this.listData.map((dataRow) => {
      if (dataRow.id === taskDefinitionId) {
        dataRow.taskStatus = taskStatus;
      }
      return dataRow;
    });
  }
}

export const STORE_ID = 'taskStore';
