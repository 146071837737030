/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGeneratedEpcCode,
    KvintaGeneratedEpcCodeFromJSON,
    KvintaGeneratedEpcCodeFromJSONTyped,
    KvintaGeneratedEpcCodeToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaQueueAction,
    KvintaQueueActionFromJSON,
    KvintaQueueActionFromJSONTyped,
    KvintaQueueActionToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceChanges,
    KvintaResourceChangesFromJSON,
    KvintaResourceChangesFromJSONTyped,
    KvintaResourceChangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationResponseGeneratedEpcCode
 */
export interface KvintaOperationResponseGeneratedEpcCode {
    /**
     * 
     * @type {KvintaGeneratedEpcCode}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    data?: KvintaGeneratedEpcCode | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    status: KvintaOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    executionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    rootExecutionId?: string | null;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    resource?: KvintaResource | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    duplicate: boolean;
    /**
     * 
     * @type {KvintaResourceChanges}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    resourceChanges?: KvintaResourceChanges | null;
    /**
     * 
     * @type {KvintaQueueAction}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    queueAction?: KvintaQueueAction | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseGeneratedEpcCode
     */
    continued?: boolean | null;
}

export function KvintaOperationResponseGeneratedEpcCodeFromJSON(json: any): KvintaOperationResponseGeneratedEpcCode {
    return KvintaOperationResponseGeneratedEpcCodeFromJSONTyped(json, false);
}

export function KvintaOperationResponseGeneratedEpcCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationResponseGeneratedEpcCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : KvintaGeneratedEpcCodeFromJSON(json['data']),
        'status': KvintaOperationStatusFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'executionId': !exists(json, 'executionId') ? undefined : json['executionId'],
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'duplicate': json['duplicate'],
        'resourceChanges': !exists(json, 'resourceChanges') ? undefined : KvintaResourceChangesFromJSON(json['resourceChanges']),
        'queueAction': !exists(json, 'queueAction') ? undefined : KvintaQueueActionFromJSON(json['queueAction']),
        'continued': !exists(json, 'continued') ? undefined : json['continued'],
    };
}

export function KvintaOperationResponseGeneratedEpcCodeToJSON(value?: KvintaOperationResponseGeneratedEpcCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': KvintaGeneratedEpcCodeToJSON(value.data),
        'status': KvintaOperationStatusToJSON(value.status),
        'error': value.error,
        'message': value.message,
        'executionId': value.executionId,
        'rootExecutionId': value.rootExecutionId,
        'resource': KvintaResourceToJSON(value.resource),
        'duplicate': value.duplicate,
        'resourceChanges': KvintaResourceChangesToJSON(value.resourceChanges),
        'queueAction': KvintaQueueActionToJSON(value.queueAction),
        'continued': value.continued,
    };
}


