import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig, TListOfErrors } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { CodePoolApi, ConfApi, KvintaCodePoolStatistic } from '../../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../../common/HistoryStore';
import { TApis } from '../../../../stores';
import { SelectableStore } from '../../../common';
import { normalizeApiResponse } from '../../../apis/apis';
import { TTextInputData, TValidationGroupData } from '../../../common/formUtils/types';
import { formRoot, textInput } from '../../../common/formUtils/formDataGenerators';
import { hasLength, isInteger, isNotEmpty } from '../../../common/formUtils/validators';
import { validateForm } from '../../../common/formUtils/core';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';

export type TCreateCsvForm = {
  gtin: string;
  file: File | null;
  showError: boolean;
  listOfErrors: TListOfErrors;
};

export type TCsvFormData = {
  idPoolForm: TValidationGroupData;
  'idPoolForm.gtin': TTextInputData;
  'idPoolForm.file': TTextInputData;
};

export type TIdPoolStatistic = {
  gtin: string;
  available: number;
  reserved: number;
  used: number;
};

export interface ICodePoolRow extends TIdPoolStatistic, ISelectableRow {}

export class CodePoolStore extends SelectableStore<ICodePoolRow> {
  private _config: TAppOptionsConfig;
  private _configApi: ConfApi;
  private _codePoolApi: CodePoolApi;
  private _historyStore: HistoryStore;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  isUploadCsvFormOpen: boolean;
  createCsvForm: TCreateCsvForm | null;
  csvFormData: TCsvFormData | null;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      setIsLoading: action.bound,
      setUploadCsvFormOpen: action.bound,
      setCreateCsvForm: action.bound,
      deleteCreateCsvForm: action.bound,

      openUploadCsvForm: action.bound,
      closeUploadCsvForm: action.bound,

      clearPoolByGtin: action.bound,
      deleteCodePoolByGtin: action.bound,
      getListCodePools: action.bound,
      uploadCsvFile: action.bound,
      submitUpload: action.bound,
      handleFormBlur: action.bound,
      handleFormChange: action.bound,

      fetchIdPoolList: action.bound,

      isLoading: observable,
      isUploadCsvFormOpen: observable,
      createCsvForm: observable,
      csvFormData: observable,
    });
    this._configApi = apis.configApi;
    this._codePoolApi = apis.codePoolApi;
    this._historyStore = apis.historyStore;
    this._config = config;
    this._notificationManager = apis.notificationManager;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setUploadCsvFormOpen(isOpen: boolean) {
    this.isUploadCsvFormOpen = isOpen;
  }

  setCreateCsvForm() {
    this.csvFormData = validateForm<TCsvFormData>(generateIdPoolFormData(ID_POOL_FORM_ROOT_ID, {}));
  }

  deleteCreateCsvForm() {
    this.createCsvForm = null;
  }

  openUploadCsvForm() {
    this.setCreateCsvForm();
    this.setUploadCsvFormOpen(true);
  }

  closeUploadCsvForm() {
    this.setUploadCsvFormOpen(false);
    this.deleteCreateCsvForm();
  }

  handleFormBlur(id: string) {
    const formData = toJS(this.csvFormData);
    this.csvFormData = handleFormBlur(formData, id);
  }

  handleFormChange(id: string, value: any) {
    const formData = toJS(this.csvFormData);
    this.csvFormData = handleFormChange(formData, id, value);
  }

  async fetchIdPoolList() {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaCodePoolStatistic[]>(
      this.getListCodePools,
      'An error occurred when trying to fetch Id Pool list',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      const oldSelection = new Map();
      if (this.listData) {
        this.listData.forEach((row) => {
          oldSelection.set(row.gtin, row.isSelected);
        });
      }
      this.setListData(
        (resultData.result || []).map((idPool) => {
          const selected = oldSelection.get(idPool.gtin) || false;
          return {
            ...normalizeIdPool(idPool),
            isSelected: selected,
            id: idPool.gtin,
          };
        }),
      );
    }
    this.setIsLoading(false);
  }

  async submitDeleteIdPool(gtin: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.deleteCodePoolByGtin(gtin),
      'An error occurred when trying to delete id Pool',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchIdPoolList();
    }
    this.setIsLoading(false);
  }

  async submitClearPool(gtin: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.clearPoolByGtin(gtin),
      'An error occurred when trying to clear id Pool',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchIdPoolList();
    }
    this.setIsLoading(false);
  }

  async submitUpload() {
    this.setIsLoading(true);
    const { file, gtin } = transformIdPoolFormData(this.csvFormData);
    const resultData = await normalizeApiResponse<any>(
      () => this.uploadCsvFile(file, gtin),
      'An error occurred when trying to upload file',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.closeUploadCsvForm();
      await this.fetchIdPoolList();
    }
    this.setIsLoading(false);
  }

  async clearPoolByGtin(gtin: string) {
    return this._codePoolApi.clearPool({
      kvintaCodePoolActionRequest: {
        gtin,
      },
    });
  }

  async deleteCodePoolByGtin(gtin: string) {
    return this._codePoolApi.deleteCodePool({
      kvintaCodePoolActionRequest: {
        gtin,
      },
    });
  }

  async getListCodePools() {
    return this._codePoolApi.listCodePools();
  }

  async uploadCsvFile(file: Blob, gtin: string) {
    return this._codePoolApi.upload({ file, gtin });
  }
}

export const STORE_ID = 'codePoolStore';
export const ID_POOL_FORM_ROOT_ID = 'idPoolForm';

export function normalizeIdPool(data: KvintaCodePoolStatistic): TIdPoolStatistic {
  return {
    gtin: data.gtin,
    available: data.available,
    reserved: data.reserved,
    used: data.used,
  };
}

function generateIdPoolFormData(rootFormId: string, initialValues: any) {
  return formRoot<TCsvFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'gtin',
        value: initialValues.gtin || '',
        validations: [
          isNotEmpty({ errorMessage: 'validation.error.required' }),
          isInteger({ errorMessage: 'validation.error.onlyInteger' }),
          hasLength({
            errorMessage: 'validation.error.gtin',
            requiredLength: 14,
          }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'file',
        value: initialValues.file || null,
        validations: [
          fileIsRequired({ errorMessage: 'validation.error.file-required' }),
          typeForFileIsEqual({ errorMessage: 'validation.error.onlyCsvFile', typeFile: 'csv' }),
        ],
        isRequiredField: true,
      }),
    ],
  });
}

function transformIdPoolFormData(locationFormData: TCsvFormData) {
  return {
    gtin: locationFormData[`${ID_POOL_FORM_ROOT_ID}.gtin`].value.trim(),
    file: locationFormData[`${ID_POOL_FORM_ROOT_ID}.file`].value,
  };
}

function typeForFileIsEqual(options: { errorMessage: string; typeFile: string }) {
  return function (id, data) {
    if (data[id].value) {
      const separatedFileName = data[id].value.name.split('.');
      if (separatedFileName.length !== 2) {
        return options.errorMessage;
      }
      if (separatedFileName[1] !== options.typeFile) {
        return options.errorMessage;
      }
    }
    return null;
  };
}

function fileIsRequired(options: { errorMessage: string }) {
  return function (id, data) {
    if (!data[id].value) {
      return options.errorMessage;
    }
    return null;
  };
}
