import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { THoursDataForDayWeek } from './InactivityHoursStore';
import styles from '../../../../assets/css/partials/modules/InactivityHours.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { weekdays, weekends } from './InactivityHoursSummaryForm';

type TInactivityHoursForDay = {
  intl: IntlShape;
  dayName: string;
  start: string;
  finish: string;
};
const InactivityHoursForDay: FC<TInactivityHoursForDay> = ({ intl, dayName, start, finish }) => {
  return (
    <div className={styles.inactivityHoursRow}>
      <div className={styles.inactivityHoursRowHeader}>{intl.formatMessage({ id: dayName })}</div>
      <TextField value={start} label="inactivityHours.create-form.field.start" />
      <TextField value={finish} label="inactivityHours.create-form.field.finish" />
    </div>
  );
};

interface IUpdateHoursForm {
  formData: THoursDataForDayWeek;
  intl: IntlShape;
}

const UpdateHoursFormImpl: FC<IUpdateHoursForm> = ({ formData, intl }) => {
  const inactivityHoursFromWeekdays = weekdays.map((day) => (
    <InactivityHoursForDay
      intl={intl}
      key={day.dayName}
      dayName={day.dayName}
      start={formData.blackHours[day.dayNumber]['start']}
      finish={formData.blackHours[day.dayNumber]['finish']}
    />
  ));
  const inactivityHoursFromWeekends = weekends.map((day) => (
    <InactivityHoursForDay
      intl={intl}
      key={day.dayName}
      dayName={day.dayName}
      start={formData.blackHours[day.dayNumber]['start']}
      finish={formData.blackHours[day.dayNumber]['finish']}
    />
  ));
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <h1 className={styles.timezoneHeader}>
          {intl.formatMessage({ id: 'inactivityHours.create-form.field.hoursFieldTitle' })}
        </h1>
        <div className={styles.timezoneSelectInput}>
          <TextField value={formData['currentTimeZone']} label="inactivityHours.create-form.field.timeZone" />
        </div>
      </div>
      <div className={styles.inactivityHoursRowContainer}>{inactivityHoursFromWeekdays}</div>
      <div className={styles.inactivityHoursRowContainer}>{inactivityHoursFromWeekends}</div>
      <div className={styles.formContainerSection}>
        {intl.formatMessage({ id: 'inactivityHours.create-form.field.hoursFieldDescription' })}
      </div>
    </div>
  );
};

export const InactivityHoursSummaryView = UpdateHoursFormImpl;
