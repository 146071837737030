/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaTaskStatusEnum {
    Created = 'CREATED',
    Scheduled = 'SCHEDULED',
    Paused = 'PAUSED',
    Running = 'RUNNING',
    Completed = 'COMPLETED',
    Error = 'ERROR'
}

export function KvintaTaskStatusEnumFromJSON(json: any): KvintaTaskStatusEnum {
    return KvintaTaskStatusEnumFromJSONTyped(json, false);
}

export function KvintaTaskStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskStatusEnum {
    return json as KvintaTaskStatusEnum;
}

export function KvintaTaskStatusEnumToJSON(value?: KvintaTaskStatusEnum | null): any {
    return value as any;
}

