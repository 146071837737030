import { Button, FormControl } from '@mui/material';
import React, { FC } from 'react';
import { TProductDataView } from './ProductStore';
import styles from '../../../../assets/css/partials/modules/Products.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { CheckBoxField } from '../../../components/FormFields/Fields_Exp/CheckBoxField';
import { ProductHierarchyLevelView } from '../../../components/ProductHierarchyLevel/ProductHierarchyLevelView';

interface DialogCreateTaskProps {
  formData: TProductDataView;
  intl: any;
}

const UpdateProductFormImpl: FC<DialogCreateTaskProps> = ({ formData, intl }) => {
  if (!formData) return null;
  const hideShippingAndReceiving = formData.shipToLocationId === 'None';
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <TextField value={formData.rsku} label="product.create-form.field.rsku" />
        <TextField value={formData.tpId} label="product.create-form.field.tpId" />
        <TextField value={formData.tpPn} label="product.create-form.field.tpPn" />
      </div>
      <div className={styles.formRow}>
        <TextField value={formData.lsku} label="product.create-form.field.lsku" />
        <div>
          <TextField value={formData.locationName} label="product.create-form.field.locationId" />
          <CheckBoxField value={formData.createStockInQad} label="product.create-form.field.createStockInQad" />
        </div>
        <div>
          <TextField value={formData.shipToLocationName} label="product.create-form.field.shipToLocationId" />
          {hideShippingAndReceiving ? null : (
            <CheckBoxField
              value={formData.shippingAndReceiving}
              label="product.create-form.field.shippingAndReceiving"
            />
          )}
        </div>
      </div>
      <div className={styles.hierarchySection}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            return null;
          }}
          disabled
        >
          {intl.formatMessage({ id: 'product.create-form.add-level' })}
        </Button>
        <FormControl fullWidth>
          {formData.hierarchy.map((hierarchyLevel, index) => {
            return (
              <ProductHierarchyLevelView
                key={index}
                index={index}
                formData={formData}
                data={hierarchyLevel}
                intl={intl}
                showSingleEpcisDocument={!(index === formData.hierarchy.length - 1)}
              />
            );
          })}
        </FormControl>
      </div>
    </div>
  );
};

export const ProductSummaryView = UpdateProductFormImpl;
