/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaBlackHour
 */
export interface KvintaBlackHour {
    /**
     * 
     * @type {string}
     * @memberof KvintaBlackHour
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaBlackHour
     */
    finish: string;
}

export function KvintaBlackHourFromJSON(json: any): KvintaBlackHour {
    return KvintaBlackHourFromJSONTyped(json, false);
}

export function KvintaBlackHourFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaBlackHour {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'finish': json['finish'],
    };
}

export function KvintaBlackHourToJSON(value?: KvintaBlackHour | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'finish': value.finish,
    };
}


