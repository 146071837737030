import { matchPath } from 'react-router-dom';
import { EMenuItemType, TItemMenu } from '../../../menu';

export function isSelectedMenu(itemMenu: TItemMenu, selectedItem: string): boolean {
  if (itemMenu.type === EMenuItemType.CHILD_ITEM) {
    if (
      (itemMenu.path === '/' && selectedItem === itemMenu.path) ||
      (itemMenu.path !== '/' && matchPath(selectedItem, itemMenu.path))
    ) {
      return true;
    }
    if (itemMenu.selectOnPaths) {
      for (const p of itemMenu.selectOnPaths) {
        if (itemMenu.path !== '/' && matchPath(selectedItem, p)) {
          return true;
        }
      }
    }
    return false;
  } else return false;
}
