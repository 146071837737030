import { useHistory } from 'react-router-dom';
import { useAuth } from '../../service/auth/useAuth';
import React from 'react';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const history = useHistory();
  if (!auth.isAuth) {
    history.push('/login');
    return null;
  }
  return children;
};
