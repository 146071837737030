/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSsccGenerationStep {
    SendMessageMcds = 'SEND_MESSAGE_MCDS',
    CheckCompliance = 'CHECK_COMPLIANCE',
    Shipping = 'SHIPPING',
    CheckShipping = 'CHECK_SHIPPING',
    SendMessageQad = 'SEND_MESSAGE_QAD'
}

export function KvintaSsccGenerationStepFromJSON(json: any): KvintaSsccGenerationStep {
    return KvintaSsccGenerationStepFromJSONTyped(json, false);
}

export function KvintaSsccGenerationStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSsccGenerationStep {
    return json as KvintaSsccGenerationStep;
}

export function KvintaSsccGenerationStepToJSON(value?: KvintaSsccGenerationStep | null): any {
    return value as any;
}

