import {TAppOptionsConfig} from 'kvinta/common/Interfaces';
import {
  CodePoolApi,
  ConfApi,
  Configuration,
  DefaultApi,
  KvintaOperationStatus,
  ProductApi
} from "./kvinta-load-test-toolkit";
import {localStorageAuthKey} from "../../config";

const authKey = localStorageAuthKey

export function createApi(config: TAppOptionsConfig) {
  return new DefaultApi(
    new Configuration({
      basePath: config.apiPath,
      middleware: [initJwtMiddleware(authKey)],
    })
  )
}

export function createProductApi(config: TAppOptionsConfig) {
  return new ProductApi(
    new Configuration({
      basePath: config.apiPath,
      middleware: [initJwtMiddleware(authKey)],
    })
  )
}

export function createConfigurationApi(config: TAppOptionsConfig) {
  return new ConfApi(
    new Configuration({
      basePath: config.apiPath,
      middleware: [initJwtMiddleware(authKey)],
    })
  )
}
export function createCodePoolApi(config: TAppOptionsConfig) {
  return new CodePoolApi(
    new Configuration({
      basePath: config.apiPath,
      middleware: [initJwtMiddleware(authKey)],
    })
  )
}

type TActon<DT> = () => Promise<{ status?: KvintaOperationStatus, data?: DT, message?: string }>

export async function normalizeApiResponse<T>(
  action: TActon<T>,
  unhandledErrorMessage = "unhandled error in normalizeApiResponse"
): Promise<{ error: string | null; result: T | null }> {
  return action()
    .then((result) => {
      if (result.status === KvintaOperationStatus.Ok) {
        return {error: null, result: result.data};
      } else {
        return {error: result.message || unhandledErrorMessage, result: null};
      }
    })
    .catch((err) => {
      return {error: err.message || unhandledErrorMessage, result: null};
    });
}

const initJwtMiddleware = (localStorageAuthKey: string) => ({
  pre: (context): Promise<void> => {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem(localStorageAuthKey);
      if (!jwt) {
        reject(new Error('user not logged in'));
      }

      const modifiedContext = {
        ...context,
        init: {
          ...context.init,
          headers: {
            ...context.init.headers,
            Authorization: `Bearer ${jwt}`,
          },
        },
      };
      resolve(modifiedContext);
    });
  },
});

export function saveTokenToLocalStorage(localStorageAuthKey: string, token: string) {
  localStorage.setItem(localStorageAuthKey, token);
}
