import React, { Component } from 'react';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { KSidebarStore, SIDEBAR_STORE_ID } from '../../../components/Sidebar';
import { InactivityHoursStore, STORE_ID as INACTIVITY_HOURS_STORE_ID } from './InactivityHoursStore';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { LoadingBackground } from '../../../components/LoadingBackground';
import styles from '../../../../assets/css/partials/modules/InactivityHours.module.scss';
import { InactivityHoursSummaryForm } from './InactivityHoursSummaryForm';
import { InactivityHoursSummaryView } from './InactivityHoursSummaryView';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface IInactivityHours {
  historyStore?: HistoryStore;
  sidebarStore?: KSidebarStore;
  inactivityHoursStore?: InactivityHoursStore;
  intl: any;
}

class InactivityHoursImpl extends Component<IInactivityHours> {
  componentDidMount(): void {
    this.props.inactivityHoursStore.fetchInactivityHours();
    this.props.sidebarStore.clearMainTitle();
  }

  componentWillUnmount(): void {
    this.props.inactivityHoursStore.cancelUpdateInactivityHoursForm();
  }

  render() {
    const {
      isLoading,
      inactivityHoursData,
      inactivityHoursFormOpen,
      onChangeUpdateInactivityHoursField,
      inactivityHoursFormData,
      onChangeTimeZoneField,
      onBlurUpdateInactivityHoursField,
    } = this.props.inactivityHoursStore;
    const intl = this.props.intl;

    return (
      <div className={styles.summaryMain}>
        {isLoading || !inactivityHoursData ? <LoadingBackground /> : null}
        <div className={styles.headerButtonContainer}>
          <FormControlButtons
            buttonState={formDataToButtonState(inactivityHoursFormData)}
            formIsOpen={inactivityHoursFormOpen}
            onCancel={() => this.props.inactivityHoursStore.cancelUpdateInactivityHoursForm()}
            onConfirm={() => this.props.inactivityHoursStore.submitUpdateInactivityHoursForm()}
            onOpen={() => this.props.inactivityHoursStore.openUpdateInactivityHoursForm()}
          />
        </div>
        {inactivityHoursFormOpen
          ? inactivityHoursFormData && (
              <InactivityHoursSummaryForm
                formData={inactivityHoursFormData}
                intl={intl}
                onChangeValue={onChangeUpdateInactivityHoursField}
                onBlurValue={onBlurUpdateInactivityHoursField}
                onChangeTimeZone={onChangeTimeZoneField}
              />
            )
          : inactivityHoursData && <InactivityHoursSummaryView formData={inactivityHoursData} intl={intl} />}
      </div>
    );
  }
}

export const InactivityHoursSummary = injectIntl(
  inject(INACTIVITY_HOURS_STORE_ID, HISTORY_STORE_ID, SIDEBAR_STORE_ID)(observer(InactivityHoursImpl)),
);
