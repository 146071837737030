/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGeneratedTopLevelUnitInfo,
    KvintaGeneratedTopLevelUnitInfoFromJSON,
    KvintaGeneratedTopLevelUnitInfoFromJSONTyped,
    KvintaGeneratedTopLevelUnitInfoToJSON,
    KvintaTaskPriority,
    KvintaTaskPriorityFromJSON,
    KvintaTaskPriorityFromJSONTyped,
    KvintaTaskPriorityToJSON,
    KvintaTaskStatusEnum,
    KvintaTaskStatusEnumFromJSON,
    KvintaTaskStatusEnumFromJSONTyped,
    KvintaTaskStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTaskDefinitionResponse
 */
export interface KvintaTaskDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionResponse
     */
    name: string;
    /**
     * 
     * @type {KvintaTaskPriority}
     * @memberof KvintaTaskDefinitionResponse
     */
    priority?: KvintaTaskPriority | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaTaskDefinitionResponse
     */
    updated: Date;
    /**
     * 
     * @type {KvintaTaskStatusEnum}
     * @memberof KvintaTaskDefinitionResponse
     */
    taskStatus: KvintaTaskStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskDefinitionResponse
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionResponse
     */
    rootWorkflowId?: string | null;
    /**
     * 
     * @type {Array<KvintaGeneratedTopLevelUnitInfo>}
     * @memberof KvintaTaskDefinitionResponse
     */
    generatedTopLevelUnitIds: Array<KvintaGeneratedTopLevelUnitInfo>;
}

export function KvintaTaskDefinitionResponseFromJSON(json: any): KvintaTaskDefinitionResponse {
    return KvintaTaskDefinitionResponseFromJSONTyped(json, false);
}

export function KvintaTaskDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'priority': !exists(json, 'priority') ? undefined : KvintaTaskPriorityFromJSON(json['priority']),
        'updated': (new Date(json['updated'])),
        'taskStatus': KvintaTaskStatusEnumFromJSON(json['taskStatus']),
        'progress': json['progress'],
        'rootWorkflowId': !exists(json, 'rootWorkflowId') ? undefined : json['rootWorkflowId'],
        'generatedTopLevelUnitIds': ((json['generatedTopLevelUnitIds'] as Array<any>).map(KvintaGeneratedTopLevelUnitInfoFromJSON)),
    };
}

export function KvintaTaskDefinitionResponseToJSON(value?: KvintaTaskDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'priority': KvintaTaskPriorityToJSON(value.priority),
        'updated': (value.updated.toISOString()),
        'taskStatus': KvintaTaskStatusEnumToJSON(value.taskStatus),
        'progress': value.progress,
        'rootWorkflowId': value.rootWorkflowId,
        'generatedTopLevelUnitIds': ((value.generatedTopLevelUnitIds as Array<any>).map(KvintaGeneratedTopLevelUnitInfoToJSON)),
    };
}


