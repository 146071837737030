import React, { FC } from 'react';
import * as DateFns from 'date-fns';

type TDateFormatField = {
  time: number;
};

export const DateFormatField: FC<TDateFormatField> = ({ time }) => {
  const stringTime = DateFns.intlFormat(time, {
    day: 'numeric',
    hour: 'numeric',
    month: 'long',
    year: 'numeric',
    minute: '2-digit',
  });

  return <span>{stringTime}</span>;
};
