import { action, makeObservable, observable } from 'mobx';

export type TCreatePopupOptions = {
  title: JSX.Element | string;
  children: JSX.Element | string;
  onHandlerApply: () => void;
  onHandlerReject?: () => void;
};
type TPopupState = {
  isOpen: boolean;
  title: JSX.Element | string;
  children: JSX.Element | string;
  onHandlerApply: () => void | null;
  onHandlerReject: () => void | null;
} | null;

export class PromptPopupStore {
  state: TPopupState = null;

  constructor() {
    makeObservable(this, {
      state: observable,

      createPopup: action.bound,
      closePopup: action.bound,
      onConfirm: action.bound,
      onReject: action.bound,
    });
  }

  closePopup() {
    this.state = null;
  }

  onReject() {
    if (this.state.onHandlerReject) {
      this.state.onHandlerReject();
    }
    this.closePopup();
  }

  onConfirm() {
    if (this.state.onHandlerApply) {
      this.state.onHandlerApply();
    }
    this.closePopup();
  }

  createPopup(options: TCreatePopupOptions) {
    this.state = {
      isOpen: true,
      title: options.title,
      children: options.children,
      onHandlerApply: options.onHandlerApply || null,
      onHandlerReject: options.onHandlerReject || null,
    };
  }
}

export const PROMPT_POPUP_STORE_ID = 'promptPopupStore';
