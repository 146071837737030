import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import type { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';

interface Profile extends KeycloakProfile {
  attributes: {
    participantId: string[];
    accountName: string[];
    role?: string[];
    lang?: string[];
  };
}

export function useAuth() {
  const { keycloak } = useKeycloak();

  return useMemo(
    () => ({
      get isAuth() {
        return keycloak.authenticated;
      },

      async login() {
        await keycloak.login();
      },

      async updatePassword() {
        await keycloak.login({
          action: 'UPDATE_PASSWORD',
        });
      },

      async logout() {
        await keycloak.logout();
      },

      async fetchProfile(): Promise<Profile> {
        const profile = (await keycloak.loadUserProfile()) as Profile;
        return profile;
      },

      async fetchToken(): Promise<{
        token: string | null;
        tokenParsed: KeycloakTokenParsed | null;
      } | null> {
        try {
          await keycloak.updateToken(3600);
        } catch (error) {
          return null;
        }

        return {
          token: keycloak.token || null,
          tokenParsed: keycloak.tokenParsed || null,
        };
      },
    }),
    [keycloak],
  );
}
