import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './TextInput_Exp.module.scss';
import { FormControl, TextField } from '@mui/material';

export type TTextInputProps = {
  intl: any;
  onChange: (id: string, val: string) => void;
  onBlur: (id: string, val: string) => void;
  value: any;
  id: string;
  label: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
};

const TextInputBase: React.FunctionComponent<TTextInputProps> = (props) => {
  const {
    id,
    value,
    onChange,
    onBlur,
    touched,
    showError,
    label,
    intl,
    style,
    disabled = false,
    isRequiredField,
  } = props;
  return (
    <FormControl variant="outlined" fullWidth style={style}>
      <TextField
        id={id}
        value={value}
        disabled={disabled}
        type="time"
        variant="outlined"
        onBlur={(e) => onBlur(id, e.target.value)}
        onChange={(e) => onChange(id, e.target.value)}
        label={intl.formatMessage({ id: label })}
        error={showError && !!props.errors.length}
      />
      {showError && (
        <div className={styles.error}>
          {props.errors.map((error) => (
            <span key={error}>{intl.formatMessage({ id: error })}</span>
          ))}
        </div>
      )}
    </FormControl>
  );
};

export const TimeInputExp = injectIntl(observer(TextInputBase));
