import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Tooltip } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../service/auth/useAuth';
import { useProfile } from '../../service/auth/ProfileProvider';

export const UserMenu = () => {
  const [menuData, setMenuData] = useState<{
    isMenuOpen: boolean;
    anchorEl: null | HTMLElement;
  }>({
    anchorEl: null,
    isMenuOpen: false,
  });
  const profile = useProfile();
  const auth = useAuth();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuData({
      anchorEl: event.currentTarget,
      isMenuOpen: event.currentTarget !== null,
    });
  };
  const onMenuClose = () => {
    setMenuData({
      anchorEl: null,
      isMenuOpen: false,
    });
  };
  const onProfileClicked = (history) => {
    onMenuClose();
  };

  const onLogout = async () => {
    await auth.logout();
    onMenuClose();
  };
  const onUpdatePassword = async () => {
    await auth.updatePassword();
    onMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  return (
    <div className="api-menu">
      <Tooltip title="Account">
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuData.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={menuData.isMenuOpen}
        onClose={onMenuClose}
      >
        <MenuItem disabled={true}>Email: {profile.email}</MenuItem>
        {/*<Route
          render={({history}) => {
            return (
              <MenuItem onClick={() => onProfileClicked(history)}>
                <FormattedMessage id={'user.menu.user-profile'}></FormattedMessage>
              </MenuItem>
            );
          }}
        ></Route>*/}
        <MenuItem onClick={onUpdatePassword}>
          <FormattedMessage id={'user.menu.changePassword'} />
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <FormattedMessage id={'user.menu.logout'} />
        </MenuItem>
      </Menu>
    </div>
  );
};
