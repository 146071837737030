/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaListTasksFilter
 */
export interface KvintaListTasksFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaListTasksFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaListTasksFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaListTasksFilter
     */
    nameLike?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaListTasksFilter
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaListTasksFilter
     */
    offset?: number | null;
}

export function KvintaListTasksFilterFromJSON(json: any): KvintaListTasksFilter {
    return KvintaListTasksFilterFromJSONTyped(json, false);
}

export function KvintaListTasksFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListTasksFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameLike': !exists(json, 'nameLike') ? undefined : json['nameLike'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function KvintaListTasksFilterToJSON(value?: KvintaListTasksFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nameLike': value.nameLike,
        'limit': value.limit,
        'offset': value.offset,
    };
}


