import React from 'react';
import { ReactNode } from 'react';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Loading } from '../../components/Loading';
import { KEYCLOAK_INIT_CONFIG, keycloakConfig } from '../../../config';

//console.log('keycloakConfig', keycloakConfig)
const keycloak = new Keycloak(keycloakConfig);
const KeycloakProviderOptions = KEYCLOAK_INIT_CONFIG;

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={KeycloakProviderOptions} LoadingComponent={<Loading />}>
      {children}
    </ReactKeycloakProvider>
  );
}
