import { Button, Checkbox, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component, FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ITableActionsState } from 'kvinta/common';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MaterialTable from 'material-table';
import { ILocation, LocationStore, STORE_ID } from './LocationStore';
import { LoadingBackground } from '../../../components/LoadingBackground';
import styles from '../../../../assets/css/partials/modules/Locations.module.scss';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { PROMPT_POPUP_STORE_ID, PromptPopupStore } from '../../../components/PromptPopup/PromptPopupStore';
import { NameListPopupBody } from '../../../components/PromptPopup/NameListPopupBody';
import { KvintaLocationCodeSpecifics } from '../../../apis/kvinta-load-test-toolkit';

interface LocationListProps {
  historyStore?: HistoryStore;
  locationsStore?: LocationStore;
  sidebarStore?: KSidebarStore;
  promptPopupStore?: PromptPopupStore;
  intl: IntlShape;
  pageContentStore?: PageContentStore;
}

class LocationListImpl extends Component<LocationListProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: LocationListProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.locationsStore.fetchLocationList();
    this.props.sidebarStore.clearMainTitle();
  }

  render() {
    const intl = this.props.intl;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    const { listData, isLoading, checked } = this.props.locationsStore;

    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    return (
      <>
        <Paper square>
          {isLoading && <LoadingBackground />}
          <div className={styles.headerButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(_e) => {
                this.props.historyStore.navigateNewLocationPath();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: (
                  <Checkbox
                    id={'check-all'}
                    checked={checked > 0}
                    onChange={() => {
                      this.props.locationsStore.toggleAll();
                    }}
                    checkedIcon={checked == 2 ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                  />
                ),
                field: 'isSelected',
                sorting: false,
                width: null,
                cellStyle: { width: 60, fontSize: null },
                render: (rowData) => (
                  <Checkbox
                    id={rowData.id}
                    checked={selected[rowData.id]}
                    onChange={() => {
                      this.props.locationsStore.toggleRow(rowData.id);
                    }}
                  />
                ),
              },
              {
                title: intl.formatMessage({ id: 'location.list.name' }),
                field: 'name',
                defaultSort: 'desc',
                width: null,
                cellStyle: { minWidth: 200, fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'location.list.locationId' }),
                field: 'locationId',
                defaultSort: 'desc',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'location.list.facilityId' }),
                field: 'facilityId',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'location.list.eoId' }),
                field: 'eoId',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'location.list.codeSpecifics' }),
                field: 'codeSpecifics',
                cellStyle: { fontSize: null },
                render: (rowData) =>
                  rowData.codeSpecifics === KvintaLocationCodeSpecifics.None
                    ? ''
                    : intl.formatMessage({
                        id: `location-enum.codeSpecifics.${rowData.codeSpecifics}`,
                        defaultMessage: rowData.codeSpecifics,
                      }),
              },
            ]}
            data={listData}
            options={{
              rowStyle: { wordBreak: 'break-word' },
              actionsColumnIndex: 0,
              paging: false,
              sorting: true,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateLocationSummaryPath(rowData.id);
              }
            }}
            actions={[
              {
                icon: () => <MoreVertIcon />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => <></>,
            }}
          />
        </Paper>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              this.props.promptPopupStore.createPopup({
                title: intl.formatMessage({ id: 'popup.confirm.delete.title' }),
                children: <NameListPopupBody listNames={[this.state.rowData]} />,
                onHandlerApply: () => {
                  this.props.locationsStore.submitDeleteLocationById((this.state.rowData as ILocation).id);
                  this.onMenuClose();
                },
                onHandlerReject: () => {
                  this.onMenuClose();
                },
              });
            }}
          >
            <DeleteOutline color="primary" />
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export const LocationList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    STORE_ID,
    SIDEBAR_STORE_ID,
    PROMPT_POPUP_STORE_ID,
  )(observer(LocationListImpl)),
);
