/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaEpcCode,
    KvintaEpcCodeFromJSON,
    KvintaEpcCodeToJSON,
    KvintaEpcCodeActionRequest,
    KvintaEpcCodeActionRequestFromJSON,
    KvintaEpcCodeActionRequestToJSON,
    KvintaEpcCodeCreateRequest,
    KvintaEpcCodeCreateRequestFromJSON,
    KvintaEpcCodeCreateRequestToJSON,
    KvintaListTasksRequest,
    KvintaListTasksRequestFromJSON,
    KvintaListTasksRequestToJSON,
    KvintaLocationActionRequest,
    KvintaLocationActionRequestFromJSON,
    KvintaLocationActionRequestToJSON,
    KvintaLocationCreateRequest,
    KvintaLocationCreateRequestFromJSON,
    KvintaLocationCreateRequestToJSON,
    KvintaLocationUpdateRequest,
    KvintaLocationUpdateRequestFromJSON,
    KvintaLocationUpdateRequestToJSON,
    KvintaOperationResponseEpcCode,
    KvintaOperationResponseEpcCodeFromJSON,
    KvintaOperationResponseEpcCodeToJSON,
    KvintaOperationResponseGeneratedEpcCode,
    KvintaOperationResponseGeneratedEpcCodeFromJSON,
    KvintaOperationResponseGeneratedEpcCodeToJSON,
    KvintaOperationResponseListEpcCode,
    KvintaOperationResponseListEpcCodeFromJSON,
    KvintaOperationResponseListEpcCodeToJSON,
    KvintaOperationResponseListLocation,
    KvintaOperationResponseListLocationFromJSON,
    KvintaOperationResponseListLocationToJSON,
    KvintaOperationResponseListTaskDefinitionResponse,
    KvintaOperationResponseListTaskDefinitionResponseFromJSON,
    KvintaOperationResponseListTaskDefinitionResponseToJSON,
    KvintaOperationResponseListTaskExecutionLogResponse,
    KvintaOperationResponseListTaskExecutionLogResponseFromJSON,
    KvintaOperationResponseListTaskExecutionLogResponseToJSON,
    KvintaOperationResponseLocation,
    KvintaOperationResponseLocationFromJSON,
    KvintaOperationResponseLocationToJSON,
    KvintaOperationResponseTaskDefinition,
    KvintaOperationResponseTaskDefinitionFromJSON,
    KvintaOperationResponseTaskDefinitionToJSON,
    KvintaOperationResponseTaskDefinitionResponse,
    KvintaOperationResponseTaskDefinitionResponseFromJSON,
    KvintaOperationResponseTaskDefinitionResponseToJSON,
    KvintaOperationResponseUnit,
    KvintaOperationResponseUnitFromJSON,
    KvintaOperationResponseUnitToJSON,
    KvintaTaskDefinitionActionRequest,
    KvintaTaskDefinitionActionRequestFromJSON,
    KvintaTaskDefinitionActionRequestToJSON,
    KvintaTaskDefinitionCreateUpdateRequest,
    KvintaTaskDefinitionCreateUpdateRequestFromJSON,
    KvintaTaskDefinitionCreateUpdateRequestToJSON,
} from '../models';

export interface CreateEpcCodeRequest {
    kvintaEpcCodeCreateRequest: KvintaEpcCodeCreateRequest;
}

export interface CreateLocationRequest {
    kvintaLocationCreateRequest: KvintaLocationCreateRequest;
}

export interface CreateTaskDefinitionRequest {
    kvintaTaskDefinitionCreateUpdateRequest: KvintaTaskDefinitionCreateUpdateRequest;
}

export interface DeleteEpcCodeRequest {
    kvintaEpcCodeActionRequest: KvintaEpcCodeActionRequest;
}

export interface DeleteLocationRequest {
    kvintaLocationActionRequest: KvintaLocationActionRequest;
}

export interface DeleteTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface ForceRunTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface GenerateSampleEpcCodeRequest {
    kvintaEpcCodeCreateRequest: KvintaEpcCodeCreateRequest;
}

export interface GetEpcCodeRequest {
    kvintaEpcCodeActionRequest: KvintaEpcCodeActionRequest;
}

export interface GetExecutionLogRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface GetExecutionStatusRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface GetLocationRequest {
    kvintaLocationActionRequest: KvintaLocationActionRequest;
}

export interface GetTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface ListTaskDefinitionsRequest {
    kvintaListTasksRequest: KvintaListTasksRequest;
}

export interface PauseTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface ResumeTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface RunTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface StopTaskDefinitionRequest {
    kvintaTaskDefinitionActionRequest: KvintaTaskDefinitionActionRequest;
}

export interface UpdateEpcCodeRequest {
    kvintaEpcCode: KvintaEpcCode;
}

export interface UpdateLocationRequest {
    kvintaLocationUpdateRequest: KvintaLocationUpdateRequest;
}

export interface UpdateTaskDefinitionRequest {
    kvintaTaskDefinitionCreateUpdateRequest: KvintaTaskDefinitionCreateUpdateRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create EPC code in user configuration
     * Create EPC code
     */
    async createEpcCodeRaw(requestParameters: CreateEpcCodeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcCode>> {
        if (requestParameters.kvintaEpcCodeCreateRequest === null || requestParameters.kvintaEpcCodeCreateRequest === undefined) {
            throw new runtime.RequiredError('kvintaEpcCodeCreateRequest','Required parameter requestParameters.kvintaEpcCodeCreateRequest was null or undefined when calling createEpcCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/createEpcCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcCodeCreateRequestToJSON(requestParameters.kvintaEpcCodeCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcCodeFromJSON(jsonValue));
    }

    /**
     * Create EPC code in user configuration
     * Create EPC code
     */
    async createEpcCode(requestParameters: CreateEpcCodeRequest): Promise<KvintaOperationResponseEpcCode> {
        const response = await this.createEpcCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create location in user configuration
     * Create location
     */
    async createLocationRaw(requestParameters: CreateLocationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLocation>> {
        if (requestParameters.kvintaLocationCreateRequest === null || requestParameters.kvintaLocationCreateRequest === undefined) {
            throw new runtime.RequiredError('kvintaLocationCreateRequest','Required parameter requestParameters.kvintaLocationCreateRequest was null or undefined when calling createLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/createLocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaLocationCreateRequestToJSON(requestParameters.kvintaLocationCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLocationFromJSON(jsonValue));
    }

    /**
     * Create location in user configuration
     * Create location
     */
    async createLocation(requestParameters: CreateLocationRequest): Promise<KvintaOperationResponseLocation> {
        const response = await this.createLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create task definition
     * Create task definition
     */
    async createTaskDefinitionRaw(requestParameters: CreateTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinition>> {
        if (requestParameters.kvintaTaskDefinitionCreateUpdateRequest === null || requestParameters.kvintaTaskDefinitionCreateUpdateRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionCreateUpdateRequest','Required parameter requestParameters.kvintaTaskDefinitionCreateUpdateRequest was null or undefined when calling createTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/createTaskDefinition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionCreateUpdateRequestToJSON(requestParameters.kvintaTaskDefinitionCreateUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionFromJSON(jsonValue));
    }

    /**
     * Create task definition
     * Create task definition
     */
    async createTaskDefinition(requestParameters: CreateTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinition> {
        const response = await this.createTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete EPC code in user configuration
     * Delete EPC code
     */
    async deleteEpcCodeRaw(requestParameters: DeleteEpcCodeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaEpcCodeActionRequest === null || requestParameters.kvintaEpcCodeActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaEpcCodeActionRequest','Required parameter requestParameters.kvintaEpcCodeActionRequest was null or undefined when calling deleteEpcCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/deleteEpcCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcCodeActionRequestToJSON(requestParameters.kvintaEpcCodeActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Delete EPC code in user configuration
     * Delete EPC code
     */
    async deleteEpcCode(requestParameters: DeleteEpcCodeRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.deleteEpcCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete location in user configuration
     * Delete location
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaLocationActionRequest === null || requestParameters.kvintaLocationActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaLocationActionRequest','Required parameter requestParameters.kvintaLocationActionRequest was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/deleteLocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaLocationActionRequestToJSON(requestParameters.kvintaLocationActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Delete location in user configuration
     * Delete location
     */
    async deleteLocation(requestParameters: DeleteLocationRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.deleteLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete task definition
     * Delete task definition
     */
    async deleteTaskDefinitionRaw(requestParameters: DeleteTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling deleteTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/deleteTaskDefinition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Delete task definition
     * Delete task definition
     */
    async deleteTaskDefinition(requestParameters: DeleteTaskDefinitionRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.deleteTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Force run scheduled task definition
     * Force run scheduled task definition
     */
    async forceRunTaskDefinitionRaw(requestParameters: ForceRunTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling forceRunTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/force-run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Force run scheduled task definition
     * Force run scheduled task definition
     */
    async forceRunTaskDefinition(requestParameters: ForceRunTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.forceRunTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Generate sample EPC code by user configuration
     * Generate sample EPC code
     */
    async generateSampleEpcCodeRaw(requestParameters: GenerateSampleEpcCodeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGeneratedEpcCode>> {
        if (requestParameters.kvintaEpcCodeCreateRequest === null || requestParameters.kvintaEpcCodeCreateRequest === undefined) {
            throw new runtime.RequiredError('kvintaEpcCodeCreateRequest','Required parameter requestParameters.kvintaEpcCodeCreateRequest was null or undefined when calling generateSampleEpcCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/generateSampleEpcCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcCodeCreateRequestToJSON(requestParameters.kvintaEpcCodeCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGeneratedEpcCodeFromJSON(jsonValue));
    }

    /**
     * Generate sample EPC code by user configuration
     * Generate sample EPC code
     */
    async generateSampleEpcCode(requestParameters: GenerateSampleEpcCodeRequest): Promise<KvintaOperationResponseGeneratedEpcCode> {
        const response = await this.generateSampleEpcCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get EPC code from user configuration
     * Get EPC code
     */
    async getEpcCodeRaw(requestParameters: GetEpcCodeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcCode>> {
        if (requestParameters.kvintaEpcCodeActionRequest === null || requestParameters.kvintaEpcCodeActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaEpcCodeActionRequest','Required parameter requestParameters.kvintaEpcCodeActionRequest was null or undefined when calling getEpcCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/getEpcCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcCodeActionRequestToJSON(requestParameters.kvintaEpcCodeActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcCodeFromJSON(jsonValue));
    }

    /**
     * Get EPC code from user configuration
     * Get EPC code
     */
    async getEpcCode(requestParameters: GetEpcCodeRequest): Promise<KvintaOperationResponseEpcCode> {
        const response = await this.getEpcCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get task execution log
     * Get task execution log
     */
    async getExecutionLogRaw(requestParameters: GetExecutionLogRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListTaskExecutionLogResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling getExecutionLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/executionLog`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListTaskExecutionLogResponseFromJSON(jsonValue));
    }

    /**
     * Get task execution log
     * Get task execution log
     */
    async getExecutionLog(requestParameters: GetExecutionLogRequest): Promise<KvintaOperationResponseListTaskExecutionLogResponse> {
        const response = await this.getExecutionLogRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get task definition execution status
     * Get task definition execution status
     */
    async getExecutionStatusRaw(requestParameters: GetExecutionStatusRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling getExecutionStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/getExecutionStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get task definition execution status
     * Get task definition execution status
     */
    async getExecutionStatus(requestParameters: GetExecutionStatusRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.getExecutionStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get location from user configuration
     * Get location
     */
    async getLocationRaw(requestParameters: GetLocationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLocation>> {
        if (requestParameters.kvintaLocationActionRequest === null || requestParameters.kvintaLocationActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaLocationActionRequest','Required parameter requestParameters.kvintaLocationActionRequest was null or undefined when calling getLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/getLocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaLocationActionRequestToJSON(requestParameters.kvintaLocationActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLocationFromJSON(jsonValue));
    }

    /**
     * Get location from user configuration
     * Get location
     */
    async getLocation(requestParameters: GetLocationRequest): Promise<KvintaOperationResponseLocation> {
        const response = await this.getLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get task definition
     * Get task definition
     */
    async getTaskDefinitionRaw(requestParameters: GetTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinition>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling getTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/getTaskDefinition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionFromJSON(jsonValue));
    }

    /**
     * Get task definition
     * Get task definition
     */
    async getTaskDefinition(requestParameters: GetTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinition> {
        const response = await this.getTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all EPC codes from user configuration
     * Get all EPC codes
     */
    async listEpcCodesRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcCode>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/userSettings/listEpcCodes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcCodeFromJSON(jsonValue));
    }

    /**
     * Get all EPC codes from user configuration
     * Get all EPC codes
     */
    async listEpcCodes(): Promise<KvintaOperationResponseListEpcCode> {
        const response = await this.listEpcCodesRaw();
        return await response.value();
    }

    /**
     * Get all locations from user configuration
     * Get all locations
     */
    async listLocationsRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseListLocation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/userSettings/listLocations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListLocationFromJSON(jsonValue));
    }

    /**
     * Get all locations from user configuration
     * Get all locations
     */
    async listLocations(): Promise<KvintaOperationResponseListLocation> {
        const response = await this.listLocationsRaw();
        return await response.value();
    }

    /**
     * List task definitions
     * List task definitions
     */
    async listTaskDefinitionsRaw(requestParameters: ListTaskDefinitionsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListTaskDefinitionResponse>> {
        if (requestParameters.kvintaListTasksRequest === null || requestParameters.kvintaListTasksRequest === undefined) {
            throw new runtime.RequiredError('kvintaListTasksRequest','Required parameter requestParameters.kvintaListTasksRequest was null or undefined when calling listTaskDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/listTaskDefinitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaListTasksRequestToJSON(requestParameters.kvintaListTasksRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * List task definitions
     * List task definitions
     */
    async listTaskDefinitions(requestParameters: ListTaskDefinitionsRequest): Promise<KvintaOperationResponseListTaskDefinitionResponse> {
        const response = await this.listTaskDefinitionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pause task definition
     * Pause task definition
     */
    async pauseTaskDefinitionRaw(requestParameters: PauseTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling pauseTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/pause`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Pause task definition
     * Pause task definition
     */
    async pauseTaskDefinition(requestParameters: PauseTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.pauseTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Resume task definition
     * Resume task definition
     */
    async resumeTaskDefinitionRaw(requestParameters: ResumeTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling resumeTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/resume`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Resume task definition
     * Resume task definition
     */
    async resumeTaskDefinition(requestParameters: ResumeTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.resumeTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Run task definition
     * Run task definition
     */
    async runTaskDefinitionRaw(requestParameters: RunTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling runTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Run task definition
     * Run task definition
     */
    async runTaskDefinition(requestParameters: RunTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.runTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Stop task definition current execution
     * Stop task definition
     */
    async stopTaskDefinitionRaw(requestParameters: StopTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinitionResponse>> {
        if (requestParameters.kvintaTaskDefinitionActionRequest === null || requestParameters.kvintaTaskDefinitionActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionActionRequest','Required parameter requestParameters.kvintaTaskDefinitionActionRequest was null or undefined when calling stopTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/stop`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionActionRequestToJSON(requestParameters.kvintaTaskDefinitionActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Stop task definition current execution
     * Stop task definition
     */
    async stopTaskDefinition(requestParameters: StopTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinitionResponse> {
        const response = await this.stopTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update EPC code in user configuration
     * Update EPC code
     */
    async updateEpcCodeRaw(requestParameters: UpdateEpcCodeRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcCode>> {
        if (requestParameters.kvintaEpcCode === null || requestParameters.kvintaEpcCode === undefined) {
            throw new runtime.RequiredError('kvintaEpcCode','Required parameter requestParameters.kvintaEpcCode was null or undefined when calling updateEpcCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/updateEpcCode`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcCodeToJSON(requestParameters.kvintaEpcCode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcCodeFromJSON(jsonValue));
    }

    /**
     * Update EPC code in user configuration
     * Update EPC code
     */
    async updateEpcCode(requestParameters: UpdateEpcCodeRequest): Promise<KvintaOperationResponseEpcCode> {
        const response = await this.updateEpcCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update location in user configuration
     * Update location
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLocation>> {
        if (requestParameters.kvintaLocationUpdateRequest === null || requestParameters.kvintaLocationUpdateRequest === undefined) {
            throw new runtime.RequiredError('kvintaLocationUpdateRequest','Required parameter requestParameters.kvintaLocationUpdateRequest was null or undefined when calling updateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/updateLocation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaLocationUpdateRequestToJSON(requestParameters.kvintaLocationUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLocationFromJSON(jsonValue));
    }

    /**
     * Update location in user configuration
     * Update location
     */
    async updateLocation(requestParameters: UpdateLocationRequest): Promise<KvintaOperationResponseLocation> {
        const response = await this.updateLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update task definition
     * Update task definition
     */
    async updateTaskDefinitionRaw(requestParameters: UpdateTaskDefinitionRequest): Promise<runtime.ApiResponse<KvintaOperationResponseTaskDefinition>> {
        if (requestParameters.kvintaTaskDefinitionCreateUpdateRequest === null || requestParameters.kvintaTaskDefinitionCreateUpdateRequest === undefined) {
            throw new runtime.RequiredError('kvintaTaskDefinitionCreateUpdateRequest','Required parameter requestParameters.kvintaTaskDefinitionCreateUpdateRequest was null or undefined when calling updateTaskDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/definitions/updateTaskDefinition`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTaskDefinitionCreateUpdateRequestToJSON(requestParameters.kvintaTaskDefinitionCreateUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseTaskDefinitionFromJSON(jsonValue));
    }

    /**
     * Update task definition
     * Update task definition
     */
    async updateTaskDefinition(requestParameters: UpdateTaskDefinitionRequest): Promise<KvintaOperationResponseTaskDefinition> {
        const response = await this.updateTaskDefinitionRaw(requestParameters);
        return await response.value();
    }

}
