import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { TCodeFormat, TCodeFormatData, TCodeFormatVariable, TSampleCodes } from './CodeFormatsStore';
import { values } from 'mobx';
import { KvintaEpcCodeType, KvintaEpcCodeVariableType } from '../../../apis/kvinta-load-test-toolkit';
import styles from '../../../../assets/css/partials/modules/CodeFormats.module.scss';
import { DeleteButton } from '../../../components/IconButtons';
import { TextField } from '../../../components/FormFields/TextField';

interface ICode {
  code: string;
  sampleCode: string;
  label: string;
  intl: IntlShape;
}

const Code: FC<ICode> = ({ code, sampleCode, intl, label }) => {
  return (
    <div>
      <TextField value={code} label={label} />
      {sampleCode && (
        <div className={styles.codeNodeHelpText}>
          {intl.formatMessage({ id: label })}: {sampleCode}
        </div>
      )}
    </div>
  );
};

interface ISampleCode {
  formData: TCodeFormat;
  sampleCodes: TSampleCodes;
  intl: IntlShape;
}

const SampleCode: FC<ISampleCode> = observer(({ formData, sampleCodes, intl }) => {
  switch (formData.codeFormatType) {
    case KvintaEpcCodeType.Pk: {
      const sampleShortCode = sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.sampleShortCode : '';
      const samplePrimaryCode =
        sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.samplePrimaryCode : '';
      const sampleShortPrimaryCode =
        sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.sampleShortPrimaryCode : '';
      return (
        <div className={styles.formContainerSection}>
          <Code
            code={formData.longCode}
            sampleCode={sampleCodes.sampleLongCode}
            intl={intl}
            label="code-format.create-form.field.sample-code-format-long"
          />
          <Code
            code={formData.shortCode}
            sampleCode={sampleShortCode}
            intl={intl}
            label="code-format.create-form.field.sample-code-format-short"
          />
          <Code
            code={formData.primaryCode}
            sampleCode={samplePrimaryCode}
            intl={intl}
            label="code-format.create-form.field.sample-code-format-primary"
          />
          <Code
            code={formData.shortPrimaryCode}
            sampleCode={sampleShortPrimaryCode}
            intl={intl}
            label="code-format.create-form.field.sample-code-format-shortPrimary"
          />
        </div>
      );
    }
    case KvintaEpcCodeType.Sgtin:
    case KvintaEpcCodeType.Sscc:
      return (
        <div className={styles.formContainerSection}>
          <Code
            code={formData.longCode}
            sampleCode={sampleCodes.sampleLongCode}
            intl={intl}
            label="code-format.create-form.field.sample-code-format"
          />
        </div>
      );
  }
});

type TVariableData = {
  variableData: TCodeFormatVariable;
};
const VariableData: FC<TVariableData> = ({ variableData }) => {
  return (
    <>
      {variableData.variableType === KvintaEpcCodeVariableType.Constant && (
        <TextField value={variableData.value} label="code-formats.create-form.field.value" />
      )}
      {variableData.variableType === KvintaEpcCodeVariableType.UniqueSequence && (
        <>
          <TextField
            value={variableData.uniqueSequenceType}
            label="code-formats.create-form.field.unique-sequence-type"
          />
          <TextField value={String(variableData.length)} label="code-formats.create-form.field.length" />
        </>
      )}
    </>
  );
};

type TVariable = {
  variableData: TCodeFormatVariable;
};
const Variable: FC<TVariable> = ({ variableData }) => {
  return (
    <div className={styles.rowContainerSection}>
      <DeleteButton
        disabled
        onClick={() => {
          return;
        }}
      />
      <TextField value={variableData.name} label="code-format.create-form.field.name" />
      <TextField value={variableData.variableType} label="code-formats.create-form.field.variable-type" />
      <VariableData variableData={variableData} />
    </div>
  );
};

interface ICodeFormatSummaryView {
  formData: TCodeFormat;
  sampleCodes: TSampleCodes;
  generateSampleCodes: (codeFormatData: TCodeFormatData) => void;
  intl: IntlShape;
}

const CodeFormatSummaryViewBase: FC<ICodeFormatSummaryView> = ({
  formData,
  sampleCodes,
  intl,
  generateSampleCodes,
}) => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <TextField value={formData.name} label="code-format.create-form.field.name" />
        <TextField value={formData.codeFormatType} label="code-format.create-form.field.type" />
      </div>
      <SampleCode intl={intl} formData={formData} sampleCodes={sampleCodes} />
      <div>
        <Button
          disabled
          onClick={() => {
            return;
          }}
          color="primary"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'button.add-variable' })}
        </Button>
      </div>
      <div className={styles.formContainerSection}>
        {formData.variables.map((variable, index) => {
          return <Variable key={index} variableData={variable} />;
        })}
      </div>
      <div>
        <Button onClick={() => generateSampleCodes(formData)} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'button.generate-sample-code' })}
        </Button>
      </div>
    </div>
  );
};

export const CodeFormatSummaryView = CodeFormatSummaryViewBase;
