import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component, FC } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ITableActionsState } from 'kvinta/common';
import { STORE_ID, TaskProductStore } from './TaskProductStore';
import { reaction } from 'mobx';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { EHistoryFunctionName, TaskHeaderTabs } from './TaskHeaderTabs';
import { Button, Checkbox, Menu, MenuItem, Paper } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { PROMPT_POPUP_STORE_ID, PromptPopupStore } from '../../components/PromptPopup/PromptPopupStore';
import { RskuListPopupBody } from '../../components/PromptPopup/RskuListPopupBody';

interface TaskListProps {
  historyStore?: HistoryStore;
  taskProductStore?: TaskProductStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  promptPopupStore?: PromptPopupStore;
  intl: any;
  taskId: string;
}

class ProductListImpl extends Component<TaskListProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;
  disposer: any;

  constructor(props: TaskListProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.taskProductStore.fetchProductListOfTask(this.props.taskId);

    this.disposer = reaction(
      () => this.props.taskProductStore.currentTask,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateTaskListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, checked, currentTask } = this.props.taskProductStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightWithTab;

    const isChecked = (listData && listData.filter((value) => value.isSelected)) || [];
    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    return (
      <>
        <Paper square>
          {isLoading && <LoadingBackground />}
          <Paper square className={styles.summaryTabMenuHeader}>
            <TaskHeaderTabs
              currentTask={currentTask}
              taskId={this.props.taskId}
              initialValue={EHistoryFunctionName.ProductListPath}
            />
          </Paper>
          <div className={styles.headerButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={() => {
                this.props.historyStore.navigateNewTaskProductPath(this.props.taskId);
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>

          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: (
                  <Checkbox
                    id={'check-all'}
                    checked={checked > 0}
                    onChange={() => {
                      this.props.taskProductStore.toggleAll();
                    }}
                    checkedIcon={checked == 2 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  />
                ),
                field: 'isSelected',
                sorting: false,
                width: null,
                cellStyle: { width: 60, fontSize: null },
                render: (rowData: any) => (
                  <Checkbox
                    id={rowData.id}
                    checked={selected[rowData.id]}
                    onChange={() => {
                      this.props.taskProductStore.toggleRow(rowData.id);
                    }}
                  />
                ),
              },
              {
                title: intl.formatMessage({ id: 'product.create-form.field.rsku' }),
                field: 'rsku',
                defaultSort: 'desc',
                width: null,
                cellStyle: { minWidth: 110, fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'product.create-form.field.quantity' }),
                field: 'quantity',
                defaultSort: 'desc',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'product.create-form.field.topHierarchyLevel' }),
                field: 'topHierarchyLevel',
                defaultSort: 'desc',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'product.list.field.location' }),
                field: 'location',
                defaultSort: 'desc',
                cellStyle: { fontSize: null },
              },
            ]}
            data={listData}
            options={{
              rowStyle: { wordBreak: 'break-word' },
              toolbarButtonAlignment: 'left',
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateTaskProductPath(this.props.taskProductStore.currentTask.id, rowData.id);
              }
            }}
            actions={[
              {
                icon: () => <MoreVertIcon />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: (props) => <></>,
            }}
          />
        </Paper>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              this.props.promptPopupStore.createPopup({
                title: intl.formatMessage({ id: 'popup.confirm.delete.title' }),
                children: <RskuListPopupBody listNames={[this.state.rowData]} />,
                onHandlerApply: () => {
                  this.props.taskProductStore.deleteProduct(currentTask.id, this.state.rowData.id);
                  this.onMenuClose();
                },
                onHandlerReject: () => {
                  this.onMenuClose();
                },
              });
            }}
          >
            <DeleteOutlineIcon color="primary" />
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export const TaskProductList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    STORE_ID,
    SIDEBAR_STORE_ID,
    PROMPT_POPUP_STORE_ID,
  )(observer(ProductListImpl)),
);
