import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ITableActionsState } from 'kvinta/common';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { EHistoryFunctionName, TaskHeaderTabs } from './TaskHeaderTabs';
import { STORE_ID, TaskLogsStore, exportLogs, ITaskLogsRow } from './TaskLogsStore';
import { DurationsTimeField } from '../../components/FormFields/DurationsTimeField';
import { DateFormatField } from '../../components/FormFields/DateFormatField';
import { KvintaSsccExecutionLogStatusResponse } from '../../apis/kvinta-load-test-toolkit';
import { toJS } from 'mobx';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CopyStringField } from '../../components/FormFields/CopyStringField';

interface TaskLogsProps {
  historyStore?: HistoryStore;
  taskLogsStore?: TaskLogsStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  taskId: string;
}

class TaskLogsListImpl extends Component<TaskLogsProps, ITableActionsState> {
  tableRef: React.RefObject<any> = null;

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.taskLogsStore.fetchLogsListOfTask(this.props.taskId);
  }

  render() {
    const intl = this.props.intl;
    const { isLoading, listData } = this.props.taskLogsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightWithTab;
    return (
      <div>
        {isLoading && <LoadingBackground />}
        <div className={styles.summaryTabMenuHeader}>
          <TaskHeaderTabs
            currentTask={null}
            taskId={this.props.taskId}
            initialValue={EHistoryFunctionName.LogsListPath}
          />
        </div>
        <div className={styles.headerResultList}>
          <div></div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon color="secondary" />}
            onClick={() => {
              exportLogs(this.props.taskLogsStore.listData);
            }}
          >
            {intl.formatMessage({ id: 'button.export' })}
          </Button>
        </div>

        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          columns={[
            {
              title: intl.formatMessage({ id: 'task.logs-list.rsku' }),
              field: 'rsku',
              defaultSort: 'desc',
              render: (rawData) => <CopyStringField stringToCopy={rawData.rsku}>{rawData.rsku}</CopyStringField>,
            },
            {
              title: intl.formatMessage({ id: 'task.logs-list.levelCode' }),
              field: 'additionalProp',
              defaultSort: 'desc',
              render: (rawData) => (
                <CopyStringField stringToCopy={rawData.additionalProp}>{rawData.additionalProp}</CopyStringField>
              ),
            },
            {
              title: intl.formatMessage({ id: 'task.logs-list.action' }),
              field: 'step',
              defaultSort: 'desc',
            },
            {
              title: intl.formatMessage({ id: 'task.logs-list.status' }),
              field: 'status',
              defaultSort: 'desc',
              render: (rawData) => {
                const isError = rawData.status === KvintaSsccExecutionLogStatusResponse.Error;
                const isDetailPanel =
                  !!this.tableRef.current?.dataManager?.lastDetailPanelRow?.tableData?.showDetailPanel;
                const errors = rawData.error.split(',').map((error, index) => <div key={index}>{error}</div>);
                return (
                  <>
                    {isError ? (
                      <div
                        className={styles.logsStatusContainer}
                        onClick={() => {
                          this.tableRef.current.onToggleDetailPanel(
                            [listData.findIndex((row) => row.id === rawData.id)],
                            () => <div className={styles.errorLogsContainer}>{errors}</div>,
                          );
                        }}
                      >
                        <div>{rawData.status}</div>
                        <IconButton>
                          {isDetailPanel ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </div>
                    ) : (
                      rawData.status
                    )}
                  </>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'task.logs-list.timeStamp' }),
              field: 'startTimeMillis',
              defaultSort: 'desc',
              render: (rawData) => <DateFormatField time={rawData.startTimeMillis} />,
            },
            {
              title: intl.formatMessage({ id: 'task.logs-list.duration' }),
              field: 'endTimeMillis',
              defaultSort: 'desc',
              render: (rawData) => (
                <DurationsTimeField intl={intl} start={rawData.startTimeMillis} end={rawData.endTimeMillis} />
              ),
            },
          ]}
          data={toJS(listData)}
          options={{
            rowStyle: { wordBreak: 'break-word' },
            detailPanelColumnStyle: { backgroundColor: 'pink' },
            paging: false,
            sorting: false,
            draggable: false,
            search: false,
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            //detailPanelType: "single",
            showTitle: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            tableLayout: 'fixed',
            exportButton: true,
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            Toolbar: (props) => <></>,
          }}
        />
      </div>
    );
  }
}

export const TaskLogsList = injectIntl(
  inject(HISTORY_STORE_ID, PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(TaskLogsListImpl)),
);
