import { Button, Paper, Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { IEditState } from 'kvinta/common';
import { STORE_ID, TaskProductStore } from './TaskProductStore';
import { TaskSummaryForm } from './TaskSummaryForm';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { LoadingBackground } from '../../components/LoadingBackground';
import { FormControlButtons } from '../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../common/formUtils/utils';
import { TaskSummaryView } from './TaskSummaryView';

interface ViewTaskSummaryProps {
  historyStore?: HistoryStore;
  intl: any;
  taskProductStore?: TaskProductStore;
  sidebarStore?: KSidebarStore;
  id: string;
}

class NewTaskImpl extends Component<ViewTaskSummaryProps, IEditState> {
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.taskProductStore.openCreateTaskForm();
  }
  componentWillUnmount(): void {
    this.props.taskProductStore.closeTaskFormData();
  }

  render() {
    const intl = this.props.intl;
    const { taskFormData, isLoading, handleTaskFormBlur, handleTaskFormChange } = this.props.taskProductStore;

    return (
      <div className={styles.summaryContainer}>
        <Paper square className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateTaskListPath();
              }
            }}
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </Paper>
        <Paper square className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(taskFormData)}
            formIsOpen={true}
            onCancel={this.props.taskProductStore.cancelCreateTask}
            onConfirm={this.props.taskProductStore.submitCreateTask}
            onOpen={() => {
              return null;
            }}
          />
          {taskFormData && (
            <TaskSummaryForm onBlur={handleTaskFormBlur} onChange={handleTaskFormChange} formData={taskFormData} />
          )}
        </Paper>
      </div>
    );
  }
}

export const NewTask = injectIntl(inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(NewTaskImpl)));
