/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaProductPackLevel
 */
export interface KvintaProductPackLevel {
    /**
     * type of the code to be generated for the current hierarchy level
     * @type {any}
     * @memberof KvintaProductPackLevel
     */
    hierarchyLevelType: any | null;
    /**
     * packaging level type, e.g. 10, 20
     * @type {number}
     * @memberof KvintaProductPackLevel
     */
    packagingLevelType: number;
    /**
     * the name of the level
     * @type {string}
     * @memberof KvintaProductPackLevel
     */
    name: string;
    /**
     * Quantity вЂ“ the quantity of the current level items in the next packing level of the hierarchy
     * @type {number}
     * @memberof KvintaProductPackLevel
     */
    quantity: number;
    /**
     * format of the code to be generated
     * @type {string}
     * @memberof KvintaProductPackLevel
     */
    epcCodeId: string;
    /**
     * global trade identification number
     * @type {string}
     * @memberof KvintaProductPackLevel
     */
    gtin?: string | null;
    /**
     * global company prefix
     * @type {string}
     * @memberof KvintaProductPackLevel
     */
    gcp?: string | null;
    /**
     * should generate sscc in system, else - request it from movilizer
     * @type {boolean}
     * @memberof KvintaProductPackLevel
     */
    generateSscc: boolean;
    /**
     * All child events will be sent in one epcis document
     * @type {boolean}
     * @memberof KvintaProductPackLevel
     */
    singleEpcisDocument: boolean;
    /**
     * type of pk generation
     * @type {any}
     * @memberof KvintaProductPackLevel
     */
    codeSource: any | null;
}

export function KvintaProductPackLevelFromJSON(json: any): KvintaProductPackLevel {
    return KvintaProductPackLevelFromJSONTyped(json, false);
}

export function KvintaProductPackLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaProductPackLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchyLevelType': json['hierarchyLevelType'],
        'packagingLevelType': json['packagingLevelType'],
        'name': json['name'],
        'quantity': json['quantity'],
        'epcCodeId': json['epcCodeId'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'gcp': !exists(json, 'gcp') ? undefined : json['gcp'],
        'generateSscc': json['generateSscc'],
        'singleEpcisDocument': json['singleEpcisDocument'],
        'codeSource': json['codeSource'],
    };
}

export function KvintaProductPackLevelToJSON(value?: KvintaProductPackLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchyLevelType': value.hierarchyLevelType,
        'packagingLevelType': value.packagingLevelType,
        'name': value.name,
        'quantity': value.quantity,
        'epcCodeId': value.epcCodeId,
        'gtin': value.gtin,
        'gcp': value.gcp,
        'generateSscc': value.generateSscc,
        'singleEpcisDocument': value.singleEpcisDocument,
        'codeSource': value.codeSource,
    };
}


