import * as React from 'react';
import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { injectIntl, IntlShape } from 'react-intl';
import style from './PromptPopup.module.scss';
import { inject, observer } from 'mobx-react';
import { PROMPT_POPUP_STORE_ID, PromptPopupStore } from './PromptPopupStore';
import styled from '@mui/material/styles/styled';
import CloseIcon from '@mui/icons-material/Close';

export interface PopupDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const PopupDialogTitle = (props: PopupDialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle className={style.dialogTitleContainer} sx={{ m: 0, p: 2 }} {...other}>
      <span className={style.dialogTitle}>{children}</span>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PopupDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type TPromptPopupBase = {
  promptPopupStore?: PromptPopupStore;
  intl: IntlShape;
};

const PromptPopupBase: FC<TPromptPopupBase> = ({ promptPopupStore, intl }) => {
  if (!promptPopupStore.state || !promptPopupStore.state.isOpen) return null;
  const { isOpen, title, children } = promptPopupStore.state;
  const { onReject, onConfirm } = promptPopupStore;
  return (
    <PopupDialog aria-labelledby="popup-dialog-title" maxWidth="md" open={isOpen} onClose={onReject}>
      <PopupDialogTitle id="popup-dialog-title" onClose={onReject}>
        {title}
      </PopupDialogTitle>
      <DialogContent dividers className={style.dialogContainer}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onReject} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
          {intl.formatMessage({ id: 'button.confirm' })}
        </Button>
      </DialogActions>
    </PopupDialog>
  );
};

export const PromptPopup = injectIntl(inject(PROMPT_POPUP_STORE_ID)(observer(PromptPopupBase)));
