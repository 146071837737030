import React, { forwardRef } from 'react';
import {
  AddBox,
  ArrowUpward,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@mui/icons-material';
import { Icons } from 'material-table';

export const tableIcons: Icons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
};
tableIcons.Add.displayName = 'DisplayNameAdd';
tableIcons.Check.displayName = 'DisplayNameCheck';
tableIcons.Clear.displayName = 'DisplayNameClear';
tableIcons.Delete.displayName = 'DisplayNameDelete';
tableIcons.DetailPanel.displayName = 'DisplayNameDetailPanel';
tableIcons.Edit.displayName = 'DisplayNameEdit';
tableIcons.Export.displayName = 'DisplayNameExport';
tableIcons.Filter.displayName = 'DisplayNameFilter';
tableIcons.FirstPage.displayName = 'DisplayNameFirstPage';
tableIcons.LastPage.displayName = 'DisplayNameLastPage';
tableIcons.NextPage.displayName = 'DisplayNameNextPage';
tableIcons.PreviousPage.displayName = 'DisplayNamePreviousPage';
tableIcons.ResetSearch.displayName = 'DisplayNameResetSearch';
tableIcons.Search.displayName = 'DisplayNameSearch';
tableIcons.SortArrow.displayName = 'DisplayNameSortArrow';
tableIcons.ThirdStateCheck.displayName = 'DisplayNameThirdStateCheck';
tableIcons.ViewColumn.displayName = 'DisplayNameViewColumn';
