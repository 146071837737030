import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { ITableActionsState } from 'kvinta/common';
import { errorRequired, exportResults, STORE_ID, TaskProductStore } from './TaskProductStore';
import { reaction } from 'mobx';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import { CopyStringField } from '../../components/FormFields/CopyStringField';
import { SelectInput } from '../../components/FormFields/SelectInput';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { EHistoryFunctionName, TaskHeaderTabs } from './TaskHeaderTabs';

interface TaskListProps {
  historyStore?: HistoryStore;
  taskProductStore?: TaskProductStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  taskId: string;
}

class TaskResultListImpl extends Component<TaskListProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;
  disposer: any;

  componentDidMount() {
    this.props.taskProductStore.fetchTaskResults(this.props.taskId);
    this.disposer = reaction(
      () => this.props.taskProductStore.currentTask,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateTaskListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const { isLoading, currentTask, currentTaskResults, currentTaskResultSelector } = this.props.taskProductStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightWithTab;
    return (
      <div>
        {isLoading && <LoadingBackground />}
        <div className={styles.summaryTabMenuHeader}>
          <TaskHeaderTabs
            currentTask={currentTask}
            taskId={this.props.taskId}
            initialValue={EHistoryFunctionName.ResultListPath}
          />
        </div>
        <div className={styles.headerResultList}>
          <SelectInput
            options={currentTaskResultSelector.options || {}}
            onChange={this.props.taskProductStore.filterResultList}
            id="packagingLevelType"
            value={currentTaskResultSelector.currentOption}
            showError={false}
            isEditing={true}
            errorRequired={errorRequired}
            style={{ height: '40px' }}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon color="secondary" />}
            onClick={() => {
              exportResults(this.props.taskProductStore.currentTaskResults);
            }}
          >
            {intl.formatMessage({ id: 'button.export' })}
          </Button>
        </div>

        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          columns={[
            {
              title: intl.formatMessage({ id: 'task.results-list.rsku' }),
              field: 'rsku',
              defaultSort: 'desc',
              render: (rawData: any) => <CopyStringField stringToCopy={rawData.rsku}>{rawData.rsku}</CopyStringField>,
            },
            {
              title: intl.formatMessage({ id: 'task.results-list.code' }),
              field: 'code',
              defaultSort: 'desc',
              render: (rawData: any) => <CopyStringField stringToCopy={rawData.code}>{rawData.code}</CopyStringField>,
            },
          ]}
          data={currentTaskResults as any}
          options={{
            rowStyle: { wordBreak: 'break-word' },
            paging: false,
            sorting: false,
            draggable: false,
            search: false,
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            showTitle: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            tableLayout: 'fixed',
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            Toolbar: (props) => <></>,
          }}
        />
      </div>
    );
  }
}

export const TaskResultList = injectIntl(
  inject(HISTORY_STORE_ID, PAGE_CONTENT_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(TaskResultListImpl)),
);
