/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcCodeVariableFormat,
    KvintaEpcCodeVariableFormatFromJSON,
    KvintaEpcCodeVariableFormatFromJSONTyped,
    KvintaEpcCodeVariableFormatToJSON,
    KvintaEpcCodeVariableType,
    KvintaEpcCodeVariableTypeFromJSON,
    KvintaEpcCodeVariableTypeFromJSONTyped,
    KvintaEpcCodeVariableTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcCodeVariable
 */
export interface KvintaEpcCodeVariable {
    /**
     * Variable name. Unique for current code
     * @type {string}
     * @memberof KvintaEpcCodeVariable
     */
    name: string;
    /**
     * 
     * @type {KvintaEpcCodeVariableType}
     * @memberof KvintaEpcCodeVariable
     */
    type: KvintaEpcCodeVariableType;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeVariable
     */
    value?: string | null;
    /**
     * 
     * @type {KvintaEpcCodeVariableFormat}
     * @memberof KvintaEpcCodeVariable
     */
    format?: KvintaEpcCodeVariableFormat | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcCodeVariable
     */
    length?: number | null;
}

export function KvintaEpcCodeVariableFromJSON(json: any): KvintaEpcCodeVariable {
    return KvintaEpcCodeVariableFromJSONTyped(json, false);
}

export function KvintaEpcCodeVariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcCodeVariable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': KvintaEpcCodeVariableTypeFromJSON(json['type']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'format': !exists(json, 'format') ? undefined : KvintaEpcCodeVariableFormatFromJSON(json['format']),
        'length': !exists(json, 'length') ? undefined : json['length'],
    };
}

export function KvintaEpcCodeVariableToJSON(value?: KvintaEpcCodeVariable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': KvintaEpcCodeVariableTypeToJSON(value.type),
        'value': value.value,
        'format': KvintaEpcCodeVariableFormatToJSON(value.format),
        'length': value.length,
    };
}


