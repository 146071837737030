import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  DefaultApi,
  KvintaEpcCode,
  KvintaEpcCodeCreateRequest,
  KvintaEpcCodeType,
  KvintaEpcCodeVariable,
  KvintaEpcCodeVariableType,
  KvintaGeneratedEpcCode,
} from '../../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../../common/HistoryStore';
import { TApis } from '../../../../stores';
import { normalizeApiResponse } from '../../../apis/apis';
import { array, formRoot, oneOf, select, textInput } from '../../../common/formUtils/formDataGenerators';
import { isNotEmpty } from '../../../common/formUtils/validators';
import { validateForm } from '../../../common/formUtils/core';
import {
  addArrayMember,
  handleFormBlur,
  handleFormChange,
  removeArrayMember,
} from '../../../common/formUtils/handlers';

export enum EUniqueSequenceType {
  ALPHANUMERIC = 'ALFANUMERIC',
  ALPHANUMERIC_CAPS = 'ALPHANUMERIC_CAPS',
  NUMERIC = 'NUMBERS',
}

export enum EEpcCodeFormatOptions {
  PK = 'PK',
  SGTIN = 'SGTIN',
  SSCC = 'SSCC',
}

type TUniqueSequenceVariable = {
  variableType: KvintaEpcCodeVariableType.UniqueSequence;
  name: string;
  length: string;
  uniqueSequenceType: EUniqueSequenceType;
};
type TConstantVariable = {
  variableType: KvintaEpcCodeVariableType.Constant;
  name: string;
  value: string;
};
type TRestVariables = {
  variableType:
    | KvintaEpcCodeVariableType.Gtin
    | KvintaEpcCodeVariableType.CurrentHour
    | KvintaEpcCodeVariableType.Gcp
    | KvintaEpcCodeVariableType.ExtensionGtin
    | KvintaEpcCodeVariableType.GcpGtin;
  name: string;
};

export type TCodeFormatVariable = TUniqueSequenceVariable | TConstantVariable | TRestVariables;

type TCodeFormatVariables = TCodeFormatVariable[];

export type TSampleCodes =
  | {
      codeFormatType: KvintaEpcCodeType.Pk;

      sampleLongCode: string;
      sampleShortCode: string;

      samplePrimaryCode: string;
      sampleShortPrimaryCode: string;
    }
  | {
      codeFormatType: KvintaEpcCodeType.Sscc | KvintaEpcCodeType.Sgtin;
      sampleLongCode: string;
    };

export type TCodeFormatData =
  | {
      name: string;
      codeFormatType: KvintaEpcCodeType.Sscc | KvintaEpcCodeType.Sgtin;
      longCode: string;
      variables: TCodeFormatVariables;
    }
  | {
      name: string;
      codeFormatType: KvintaEpcCodeType.Pk;

      longCode: string;
      shortCode: string;

      primaryCode: string;
      shortPrimaryCode: string;

      variables: TCodeFormatVariables;
    };

export type TCodeFormat = TCodeFormatData & {
  id: string;
};

export const CODE_FORMAT_FORM_ROOT_ID = 'codeFormatForm';

export type TCodeFormatFormData = any;

type TCodeFormatTableRow = TCodeFormat & ISelectableRow;

export class CodeFormatsStore extends SelectableStore<TCodeFormatTableRow> {
  private _config: TAppOptionsConfig;
  private _codeFormatsApi: DefaultApi;
  private _historyStore: HistoryStore;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  currentCodeFormat: TCodeFormat | null = null;
  sampleCodes: TSampleCodes | null = null;

  updateCodeFormatData: any;
  updateCodeFormatFormOpen: boolean;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      isLoading: observable,
      currentCodeFormat: observable,
      sampleCodes: observable,
      updateCodeFormatData: observable,
      updateCodeFormatFormOpen: observable,

      setIsLoading: action.bound,
      setCurrentCodeFormat: action.bound,
      setCodeFormatFormData: action.bound,
      initCodeFormatFormData: action.bound,

      fetchCodeFormatList: action.bound,
      openCreateCodeFormatForm: action.bound,
      generateSampleCodeForView: action.bound,
      createCodeFormat: action.bound,
      updateCodeFormat: action.bound,
      submitCodeFormat: action.bound,
      submitCreateCodeFormat: action.bound,
      submitUpdateCodeFormat: action.bound,
      openUpdateCodeFormatForm: action.bound,
      closeUpdateCodeFormatForm: action.bound,
      deleteCodeFormat: action.bound,
      fetchCodeFormatData: action.bound,
      fetchCodeFormatById: action.bound,
      setSampleCodes: action.bound,
      initialSampleCodes: action.bound,
      cancelCreateCodeFormat: action.bound,

      handleFormBlur: action.bound,
      handleFormChange: action.bound,

      generateSampleCodeForForm: action.bound,
      generateSampleCode: action.bound,
      setSampleCodesForResult: action.bound,

      getEpcCodeById: action.bound,
      deleteEpcCodeById: action.bound,
      getListEpcCodes: action.bound,
      generateSampleEpcCode: action.bound,
    });

    this._codeFormatsApi = apis.defaultApi;
    this._historyStore = apis.historyStore;
    this._config = config;
    this._notificationManager = apis.notificationManager;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setSampleCodes(sampleCodesData: TSampleCodes) {
    if (sampleCodesData.codeFormatType === KvintaEpcCodeType.Pk) {
      this.sampleCodes = {
        codeFormatType: sampleCodesData.codeFormatType,
        sampleLongCode: sampleCodesData.sampleLongCode,
        sampleShortCode: sampleCodesData.sampleShortCode,
        samplePrimaryCode: sampleCodesData.samplePrimaryCode,
        sampleShortPrimaryCode: sampleCodesData.sampleShortPrimaryCode,
      };
    } else {
      this.sampleCodes = {
        codeFormatType: sampleCodesData.codeFormatType,
        sampleLongCode: sampleCodesData.sampleLongCode,
      };
    }
  }

  initialSampleCodes(codeFormatType: KvintaEpcCodeType) {
    if (codeFormatType === KvintaEpcCodeType.Pk) {
      this.sampleCodes = {
        codeFormatType: KvintaEpcCodeType.Pk,
        sampleLongCode: '',
        samplePrimaryCode: '',
        sampleShortCode: '',
        sampleShortPrimaryCode: '',
      };
    } else {
      this.sampleCodes = {
        codeFormatType: codeFormatType,
        sampleLongCode: '',
      };
    }
  }

  setCurrentCodeFormat(epcCode: KvintaEpcCode) {
    if (epcCode.type === KvintaEpcCodeType.Pk) {
      this.currentCodeFormat = {
        id: epcCode.id || '',
        name: epcCode.name || '',
        codeFormatType: epcCode.type,
        longCode: epcCode.longCode || '',
        shortCode: epcCode.shortCode || '',
        primaryCode: epcCode.primaryCode || '',
        shortPrimaryCode: epcCode.shortCodeForPrimary || '',
        variables: normalizeVariables(epcCode.variables),
      };
    } else {
      this.currentCodeFormat = {
        id: epcCode.id || '',
        name: epcCode.name || '',
        codeFormatType: epcCode.type,
        longCode: epcCode.longCode || '',
        variables: normalizeVariables(epcCode.variables),
      };
    }
    this.initialSampleCodes(epcCode.type);
  }

  initCodeFormatFormData() {
    const currentData = {
      name: '',
      codeFormatType: KvintaEpcCodeType.Pk,

      longCode: '',
      shortCode: '',

      primaryCode: '',
      shortPrimaryCode: '',

      variables: [],
    };

    this.updateCodeFormatData = validateForm<TCodeFormatFormData>(
      generateCodeFormatFormData(CODE_FORMAT_FORM_ROOT_ID, currentData),
    );
    this.initialSampleCodes(KvintaEpcCodeType.Pk);
  }

  setCodeFormatFormData() {
    const currentData = toJS(this.currentCodeFormat);
    this.updateCodeFormatData = validateForm<TCodeFormatFormData>(
      generateCodeFormatFormData(CODE_FORMAT_FORM_ROOT_ID, currentData),
    );
  }

  async fetchCodeFormatById(codeId: string) {
    this.setIsLoading(true);
    await this.fetchCodeFormatData(codeId);
    this.setIsLoading(false);
  }

  async fetchCodeFormatList() {
    this.setIsLoading(true);
    this.setListData([]);
    const resultData = await normalizeApiResponse<KvintaEpcCode[] | null>(
      this.getListEpcCodes,
      'An error occurred when trying to get Epc Code list',
    );
    if (resultData.error) {
      console.log('resultData.error', resultData);
      this._notificationManager.sendError(resultData.error);
    } else {
      const oldSelection = new Map();
      if (this.listData) {
        this.listData.forEach((row) => {
          oldSelection.set(row.id, row.isSelected);
        });
      }
      this.setListData(
        (resultData.result || []).map((epcCope) => {
          const selected = oldSelection.get(epcCope.id) || false;
          return {
            ...normalizeCodeFormatDataResponse(epcCope),
            isSelected: selected,
          };
        }),
      );
    }
    this.setIsLoading(false);
  }

  async deleteCodeFormat(codeId: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.deleteEpcCodeById(codeId),
      'An error occurred when trying to delete Code format by ID',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    await this.fetchCodeFormatList();
    this.setIsLoading(false);
  }

  async fetchCodeFormatData(codeId: string) {
    this.currentCodeFormat = null;
    const resultData = await normalizeApiResponse<KvintaEpcCode>(
      () => this.getEpcCodeById(codeId),
      'An error occurred when trying to get Epc Code by ID',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.setCurrentCodeFormat(resultData.result);
    }
  }

  async submitCodeFormat(
    action: Promise<{ error: string | null; result: KvintaEpcCode | null }>,
  ): Promise<{ error: string | null; result: KvintaEpcCode | null }> {
    const resultData = await action;
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    this.closeUpdateCodeFormatForm();
    return resultData;
  }

  async submitCreateCodeFormat() {
    this.setIsLoading(true);
    const resultData = await this.submitCodeFormat(
      normalizeApiResponse<KvintaEpcCode>(
        this.createCodeFormat,
        'An error occurred when trying to submit create Code format',
      ),
    );
    if (!resultData.error) {
      this._historyStore.navigateCodeFormatListPath();
    }
    this.setIsLoading(false);
  }

  async submitUpdateCodeFormat() {
    this.setIsLoading(true);
    const resultData = await this.submitCodeFormat(
      normalizeApiResponse<KvintaEpcCode>(
        this.updateCodeFormat,
        'An error occurred when trying to submit update Code format',
      ),
    );
    if (resultData.result.id) {
      await this.fetchCodeFormatData(resultData.result.id);
    }
    this.setIsLoading(false);
  }

  async generateSampleCodeForView(codeFormatData: TCodeFormatData) {
    await this.generateSampleCode(
      this.generateSampleEpcCode,
      normalizeGenerateSampleEpcCodeCreateRequest(codeFormatData),
    );
  }

  async generateSampleCodeForForm(codeFormatData: KvintaEpcCodeCreateRequest) {
    await this.generateSampleCode(this.generateSampleEpcCode, codeFormatData);
  }

  async generateSampleCode(action, codeFormatData: KvintaEpcCodeCreateRequest) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaGeneratedEpcCode>(
      () => action(codeFormatData),
      'An error occurred when trying to generate sample Codes',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.setSampleCodesForResult(resultData.result, codeFormatData.type);
    }
    this.setIsLoading(false);
  }

  setSampleCodesForResult(resultData: KvintaGeneratedEpcCode, codeFormatType: KvintaEpcCodeType) {
    const { longCode, shortCode, primaryCode, shortCodeForPrimary } = resultData;
    if (codeFormatType === KvintaEpcCodeType.Pk) {
      this.setSampleCodes({
        codeFormatType: codeFormatType,
        sampleLongCode: longCode,
        sampleShortCode: shortCode,
        samplePrimaryCode: primaryCode,
        sampleShortPrimaryCode: shortCodeForPrimary,
      });
    } else {
      this.setSampleCodes({
        codeFormatType: codeFormatType,
        sampleLongCode: longCode,
      });
    }
  }

  openCreateCodeFormatForm() {
    this.initCodeFormatFormData();
    this.updateCodeFormatFormOpen = true;
  }

  cancelCreateCodeFormat() {
    this._historyStore.navigateCodeFormatListPath();
  }

  openUpdateCodeFormatForm() {
    this.setCodeFormatFormData();
    this.updateCodeFormatFormOpen = true;
  }

  closeUpdateCodeFormatForm() {
    this.updateCodeFormatData = null;
    this.updateCodeFormatFormOpen = false;
  }

  handleFormBlur(id: string) {
    const formData = toJS(this.updateCodeFormatData);
    this.updateCodeFormatData = handleFormBlur(formData, id);
  }

  handleFormChange(id: string, value: any) {
    const formData = toJS(this.updateCodeFormatData);
    this.updateCodeFormatData = handleFormChange(formData, id, value);
  }

  addMember = (parentId: string, place: number) => {
    const formData = toJS(this.updateCodeFormatData);
    this.updateCodeFormatData = addArrayMember(
      parentId,
      place,
      {
        variableType: KvintaEpcCodeVariableType.UniqueSequence,
        name: '',
        length: '0',
        uniqueSequenceType: EUniqueSequenceType.ALPHANUMERIC,
      },
      formData,
    );
  };

  removeMember = (memberId: string) => {
    const formData = toJS(this.updateCodeFormatData);
    this.updateCodeFormatData = removeArrayMember(memberId, formData);
  };

  createCodeFormat() {
    return this._codeFormatsApi.createEpcCode({
      kvintaEpcCodeCreateRequest: {
        ...transformCodeFormatFormData(this.updateCodeFormatData),
      },
    });
  }

  updateCodeFormat() {
    return this._codeFormatsApi.updateEpcCode({
      kvintaEpcCode: {
        ...transformCodeFormatFormData(this.updateCodeFormatData),
        id: this.currentCodeFormat.id,
      },
    });
  }

  getEpcCodeById(codeId: string) {
    return this._codeFormatsApi.getEpcCode({
      kvintaEpcCodeActionRequest: { codeId },
    });
  }

  deleteEpcCodeById(codeId: string) {
    return this._codeFormatsApi.deleteEpcCode({
      kvintaEpcCodeActionRequest: { codeId },
    });
  }

  getListEpcCodes() {
    return this._codeFormatsApi.listEpcCodes();
  }

  generateSampleEpcCode(codeFormatData: KvintaEpcCodeCreateRequest) {
    return this._codeFormatsApi.generateSampleEpcCode({
      kvintaEpcCodeCreateRequest: codeFormatData,
    });
  }
}

export const STORE_ID = 'codeFormatsStore';

function normalizeGenerateSampleEpcCodeCreateRequest(codeFormatData: TCodeFormatData) {
  if (codeFormatData.codeFormatType !== KvintaEpcCodeType.Pk) {
    return {
      type: codeFormatData.codeFormatType,
      name: codeFormatData.name,
      longCode: codeFormatData.longCode,
      variables: codeFormatData.variables.map((variable) => normalizeVariablesForGenerateSampleEpcCode(variable)),
    };
  } else {
    return {
      type: codeFormatData.codeFormatType,
      name: codeFormatData.name,
      longCode: codeFormatData.longCode,
      shortCode: codeFormatData.shortCode,
      primaryCode: codeFormatData.primaryCode,
      shortPrimaryCode: codeFormatData.shortPrimaryCode,
      variables: codeFormatData.variables.map((variable) => normalizeVariablesForGenerateSampleEpcCode(variable)),
    };
  }
}

function normalizeVariablesForGenerateSampleEpcCode(variable: TCodeFormatVariable) {
  switch (variable.variableType) {
    case KvintaEpcCodeVariableType.UniqueSequence: {
      return {
        type: variable.variableType,
        name: variable.name,
        length: Number(variable.length),
        format: (variable as any).uniqueSequenceType,
      };
    }
    case KvintaEpcCodeVariableType.Constant: {
      return {
        type: variable.variableType,
        name: variable.name,
        value: variable.value,
      };
    }
    case KvintaEpcCodeVariableType.CurrentHour:
    case KvintaEpcCodeVariableType.ExtensionGtin:
    case KvintaEpcCodeVariableType.Gcp:
    case KvintaEpcCodeVariableType.GcpGtin:
    case KvintaEpcCodeVariableType.Gtin: {
      return {
        type: variable.variableType,
        name: variable.name,
      };
    }
  }
}

function normalizeCodeFormatDataResponse(data: KvintaEpcCode): TCodeFormat {
  switch (data.type) {
    case KvintaEpcCodeType.Pk: {
      return {
        id: data.id,
        name: data.name,
        codeFormatType: data.type,
        longCode: data.longCode,
        primaryCode: data.primaryCode,
        shortCode: data.shortCode,
        shortPrimaryCode: data.shortCodeForPrimary,
        variables: normalizeVariables(data.variables),
      };
    }
    case KvintaEpcCodeType.Sgtin:
    case KvintaEpcCodeType.Sscc: {
      return {
        id: data.id,
        name: data.name,
        codeFormatType: data.type,
        longCode: data.longCode,
        variables: normalizeVariables(data.variables),
      };
    }
  }
}

function normalizeVariables(variables: KvintaEpcCodeVariable[] = []) {
  return variables.map((variable) => {
    switch (variable.type) {
      case KvintaEpcCodeVariableType.Constant: {
        return {
          variableType: variable.type,
          name: variable.name || '',
          value: variable.value || '',
        };
      }
      case KvintaEpcCodeVariableType.UniqueSequence: {
        return {
          variableType: variable.type,
          name: variable.name || '',
          length: String(variable.length) || '0',
          uniqueSequenceType: (variable as any).format,
        };
      }
      case KvintaEpcCodeVariableType.CurrentHour:
      case KvintaEpcCodeVariableType.ExtensionGtin:
      case KvintaEpcCodeVariableType.Gcp:
      case KvintaEpcCodeVariableType.GcpGtin:
      case KvintaEpcCodeVariableType.Gtin: {
        return {
          variableType: variable.type,
          name: variable.name || '',
        };
      }
    }
  });
}

function generateCodeFormatFormData(rootFormId: string, initialValues: any) {
  return formRoot<TCodeFormatFormData>({
    formId: rootFormId,
    validations: [checkVariablesInCodeFormatsForExistence({ errorMessage: 'validation.error.required' })],
    childrenFactories: [
      textInput({
        path: 'name',
        value: initialValues.name,
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      array<TCodeFormatVariable>({
        path: 'variables',
        value: initialValues.variables,
        memberFactory: (id, memberInitialValue) =>
          oneOf({
            path: id,
            switcherOptions: {
              path: 'variableType',
              value: memberInitialValue.variableType,
              options: Object.values(KvintaEpcCodeVariableType).map((option) => ({ key: option, label: option })),
            },
            variants: {
              [KvintaEpcCodeVariableType.UniqueSequence]: {
                validations: [],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                  textInput({
                    path: 'length',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: (memberInitialValue as TUniqueSequenceVariable).length || '',
                    isRequiredField: true,
                  }),
                  select({
                    path: 'uniqueSequenceType',
                    value:
                      (memberInitialValue as TUniqueSequenceVariable).uniqueSequenceType || EUniqueSequenceType.NUMERIC,
                    options: Object.values(EUniqueSequenceType).map((option) => ({ key: option, label: option })),
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.Constant]: {
                validations: [],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                  textInput({
                    path: 'value',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: (memberInitialValue as TConstantVariable).value || '',
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.Gtin]: {
                validations: [],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.Gcp]: {
                validations: [],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.CurrentHour]: {
                validations: [],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.ExtensionGtin]: {
                validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                ],
              },
              [KvintaEpcCodeVariableType.GcpGtin]: {
                validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                children: [
                  textInput({
                    path: 'name',
                    validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
                    value: memberInitialValue.name || '',
                    isRequiredField: true,
                  }),
                ],
              },
            },
          }),
        validations: [],
      }),
      oneOf({
        path: 'codeFormatType',
        switcherOptions: {
          path: 'type',
          value: initialValues.codeFormatType,
          options: Object.values(EEpcCodeFormatOptions).map((option) => ({ key: option, label: option })),
        },
        variants: {
          [EEpcCodeFormatOptions.PK]: {
            validations: [],
            children: [
              textInput({
                path: 'longCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.longCode || '',
                isRequiredField: true,
              }),
              textInput({
                path: 'shortCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.shortCode || '',
                isRequiredField: true,
              }),
              textInput({
                path: 'primaryCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.primaryCode || '',
                isRequiredField: true,
              }),
              textInput({
                path: 'shortPrimaryCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.shortPrimaryCode || '',
                isRequiredField: true,
              }),
            ],
          },
          [EEpcCodeFormatOptions.SGTIN]: {
            validations: [],
            children: [
              textInput({
                path: 'longCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.longCode || '',
                isRequiredField: true,
              }),
            ],
          },
          [EEpcCodeFormatOptions.SSCC]: {
            validations: [],
            children: [
              textInput({
                path: 'longCode',
                validations: [
                  isNotEmpty({ errorMessage: 'validation.error.required' }),
                  codeFormatIsCorrect({ errorMessage: 'validation.error.code-format-is-not-correct' }),
                ],
                value: initialValues.longCode || '',
                isRequiredField: true,
              }),
            ],
          },
        },
      }),
    ],
  });
}

function variablesExists(options: { errorMessage: string }) {
  return function (id, data) {
    const errorVariable = [];
    const codeFormat: string = data[id].value;
    const usedVariables = codeFormat.match(new RegExp(/(?<=\$\{)[A-Za-z0-9-]*?(?=\})/g)) || [];
    const createdVariables = data['codeFormatForm.variables'].children.map((child) => data[`${child}.name`].value);
    usedVariables.forEach((variable) => {
      if (!createdVariables.includes(variable)) {
        errorVariable.push(variable);
      }
    });
    if (errorVariable.length) {
      return options.errorMessage;
    } else {
      return null;
    }
  };
}

function checkVariablesInCodeFormatsForExistence(options: { errorMessage: string }) {
  return function (id, data) {
    findErrorsInDependentField('codeFormatForm.codeFormatType.longCode', data);
    findErrorsInDependentField('codeFormatForm.codeFormatType.shortCode', data);
    findErrorsInDependentField('codeFormatForm.codeFormatType.primaryCode', data);
    findErrorsInDependentField('codeFormatForm.codeFormatType.shortPrimaryCode', data);

    if (errorsInField('codeFormatForm.codeFormatType.longCode', data)) {
      return options.errorMessage;
    }
    if (errorsInField('codeFormatForm.codeFormatType.shortCode', data)) {
      return options.errorMessage;
    }
    if (errorsInField('codeFormatForm.codeFormatType.primaryCode', data)) {
      return options.errorMessage;
    }
    if (errorsInField('codeFormatForm.codeFormatType.shortPrimaryCode', data)) {
      return options.errorMessage;
    }
    return null;
  };
}

function errorsInField(id, data) {
  return data[id] && data[id].errors.length;
}

function findErrorsInDependentField(idField, data) {
  const codeField = data[idField];
  if (codeField) {
    const error = variablesExists({ errorMessage: 'validation.error.variable-does-not-exist' })(idField, data);
    if (error) {
      if (!codeField.errors.length) {
        codeField.touched = true;
        codeField.showError = true;
        codeField.errors.push(error);
      }
    } else {
      if (codeField.errors.includes('validation.error.variable-does-not-exist')) {
        codeField.errors = codeField.errors.filter((error) => error !== 'validation.error.variable-does-not-exist');
      }
    }
  }
}

function codeFormatIsCorrect(options: { errorMessage: string }) {
  return function (id, data) {
    // TODO create a code format check for correctness
    return null;
  };
}

export function transformCodeFormatFormData(formData: TCodeFormatFormData): any {
  const transformFormData = {
    name: formData['codeFormatForm.name'].value.trim(),
    longCode: formData['codeFormatForm.codeFormatType.longCode'].value.trim(),
    type: formData['codeFormatForm.codeFormatType.type'].value.trim(),
  };
  if (transformFormData.type === KvintaEpcCodeType.Pk) {
    transformFormData['shortCode'] = formData['codeFormatForm.codeFormatType.shortCode'].value.trim();
    transformFormData['primaryCode'] = formData['codeFormatForm.codeFormatType.primaryCode'].value.trim();
    transformFormData['shortCodeForPrimary'] = formData['codeFormatForm.codeFormatType.shortPrimaryCode'].value.trim();
  }

  transformFormData['variables'] = formData['codeFormatForm.variables'].children.map((arrayMemberId) => {
    const variable = {
      name: formData[`${arrayMemberId}.name`].value.trim(),
      type: formData[`${arrayMemberId}.variableType`].value.trim(),
    };
    if (variable.type === KvintaEpcCodeVariableType.Constant) {
      variable['value'] = formData[`${arrayMemberId}.value`].value.trim();
    }
    if (variable.type === KvintaEpcCodeVariableType.UniqueSequence) {
      variable['format'] = formData[`${arrayMemberId}.uniqueSequenceType`].value.trim();
      variable['length'] = Number(formData[`${arrayMemberId}.length`].value.trim()) || 0;
    }
    return variable;
  });
  return transformFormData;
}

export enum EEpcCodeVariableType {
  'Constant' = 'CONSTANT',
  'Current Hour' = 'CURRENT_HOUR',
  'Extension (GTIN)' = 'EXTENSION_GTIN',
  'GCP' = 'GCP',
  'GCP (GTIN)' = 'GCP_GTIN',
  'GTIN' = 'GTIN',
  'Unique Sequence' = 'UNIQUE_SEQUENCE',
}
