import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  CodePoolApi,
  DefaultApi,
  KvintaCodePoolStatistic,
  KvintaCodeSource,
  KvintaEpcCode,
  KvintaEpcCodeType,
  KvintaHierarchyLevelType,
  KvintaLocation,
  KvintaLocationCodeSpecifics,
  KvintaProduct,
  KvintaProductPackLevel,
  KvintaProductRequest,
  ProductApi,
} from '../../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../../common/HistoryStore';
import { TApis } from '../../../../stores';
import { normalizeApiResponse } from '../../../apis/apis';
import {
  generateCheckboxState,
  transformCheckboxState,
} from '../../../components/FormFields/Fields_Exp/CheckBoxInput_Exp';
import { ECheckboxState } from '../../../common/formUtils/types';
import { array, checkBoxInput, formRoot, oneOf, select, textInput } from '../../../common/formUtils/formDataGenerators';
import {
  hasLength,
  isGreaterThan,
  isInteger,
  isIntegerForTpId,
  isLessThan,
  isNotEmpty,
  isNotEmptyForTpId,
  maxLength,
  maxLengthForTpId,
  minLength,
  minLengthForTpId,
  tpidIsCorrect,
  tpIdIsExactly,
} from '../../../common/formUtils/validators';
import { validateForm } from '../../../common/formUtils/core';
import {
  addArrayMember,
  handleFormBlur,
  handleFormChange,
  moveArrayMemberDown,
  moveArrayMemberUp,
  removeArrayMember,
} from '../../../common/formUtils/handlers';

export type TOptions = { key: string; label: string }[];

export type TFormHierarchyLevelView = {
  hierarchyLevelType: KvintaHierarchyLevelType;
  packagingLevelType: string;
  packagingLevelTypeOptions: TOptions;
  name: string;
  quantity: string;
  singleEpcisDocument: ECheckboxState;

  epcCodeId?: string;
  epcCodeIdOptions: TOptions;

  gtin?: string;
  gtinOption?: TOptions;

  gcp?: string;

  generateSscc?: ECheckboxState;
  codeSource?: ECheckboxState;
};

export type TFormHierarchyView = TFormHierarchyLevelView[];

export type TProductFormData = any;

export type TProductDataView = {
  id: string;

  rsku: string;
  lsku: string;
  tpId: string;
  tpPn: string;

  locationId: string;
  locationName: string;
  locationOptions: TOptions;

  codeSpecifics: KvintaLocationCodeSpecifics;

  createStockInQad: ECheckboxState;
  shippingAndReceiving: ECheckboxState;

  shipToLocationId: string;
  shipToLocationName: string;
  shipToLocationOptions: TOptions;

  hierarchy: TFormHierarchyView;
};

type TProductListData = {
  id: string;
  rsku: string;
  lsku: string;
  tpId: string;
  tpPn: string;
  locationId: string;
  locationName: string;
  shipToLocationId: string;
  createStockInQad: boolean;
  shippingAndReceiving: boolean;
  hierarchy: KvintaProductPackLevel[];
};

export interface IProductRow extends TProductListData, ISelectableRow {}

export class ProductStore extends SelectableStore<IProductRow> {
  private _config: TAppOptionsConfig;
  private _productApi: ProductApi;
  private _historyStore: HistoryStore;
  private _defaultApi: DefaultApi;
  private _codePoolApi: CodePoolApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  currentProduct?: TProductDataView = null;
  productFormData?: TProductFormData;
  productFormIsOpen: boolean;

  listLocations: KvintaLocation[];
  listCodeFormats: KvintaEpcCode[];
  listIdPools: string[];

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      setCurrentProduct: action.bound,
      setProductFormData: action.bound,
      setIsLoading: action.bound,
      setUpdateProductFormOpen: action.bound,
      setCreateProductFormData: action.bound,

      setListIdPool: action.bound,
      setListLocations: action.bound,
      setListCodeFormats: action.bound,

      fetchProductList: action.bound,
      fetchProductData: action.bound,
      fetchProduct: action.bound,
      fetchListLocations: action.bound,
      fetchListProductsData: action.bound,

      getCodePoolList: action.bound,
      getListProducts: action.bound,
      getLocationList: action.bound,

      handleFormBlur: action.bound,
      handleFormChange: action.bound,
      addMember: action.bound,
      removeMember: action.bound,
      moveMemberDown: action.bound,
      moveMemberUp: action.bound,

      openUpdateProductForm: action.bound,
      openCreateProductForm: action.bound,
      cancelCreateProduct: action.bound,
      cancelUpdateProduct: action.bound,
      closeCreateOrUpdateProduct: action.bound,

      submitUpdateProduct: action.bound,
      submitCreateProduct: action.bound,

      deleteProduct: action.bound,
      deleteProductApi: action.bound,

      isLoading: observable,
      productFormIsOpen: observable,
      currentProduct: observable,
      productFormData: observable,

      listLocations: observable,
      listIdPools: observable,
      listCodeFormats: observable,
    });
    this._productApi = apis.productApi;
    this._historyStore = apis.historyStore;
    this._defaultApi = apis.defaultApi;
    this._codePoolApi = apis.codePoolApi;
    this._config = config;
    this._notificationManager = apis.notificationManager;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setUpdateProductFormOpen(isOpen: boolean) {
    this.productFormIsOpen = isOpen;
  }

  setListLocations(listLocation: KvintaLocation[]) {
    this.listLocations = listLocation;
  }

  setListCodeFormats(listCodeFormats: KvintaEpcCode[]) {
    this.listCodeFormats = listCodeFormats;
  }

  setListIdPool(result: string[]) {
    this.listIdPools = result;
  }

  setCurrentProduct(productData: KvintaProduct) {
    this.currentProduct = normalizeProductDataView(
      productData,
      this.listLocations,
      this.listCodeFormats,
      this.listIdPools,
    );
  }

  setProductFormData() {
    const currentData = toJS(this.currentProduct);
    this.productFormData = validateForm<TProductFormData>(
      generateProductFormData(PRODUCT_FORM_ROOT_ID, currentData, this.listCodeFormats, this.listIdPools),
    );
  }

  setCreateProductFormData() {
    const newProduct: KvintaProduct = {
      rsku: '',
      lsku: '',
      createStockInQad: false,
      locationId: '',
      shippingAndReceiving: false,
      tpId: '',
      tpPn: '',
      hierarchy: [],
      updated: new Date(),
    };
    const newCurrentData = normalizeProductDataView(
      newProduct,
      this.listLocations,
      this.listCodeFormats,
      this.listIdPools,
    );
    this.productFormData = validateForm<TProductFormData>(
      generateProductFormData(PRODUCT_FORM_ROOT_ID, toJS(newCurrentData), this.listCodeFormats, this.listIdPools),
    );
  }

  async fetchProductList() {
    this.setIsLoading(true);
    this.setListData([]);
    const [productListDataResponse, listLocations] = await Promise.all([
      this.fetchListProductsData(),
      this.fetchListLocations(),
    ]);
    if (productListDataResponse && listLocations) {
      const productListData = productListDataResponse.map((product) => {
        const locationName = listLocations.find((location) => location.id === product.locationId)?.name || '';
        return normalizeProductListData(product, locationName);
      });
      this.setListData(
        (productListData || []).map((product) => {
          return {
            ...product,
            isSelected: false,
          };
        }),
      );
    }
    this.setIsLoading(false);
  }

  async fetchListProductsData() {
    const resultData = await normalizeApiResponse<Array<KvintaProduct> | null>(
      () => this.getListProducts(),
      'An error occurred when trying to get a list of products',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData.result;
  }

  getListProducts() {
    return this._productApi.listProducts();
  }

  async fetchProduct(rsku: string) {
    this.setIsLoading(true);
    const product = await this.fetchProductData(rsku);
    if (!product) {
      this._historyStore.navigateProductListPath();
    }
    this.setIsLoading(false);
  }

  async fetchProductData(rsku: string) {
    this.currentProduct = null;
    const [productData, listLocations, listCodeFormats, idPoolList] = await Promise.all([
      this.getProductData(rsku),
      this.fetchListLocations(),
      this.fetchListCodeFormats(),
      this.fetchListIdPools(),
    ]);
    if (productData && listLocations && listCodeFormats && idPoolList) {
      this.setCurrentProduct(productData);
      return productData;
    }
    return null;
  }

  async getProductData(rsku: string) {
    const resultData = await normalizeApiResponse<KvintaProduct>(
      () => this.getProduct(rsku),
      'error in getProductData',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }
    return resultData.result;
  }

  async getProduct(rsku: string) {
    return this._productApi.getProduct({
      kvintaProductActionRequest: {
        rsku,
      },
    });
  }

  handleFormBlur(id: string) {
    const formData = toJS(this.productFormData);
    this.productFormData = handleFormBlur(formData, id);
  }

  handleFormChange(id: string, value: any) {
    let formData = toJS(this.productFormData);
    if (id === 'productFormRoot.shipToLocationId' && value === 'None') {
      formData = toJS(handleFormChange(formData, 'productFormRoot.shippingAndReceiving', ECheckboxState.UNCHECKED));
    }
    if (id === 'productFormRoot.locationId') {
      const codeSpecifics =
        this.listLocations.find((location) => location.id === value)?.codeSpecifics || KvintaLocationCodeSpecifics.None;
      formData = toJS(handleFormChange(formData, 'productFormRoot.codeSpecifics', codeSpecifics));
      if (codeSpecifics === KvintaLocationCodeSpecifics.Codentify) {
        formData = toJS(handleFormChange(formData, 'productFormRoot.tpPn', notNeeded));
        formData = toJS(handleFormChange(formData, 'productFormRoot.tpId', notNeeded));
      } else {
        if (
          formData['productFormRoot.tpPn'].value === notNeeded ||
          formData['productFormRoot.tpId'].value === notNeeded
        ) {
          formData = toJS(handleFormChange(formData, 'productFormRoot.tpPn', ''));
          formData = toJS(handleFormChange(formData, 'productFormRoot.tpId', ''));
        }
      }
    }
    this.productFormData = handleFormChange(formData, id, value);
  }

  addMember = (parentId: string, place: number) => {
    const formData = toJS(this.productFormData);
    this.productFormData = addArrayMember(parentId, place, {}, formData);
  };

  removeMember = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = removeArrayMember(memberId, formData);
  };

  moveMemberDown = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = moveArrayMemberDown(memberId, formData);
  };

  moveMemberUp = (memberId: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = moveArrayMemberUp(memberId, formData);
  };

  async openUpdateProductForm() {
    this.setProductFormData();
    this.setUpdateProductFormOpen(true);
  }

  async openCreateProductForm() {
    this.setIsLoading(true);
    const [listLocations, listCodeFormats, idPoolList] = await Promise.all([
      this.fetchListLocations(),
      this.fetchListCodeFormats(),
      this.fetchListIdPools(),
    ]);
    if (listLocations && listCodeFormats && idPoolList) {
      this.setCreateProductFormData();
      this.setUpdateProductFormOpen(true);
    }
    this.setIsLoading(false);
  }

  cancelCreateProduct() {
    this._historyStore.navigateProductListPath();
    this.closeCreateOrUpdateProduct();
  }

  async cancelUpdateProduct() {
    await this.fetchProduct(this.currentProduct.id);
    this.closeCreateOrUpdateProduct();
  }

  closeCreateOrUpdateProduct = () => {
    this.productFormIsOpen = false;
    this.productFormData = null;
  };

  async submitUpdateProduct() {
    const updateData = transformProductFormData(this.productFormData);
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaProduct>(
      () => this.updateProduct(updateData),
      'error in getProductData',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchProductData(resultData.result.rsku);
      this.closeCreateOrUpdateProduct();
    }
    this.setIsLoading(false);
  }

  async updateProduct(kvintaProductRequest: KvintaProductRequest) {
    return this._productApi.updateProduct({
      kvintaProductRequest,
    });
  }

  async submitCreateProduct() {
    const updateData = transformProductFormData(this.productFormData);
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaProduct>(
      () => this.createProduct(updateData),
      'error in submitCreateProduct',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.closeCreateOrUpdateProduct();
      this._historyStore.navigateProductSummaryPath(resultData.result.rsku);
    }
    this.setIsLoading(false);
  }

  async createProduct(kvintaProductRequest: KvintaProductRequest) {
    return this._productApi.createProduct({
      kvintaProductRequest,
    });
  }

  async deleteProduct(rsku: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.deleteProductApi(rsku),
      'An error occurred when trying to get Epc Code by ID',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchProductList();
    }
    this.setIsLoading(false);
  }

  deleteProductApi(rsku: string) {
    return this._productApi.deleteProduct({
      kvintaProductActionRequest: {
        rsku,
      },
    });
  }

  async fetchListCodeFormats() {
    const resultData = await normalizeApiResponse<Array<KvintaEpcCode> | null>(
      () => this._defaultApi.listEpcCodes(),
      'An error occurred when trying to get Epc Code by ID',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListCodeFormats(resultData.result);
      return resultData.result;
    }
  }

  async fetchListLocations() {
    const resultData = await normalizeApiResponse<Array<KvintaLocation> | null>(
      () => this.getLocationList(),
      'An error occurred when trying to get a list of location',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListLocations(resultData.result);
      return resultData.result;
    }
  }

  async getLocationList() {
    return this._defaultApi.listLocations();
  }

  async fetchListIdPools() {
    const resultData = await normalizeApiResponse<string[]>(
      this.getCodePoolList,
      'An error occurred when trying to fetch Id Pool list',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
      return null;
    } else {
      this.setListIdPool(resultData.result);
      return resultData.result;
    }
  }

  async getCodePoolList() {
    return this._codePoolApi.listCodePoolsGtins();
  }
}
export const notNeeded = 'Not needed';
export const STORE_ID = 'productStore';
export const PRODUCT_FORM_ROOT_ID = 'productFormRoot';

export function getGtinFromOptions(options: TOptions, gtin: string) {
  if (options.length) {
    const gtinInOptions = options.find((value) => value.key === gtin);
    if (gtinInOptions) {
      return gtinInOptions.key;
    } else {
      return options[0].key;
    }
  } else return '';
}

export function generateProductFormData(
  rootFormId: string,
  initialValues: TProductDataView,
  listCodeFormats: KvintaEpcCode[],
  idPoolListExp: string[],
) {
  return formRoot<TProductFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'rsku',
        value: initialValues.rsku || '',
        validations: [
          isNotEmpty({ errorMessage: 'validation.error.required' }),
          isInteger({ errorMessage: 'validation.error.onlyInteger' }),
          maxLength({ errorMessage: 'validation.error.rskuLsku', maxLength: 9 }),
          minLength({ errorMessage: 'validation.error.rskuLsku', minLength: 8 }),
        ],
        isRequiredField: true,
      }),
      ...generalProductFormDataFields({ idPoolListExp, initialValues, listCodeFormats }),
    ],
  });
}

type TGeneralProductFormDataFields = {
  initialValues: TProductDataView;
  listCodeFormats: KvintaEpcCode[];
  idPoolListExp: string[];
};

export function generalProductFormDataFields({
  initialValues,
  listCodeFormats,
  idPoolListExp,
}: TGeneralProductFormDataFields) {
  return [
    textInput({
      path: 'lsku',
      value: initialValues.lsku || '',
      validations: [
        isNotEmpty({ errorMessage: 'validation.error.required' }),
        isInteger({ errorMessage: 'validation.error.onlyInteger' }),
        maxLength({ errorMessage: 'validation.error.rskuLsku', maxLength: 9 }),
        minLength({ errorMessage: 'validation.error.rskuLsku', minLength: 8 }),
      ],
      isRequiredField: true,
    }),
    textInput({
      path: 'codeSpecifics',
      value: initialValues.codeSpecifics || KvintaLocationCodeSpecifics.None,
      validations: [],
      isRequiredField: false,
    }),
    textInput({
      path: 'tpId',
      value: initialValues.tpId || '',
      validations: [
        isNotEmptyForTpId({ errorMessage: 'validation.error.required' }),
        tpidIsCorrect({ errorMessage: 'validation.error.onlyInteger' }),
        tpIdIsExactly({ errorMessage: 'validation.error.tpid' }),
      ],
      isRequiredField: true,
    }),
    textInput({
      path: 'tpPn',
      value: initialValues.tpPn || '',
      validations: [
        isNotEmptyForTpId({ errorMessage: 'validation.error.required' }),
        isIntegerForTpId({ errorMessage: 'validation.error.onlyInteger' }),
        maxLengthForTpId({ errorMessage: 'validation.error.tppn', maxLength: 30 }),
        minLengthForTpId({ errorMessage: 'validation.error.tppn', minLength: 5 }),
      ],
      isRequiredField: true,
    }),
    select({
      path: 'locationId',
      value: initialValues.locationId,
      options: initialValues.locationOptions,
      validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
      isRequiredField: true,
    }),
    select({
      path: 'shipToLocationId',
      value: initialValues.shipToLocationId,
      options: initialValues.shipToLocationOptions,
      validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
      isRequiredField: true,
    }),

    checkBoxInput({
      path: 'shippingAndReceiving',
      value: initialValues.shippingAndReceiving || ECheckboxState.NOT_SET,
      validations: [],
      isRequiredField: false,
    }),
    checkBoxInput({
      path: 'createStockInQad',
      value: initialValues.createStockInQad || ECheckboxState.NOT_SET,
      validations: [],
      isRequiredField: false,
    }),

    array<TFormHierarchyLevelView>({
      path: 'hierarchy',
      value: initialValues.hierarchy,
      validations: [
        singleEpcisDocumentOnlyOne({ errorMessage: 'validation.error.only-one-checked' }),
        singleEpcisDocumentIsRequired({ errorMessage: 'validation.error.is-required' }),
      ],
      memberFactory: (id, memberInitialValue) =>
        oneOfForHierarchyLevelType({ id, idPoolListExp, listCodeFormats, memberInitialValue }),
    }),
  ];
}

type TOneOfForHierarchyLevelType = {
  id: string;
  memberInitialValue: TFormHierarchyLevelView;
  idPoolListExp: string[];
  listCodeFormats: KvintaEpcCode[];
};

function oneOfForHierarchyLevelType({
  id,
  memberInitialValue,
  idPoolListExp,
  listCodeFormats,
}: TOneOfForHierarchyLevelType) {
  return oneOf({
    path: id,
    switcherOptions: {
      path: 'hierarchyLevelType',
      value: memberInitialValue.hierarchyLevelType || Object.values(KvintaHierarchyLevelType)[0],
      options: Object.values(KvintaHierarchyLevelType).map((option) => ({
        key: option,
        label: option,
      })),
    },
    variants: {
      [KvintaHierarchyLevelType.Pk]: {
        validations: [],
        children: [
          checkBoxInput({
            path: 'singleEpcisDocument',
            value: memberInitialValue.singleEpcisDocument || ECheckboxState.UNCHECKED,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'name',
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            value: memberInitialValue.name || '',
            isRequiredField: true,
          }),
          textInput({
            path: 'quantity',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            value: memberInitialValue.quantity || '1',
            isRequiredField: true,
          }),
          select({
            path: 'packagingLevelType',
            value: memberInitialValue.packagingLevelType || String(pkPackagingLevelOptions[0]),
            options: generatePackagingLevelTypeOptions(
              KvintaHierarchyLevelType.Pk,
              Number(memberInitialValue.packagingLevelType),
            ),
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            isRequiredField: true,
          }),
          oneOfForCodeSource({ idPoolListExp, listCodeFormats, memberInitialValue }),
        ],
      },

      [KvintaHierarchyLevelType.Sgtin]: {
        validations: [],
        children: [
          checkBoxInput({
            path: 'singleEpcisDocument',
            value: memberInitialValue.singleEpcisDocument || ECheckboxState.UNCHECKED,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'name',
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            value: memberInitialValue.name || '',
            isRequiredField: true,
          }),
          textInput({
            path: 'quantity',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            value: memberInitialValue.quantity || '1',
            isRequiredField: true,
          }),
          select({
            path: 'packagingLevelType',
            value: memberInitialValue.packagingLevelType || String(sgtinPackagingLevelOptions[0]),
            options: generatePackagingLevelTypeOptions(
              KvintaHierarchyLevelType.Sgtin,
              Number(memberInitialValue.packagingLevelType),
            ),
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            isRequiredField: true,
          }),
          select({
            path: 'epcCodeId',
            value:
              memberInitialValue.epcCodeId ||
              generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sgtin, listCodeFormats)[0].key ||
              '',
            options: generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sgtin, listCodeFormats),
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            isRequiredField: true,
          }),
          textInput({
            path: 'gtin',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              hasLength({ errorMessage: 'validation.error.gtin', requiredLength: 14 }),
            ],
            value: memberInitialValue.gtin || '',
            isRequiredField: true,
          }),
        ],
      },

      [KvintaHierarchyLevelType.Sscc]: {
        validations: [],
        children: [
          checkBoxInput({
            path: 'singleEpcisDocument',
            value: memberInitialValue.singleEpcisDocument || ECheckboxState.UNCHECKED,
            validations: [],
            isRequiredField: false,
          }),
          textInput({
            path: 'name',
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            value: memberInitialValue.name || '',
            isRequiredField: true,
          }),
          textInput({
            path: 'quantity',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            value: memberInitialValue.quantity || '1',
            isRequiredField: true,
          }),
          select({
            path: 'packagingLevelType',
            value: memberInitialValue.packagingLevelType || String(ssccPackagingLevelOptions[0]),
            options: generatePackagingLevelTypeOptions(
              KvintaHierarchyLevelType.Sscc,
              Number(memberInitialValue.packagingLevelType),
            ),
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              isGreaterThan({ errorMessage: 'validation.error.quantity', greaterThan: 0 }),
              isLessThan({ errorMessage: 'validation.error.quantity', lessThan: 101 }),
            ],
            isRequiredField: true,
          }),
          oneOfForGenerateSscc({ memberInitialValue, listCodeFormats }),
        ],
      },
    },
  });
}

type TOneOfForCodeSource = {
  memberInitialValue: TFormHierarchyLevelView;
  idPoolListExp: string[];
  listCodeFormats: KvintaEpcCode[];
};

function oneOfForCodeSource({ memberInitialValue, idPoolListExp, listCodeFormats }: TOneOfForCodeSource) {
  return oneOf({
    path: 'variants',
    switcherOptions: {
      path: 'codeSource',
      value: memberInitialValue.codeSource || ECheckboxState.UNCHECKED,
      options: Object.values(ECheckboxState).map((option) => ({
        key: option,
        label: option,
      })),
    },
    variants: {
      [ECheckboxState.CHECKED]: {
        validations: [],
        children: [
          select({
            path: 'gtin',
            value: getGtinFromOptions(generateCodePoolGtinOptions(idPoolListExp) || [], memberInitialValue.gtin) || '',
            options: generateCodePoolGtinOptions(idPoolListExp),
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              hasLength({
                errorMessage: 'validation.error.gtin',
                requiredLength: 14,
              }),
            ],
            isRequiredField: true,
          }),
        ],
      },
      [ECheckboxState.UNCHECKED]: {
        validations: [],
        children: [
          select({
            path: 'epcCodeId',
            value:
              memberInitialValue.epcCodeId ||
              generateEpcCodeIdOptions(KvintaHierarchyLevelType.Pk, listCodeFormats)[0].key,
            options: generateEpcCodeIdOptions(KvintaHierarchyLevelType.Pk, listCodeFormats),
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            isRequiredField: true,
          }),
          textInput({
            path: 'gtin',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              hasLength({
                errorMessage: 'validation.error.gtin',
                requiredLength: 14,
              }),
            ],
            value: memberInitialValue.gtin || '',
            isRequiredField: true,
          }),
        ],
      },
      [ECheckboxState.NOT_SET]: {
        validations: [],
        children: [
          select({
            path: 'epcCodeId',
            value:
              memberInitialValue.epcCodeId ||
              generateEpcCodeIdOptions(KvintaHierarchyLevelType.Pk, listCodeFormats)[0].key,
            options: generateEpcCodeIdOptions(KvintaHierarchyLevelType.Pk, listCodeFormats),
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            isRequiredField: true,
          }),
          textInput({
            path: 'gtin',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              hasLength({
                errorMessage: 'validation.error.gtin',
                requiredLength: 14,
              }),
            ],
            value: memberInitialValue.gtin || '',
            isRequiredField: true,
          }),
        ],
      },
    },
  });
}

type TOneOfForGenerateSscc = {
  memberInitialValue: TFormHierarchyLevelView;
  listCodeFormats: KvintaEpcCode[];
};

function oneOfForGenerateSscc({ memberInitialValue, listCodeFormats }: TOneOfForGenerateSscc) {
  return oneOf({
    path: 'variants',
    switcherOptions: {
      path: 'generateSscc',
      value: memberInitialValue.generateSscc || ECheckboxState.UNCHECKED,
      options: Object.values(ECheckboxState).map((option) => ({
        key: option,
        label: option,
      })),
    },
    variants: {
      [ECheckboxState.CHECKED]: {
        validations: [],
        children: [
          textInput({
            path: 'gcp',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              maxLength({
                errorMessage: 'validation.error.gcp',
                maxLength: 12,
              }),
              minLength({
                errorMessage: 'validation.error.gcp',
                minLength: 4,
              }),
            ],
            value: memberInitialValue.gcp || '',
            isRequiredField: true,
          }),
        ],
      },
      [ECheckboxState.UNCHECKED]: {
        validations: [],
        children: [
          select({
            path: 'epcCodeId',
            value:
              memberInitialValue.epcCodeId ||
              generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sscc, listCodeFormats)[0].key ||
              '',
            options: generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sscc, listCodeFormats),
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            isRequiredField: true,
          }),
          textInput({
            path: 'gcp',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              maxLength({
                errorMessage: 'validation.error.gcp',
                maxLength: 12,
              }),
              minLength({
                errorMessage: 'validation.error.gcp',
                minLength: 4,
              }),
            ],
            value: memberInitialValue.gcp || '',
            isRequiredField: true,
          }),
        ],
      },
      [ECheckboxState.NOT_SET]: {
        validations: [],
        children: [
          select({
            path: 'epcCodeId',
            value:
              memberInitialValue.epcCodeId ||
              generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sscc, listCodeFormats)[0].key ||
              '',
            options: generateEpcCodeIdOptions(KvintaHierarchyLevelType.Sscc, listCodeFormats),
            validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
            isRequiredField: true,
          }),
          textInput({
            path: 'gcp',
            validations: [
              isNotEmpty({ errorMessage: 'validation.error.required' }),
              isInteger({ errorMessage: 'validation.error.onlyInteger' }),
              maxLength({
                errorMessage: 'validation.error.gcp',
                maxLength: 12,
              }),
              minLength({
                errorMessage: 'validation.error.gcp',
                minLength: 4,
              }),
            ],
            value: memberInitialValue.gcp || '',
            isRequiredField: true,
          }),
        ],
      },
    },
  });
}

function notLastChild(array: any[], index: number) {
  return array.length - 1 !== index;
}

function fieldIsChecked(value: ECheckboxState) {
  return value === ECheckboxState.CHECKED;
}

function hasNoError(errors: string[], error: string) {
  return !errors.includes(error);
}

export function singleEpcisDocumentOnlyOne(options: { errorMessage: string }) {
  return function (id, data) {
    let singleEpcisDocumentCounter = 0;
    const childrenIdArray = data[id].children as string[];
    childrenIdArray.forEach((childrenId, index) => {
      if (notLastChild(childrenIdArray, index) && fieldIsChecked(data[`${childrenId}.singleEpcisDocument`].value)) {
        singleEpcisDocumentCounter++;
      }
    });
    if (singleEpcisDocumentCounter > 1) {
      childrenIdArray.forEach((childrenId, index) => {
        if (
          notLastChild(childrenIdArray, index) &&
          fieldIsChecked(data[`${childrenId}.singleEpcisDocument`].value) &&
          hasNoError(data[`${childrenId}.singleEpcisDocument`].errors, options.errorMessage)
        ) {
          data[`${childrenId}.singleEpcisDocument`].errors.push(options.errorMessage);
          data[`${childrenId}.singleEpcisDocument`].touched = true;
          data[`${childrenId}.singleEpcisDocument`].showError = true;
        }
      });
      return options.errorMessage;
    } else {
      childrenIdArray.forEach((childrenId, index) => {
        data[`${childrenId}.singleEpcisDocument`].errors = data[`${childrenId}.singleEpcisDocument`].errors.filter(
          (error) => error !== options.errorMessage,
        );
      });
      return null;
    }
  };
}

export function singleEpcisDocumentIsRequired(options: { errorMessage: string }) {
  return function (id, data) {
    let singleEpcisDocumentCounter = 0;
    const childrenIdArray = data[id].children;
    childrenIdArray.forEach((childrenId, index) => {
      if (notLastChild(childrenIdArray, index) && fieldIsChecked(data[`${childrenId}.singleEpcisDocument`].value)) {
        singleEpcisDocumentCounter++;
      }
    });

    if (singleEpcisDocumentCounter !== 1 && childrenIdArray.length > 1) {
      childrenIdArray.forEach((childrenId, index) => {
        if (
          notLastChild(childrenIdArray, index) &&
          hasNoError(data[`${childrenId}.singleEpcisDocument`].errors, options.errorMessage)
        ) {
          data[`${childrenId}.singleEpcisDocument`].errors.push(options.errorMessage);
          data[`${childrenId}.singleEpcisDocument`].touched = true;
          data[`${childrenId}.singleEpcisDocument`].showError = true;
        }
      });
      return options.errorMessage;
    } else {
      childrenIdArray.forEach((childrenId, index) => {
        data[`${childrenId}.singleEpcisDocument`].errors = data[`${childrenId}.singleEpcisDocument`].errors.filter(
          (error) => error !== options.errorMessage,
        );
      });
      return null;
    }
  };
}

function transformCodeSource(codeSource: ECheckboxState): KvintaCodeSource {
  switch (codeSource) {
    case ECheckboxState.CHECKED: {
      return KvintaCodeSource.Pool;
    }
    case ECheckboxState.NOT_SET:
    case ECheckboxState.UNCHECKED: {
      return KvintaCodeSource.Autogenerated;
    }
  }
}

export function transformProductFormData(formData: TProductFormData, productId?: string): any {
  const transformFormData = {
    rsku: formData[`${PRODUCT_FORM_ROOT_ID}.rsku`].value.trim(),
    lsku: formData[`${PRODUCT_FORM_ROOT_ID}.lsku`].value.trim(),
    locationId: formData[`${PRODUCT_FORM_ROOT_ID}.locationId`].value.trim(),
    createStockInQad: transformCheckboxState(formData[`${PRODUCT_FORM_ROOT_ID}.createStockInQad`].value.trim()),
    tpId: formData[`${PRODUCT_FORM_ROOT_ID}.tpId`].value.trim(),
    tpPn: formData[`${PRODUCT_FORM_ROOT_ID}.tpPn`].value.trim(),
    shippingAndReceiving: transformCheckboxState(formData[`${PRODUCT_FORM_ROOT_ID}.shippingAndReceiving`].value.trim()),
  };
  if (productId) {
    transformFormData['id'] = productId;
  }
  if (formData[`${PRODUCT_FORM_ROOT_ID}.shipToLocationId`].value.trim() !== 'None') {
    transformFormData['shipToLocationId'] = formData[`${PRODUCT_FORM_ROOT_ID}.shipToLocationId`].value.trim();
  }

  transformFormData['hierarchy'] = formData[`${PRODUCT_FORM_ROOT_ID}.hierarchy`].children.map(
    (arrayMemberId, index: number) => {
      const variable = {
        name: formData[`${arrayMemberId}.name`].value.trim(),
        packagingLevelType: Number(formData[`${arrayMemberId}.packagingLevelType`].value.trim() || '0') || 0,
        hierarchyLevelType: formData[`${arrayMemberId}.hierarchyLevelType`].value.trim(),
        quantity: Number(formData[`${arrayMemberId}.quantity`].value.trim() || '0') || 0,
        singleEpcisDocument: transformCheckboxState(formData[`${arrayMemberId}.singleEpcisDocument`].value.trim()),
      };

      if (index === formData[`${PRODUCT_FORM_ROOT_ID}.hierarchy`].children.length - 1) {
        variable.singleEpcisDocument = false;
      }

      switch (formData[`${arrayMemberId}.hierarchyLevelType`].value.trim()) {
        case KvintaHierarchyLevelType.Pk: {
          variable['codeSource'] = transformCodeSource(formData[`${arrayMemberId}.variants.codeSource`].value.trim());
          variable['gtin'] = formData[`${arrayMemberId}.variants.gtin`].value.trim();
          if (
            formData[`${arrayMemberId}.variants.codeSource`].value.trim() === ECheckboxState.UNCHECKED ||
            formData[`${arrayMemberId}.variants.codeSource`].value.trim() === ECheckboxState.NOT_SET
          ) {
            variable['epcCodeId'] = formData[`${arrayMemberId}.variants.epcCodeId`].value.trim();
          } else {
            variable['epcCodeId'] = transformCodeSource(formData[`${arrayMemberId}.variants.codeSource`].value.trim());
          }
          break;
        }
        case KvintaHierarchyLevelType.Sscc: {
          variable['generateSscc'] = transformCheckboxState(
            formData[`${arrayMemberId}.variants.generateSscc`].value.trim(),
          );
          variable['gcp'] = formData[`${arrayMemberId}.variants.gcp`].value.trim();
          variable['codeSource'] = KvintaCodeSource.Autogenerated;

          if (
            formData[`${arrayMemberId}.variants.generateSscc`].value.trim() === ECheckboxState.UNCHECKED ||
            formData[`${arrayMemberId}.variants.generateSscc`].value.trim() === ECheckboxState.NOT_SET
          ) {
            variable['epcCodeId'] = formData[`${arrayMemberId}.variants.epcCodeId`].value.trim();
          } else {
            variable['epcCodeId'] = 'GENERATE_SSCC';
          }
          break;
        }
        case KvintaHierarchyLevelType.Sgtin: {
          variable['codeSource'] = KvintaCodeSource.Autogenerated;
          variable['gtin'] = formData[`${arrayMemberId}.gtin`].value.trim();
          variable['epcCodeId'] = formData[`${arrayMemberId}.epcCodeId`].value.trim();
          break;
        }
        default:
          break;
      }
      return variable;
    },
  );
  return transformFormData;
}

function normalizeProductListData(product: KvintaProduct, locationName: string) {
  return {
    id: product.rsku,
    lsku: product.lsku,
    rsku: product.rsku,
    createStockInQad: product.createStockInQad,
    locationId: product.locationId,
    shippingAndReceiving: product.shippingAndReceiving,
    tpId: product.tpId,
    tpPn: product.tpPn,
    shipToLocationId: product.shipToLocationId,
    locationName,
    hierarchy: product.hierarchy || [],
  };
}

export function doGeneratePackagingLevelType(options: number[], customOption: number): TOptions {
  const newOptions = options;
  if (customOption) {
    newOptions.push(customOption);
  }
  return Array.from(new Set(newOptions)).map((value) => {
    return {
      key: String(value),
      label: String(value),
    };
  });
}

export function generatePackagingLevelTypeOptions(hierarchyType: KvintaHierarchyLevelType, customOption?: number) {
  switch (hierarchyType) {
    case KvintaHierarchyLevelType.Pk:
      return doGeneratePackagingLevelType(pkPackagingLevelOptions, customOption);
    case KvintaHierarchyLevelType.Sgtin:
      return doGeneratePackagingLevelType(sgtinPackagingLevelOptions, customOption);
    case KvintaHierarchyLevelType.Sscc:
      return doGeneratePackagingLevelType(ssccPackagingLevelOptions, customOption);
  }
}

export function generateEpcCodeIdOptions(hierarchyType: KvintaHierarchyLevelType, listCodeFormats: KvintaEpcCode[]) {
  switch (hierarchyType) {
    case KvintaHierarchyLevelType.Pk:
      return listCodeFormats
        .filter((value) => value.type === KvintaEpcCodeType.Pk)
        .map((value) => {
          return {
            key: value.id,
            label: value.name,
          };
        });
    case KvintaHierarchyLevelType.Sgtin:
      return listCodeFormats
        .filter((value) => value.type === KvintaEpcCodeType.Sgtin)
        .map((value) => {
          return {
            key: value.id,
            label: value.name,
          };
        });
    case KvintaHierarchyLevelType.Sscc:
      return listCodeFormats
        .filter((value) => value.type === KvintaEpcCodeType.Sscc)
        .map((value) => {
          return {
            key: value.id,
            label: value.name,
          };
        });
  }
}

export function generateCodePoolGtinOptions(listCodePools: string[]) {
  return listCodePools.map((value) => {
    return {
      key: value,
      label: value,
    };
  });
}

export function getOptionLabel(options: TOptions, key: string, notFound = 'not found') {
  const option = options.find((value) => value.key === key);
  if (option) {
    return option.label;
  } else {
    return '';
  }
}

function getLocationData(product: KvintaProduct, listLocations: KvintaLocation[]) {
  let locationId = product.locationId;
  const locationOptions = listLocations.map((location) => {
    return {
      key: location.id,
      label: location.name,
    };
  });
  const locationName = getOptionLabel(locationOptions, product.locationId);
  if (!locationName) {
    locationId = '';
  }
  return {
    locationId,
    locationOptions,
    locationName,
  };
}
function getShipToLocationData(product: KvintaProduct, listLocations: KvintaLocation[]) {
  const locationOptions = listLocations.map((location) => {
    return {
      key: location.id,
      label: location.name,
    };
  });
  const shipToLocationOptions = [
    ...locationOptions,
    {
      key: 'None',
      label: 'None',
    },
  ];
  let shipToLocationId = product.shipToLocationId ? product.shipToLocationId : 'None';
  const shipToLocationName = getOptionLabel(shipToLocationOptions, shipToLocationId);
  if (!shipToLocationName) {
    shipToLocationId = '';
  }
  return {
    shipToLocationId,
    shipToLocationOptions,
    shipToLocationName,
  };
}

export function normalizeProductDataView(
  product: KvintaProduct,
  listLocations: KvintaLocation[],
  listCodeFormats: KvintaEpcCode[],
  idPoolList: string[],
) {
  const { locationId, locationName, locationOptions } = getLocationData(product, listLocations);
  const codeSpecifics =
    listLocations.find((location) => location.id === product.locationId)?.codeSpecifics ||
    KvintaLocationCodeSpecifics.None;
  const { shipToLocationId, shipToLocationName, shipToLocationOptions } = getShipToLocationData(product, listLocations);

  return {
    id: product.rsku,
    lsku: product.lsku,
    rsku: product.rsku,
    tpId:
      codeSpecifics === KvintaLocationCodeSpecifics.Codentify
        ? notNeeded
        : product.tpId === notNeeded
        ? ''
        : product.tpId,
    tpPn:
      codeSpecifics === KvintaLocationCodeSpecifics.Codentify
        ? notNeeded
        : product.tpPn === notNeeded
        ? ''
        : product.tpPn,

    createStockInQad: generateCheckboxState(product.createStockInQad),
    shippingAndReceiving:
      shipToLocationId === 'None' ? ECheckboxState.UNCHECKED : generateCheckboxState(product.shippingAndReceiving),

    locationId,
    locationName,
    locationOptions,
    codeSpecifics,

    shipToLocationId,
    shipToLocationName,
    shipToLocationOptions,

    hierarchy: product.hierarchy.map((level) => {
      const packagingLevelTypeOptions =
        generatePackagingLevelTypeOptions(level.hierarchyLevelType, level.packagingLevelType) || [];
      const epcCodeIdOptions = generateEpcCodeIdOptions(level.hierarchyLevelType, listCodeFormats);
      const gtinOption = generateCodePoolGtinOptions(idPoolList);

      const generateSscc = generateCheckboxState(level.generateSscc || false);
      const singleEpcisDocument = generateCheckboxState(level.singleEpcisDocument);

      switch ((level.hierarchyLevelType as KvintaHierarchyLevelType) || KvintaHierarchyLevelType.Pk) {
        case KvintaHierarchyLevelType.Sscc: {
          return (function () {
            switch (generateSscc || ECheckboxState.UNCHECKED) {
              case ECheckboxState.UNCHECKED:
              case ECheckboxState.NOT_SET: {
                return {
                  singleEpcisDocument,
                  name: level.name,
                  quantity: String(level.quantity),
                  packagingLevelType: String(level.packagingLevelType),
                  packagingLevelTypeOptions,

                  hierarchyLevelType: KvintaHierarchyLevelType.Sscc,
                  gcp: level.gcp,
                  epcCodeIdOptions,

                  generateSscc,
                  epcCodeId: level.epcCodeId,
                };
              }
              case ECheckboxState.CHECKED: {
                return {
                  singleEpcisDocument,
                  name: level.name,
                  quantity: String(level.quantity),
                  packagingLevelType: String(level.packagingLevelType),
                  packagingLevelTypeOptions,

                  hierarchyLevelType: KvintaHierarchyLevelType.Sscc,
                  gcp: level.gcp,
                  epcCodeIdOptions,

                  generateSscc,
                };
              }
            }
          })();
        }
        case KvintaHierarchyLevelType.Sgtin: {
          return {
            singleEpcisDocument,
            name: level.name,
            quantity: String(level.quantity),
            packagingLevelType: String(level.packagingLevelType),
            packagingLevelTypeOptions,

            hierarchyLevelType: KvintaHierarchyLevelType.Sgtin,
            gtin: level.gtin,
            epcCodeId: level.epcCodeId,
            epcCodeIdOptions,
          };
        }
        case KvintaHierarchyLevelType.Pk: {
          return (function () {
            const codeSource =
              level.codeSource === KvintaCodeSource.Pool ? ECheckboxState.CHECKED : ECheckboxState.UNCHECKED;
            switch (level.codeSource as KvintaCodeSource) {
              case KvintaCodeSource.Autogenerated: {
                return {
                  singleEpcisDocument,
                  name: level.name,
                  quantity: String(level.quantity),
                  packagingLevelType: String(level.packagingLevelType),
                  packagingLevelTypeOptions,

                  hierarchyLevelType: KvintaHierarchyLevelType.Pk,
                  gtin: level.gtin,
                  gtinOption,
                  epcCodeIdOptions,

                  codeSource,
                  epcCodeId: level.epcCodeId,
                };
              }
              case KvintaCodeSource.Pool: {
                return {
                  singleEpcisDocument,
                  name: level.name,
                  quantity: String(level.quantity),
                  packagingLevelType: String(level.packagingLevelType),
                  packagingLevelTypeOptions,

                  hierarchyLevelType: KvintaHierarchyLevelType.Pk,
                  gtin: level.gtin,
                  gtinOption,
                  epcCodeIdOptions,

                  codeSource,
                };
              }
            }
          })();
        }
      }
    }),
  };
}

export const pkPackagingLevelOptions = [10];
export const sgtinPackagingLevelOptions = [11, 12, 13, 20, 21, 22, 30];
export const ssccPackagingLevelOptions = [40];
