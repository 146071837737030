/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationResponseListProduct,
    KvintaOperationResponseListProductFromJSON,
    KvintaOperationResponseListProductToJSON,
    KvintaOperationResponseProduct,
    KvintaOperationResponseProductFromJSON,
    KvintaOperationResponseProductToJSON,
    KvintaOperationResponseUnit,
    KvintaOperationResponseUnitFromJSON,
    KvintaOperationResponseUnitToJSON,
    KvintaProductActionRequest,
    KvintaProductActionRequestFromJSON,
    KvintaProductActionRequestToJSON,
    KvintaProductRequest,
    KvintaProductRequestFromJSON,
    KvintaProductRequestToJSON,
} from '../models';

export interface CreateProductRequest {
    kvintaProductRequest: KvintaProductRequest;
}

export interface DeleteProductRequest {
    kvintaProductActionRequest: KvintaProductActionRequest;
}

export interface GetProductRequest {
    kvintaProductActionRequest: KvintaProductActionRequest;
}

export interface UpdateProductRequest {
    kvintaProductRequest: KvintaProductRequest;
}

/**
 * 
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     * Create product in user configuration
     * Create product
     */
    async createProductRaw(requestParameters: CreateProductRequest): Promise<runtime.ApiResponse<KvintaOperationResponseProduct>> {
        if (requestParameters.kvintaProductRequest === null || requestParameters.kvintaProductRequest === undefined) {
            throw new runtime.RequiredError('kvintaProductRequest','Required parameter requestParameters.kvintaProductRequest was null or undefined when calling createProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/createProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaProductRequestToJSON(requestParameters.kvintaProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseProductFromJSON(jsonValue));
    }

    /**
     * Create product in user configuration
     * Create product
     */
    async createProduct(requestParameters: CreateProductRequest): Promise<KvintaOperationResponseProduct> {
        const response = await this.createProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete product in user configuration
     * Delete product
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaProductActionRequest === null || requestParameters.kvintaProductActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaProductActionRequest','Required parameter requestParameters.kvintaProductActionRequest was null or undefined when calling deleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/deleteProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaProductActionRequestToJSON(requestParameters.kvintaProductActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Delete product in user configuration
     * Delete product
     */
    async deleteProduct(requestParameters: DeleteProductRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.deleteProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get product from user configuration
     * Get product
     */
    async getProductRaw(requestParameters: GetProductRequest): Promise<runtime.ApiResponse<KvintaOperationResponseProduct>> {
        if (requestParameters.kvintaProductActionRequest === null || requestParameters.kvintaProductActionRequest === undefined) {
            throw new runtime.RequiredError('kvintaProductActionRequest','Required parameter requestParameters.kvintaProductActionRequest was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/getProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaProductActionRequestToJSON(requestParameters.kvintaProductActionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseProductFromJSON(jsonValue));
    }

    /**
     * Get product from user configuration
     * Get product
     */
    async getProduct(requestParameters: GetProductRequest): Promise<KvintaOperationResponseProduct> {
        const response = await this.getProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all products from user configuration
     * Get all products
     */
    async listProductsRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseListProduct>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/userSettings/listProducts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListProductFromJSON(jsonValue));
    }

    /**
     * Get all products from user configuration
     * Get all products
     */
    async listProducts(): Promise<KvintaOperationResponseListProduct> {
        const response = await this.listProductsRaw();
        return await response.value();
    }

    /**
     * Update product in user configuration
     * Update product
     */
    async updateProductRaw(requestParameters: UpdateProductRequest): Promise<runtime.ApiResponse<KvintaOperationResponseProduct>> {
        if (requestParameters.kvintaProductRequest === null || requestParameters.kvintaProductRequest === undefined) {
            throw new runtime.RequiredError('kvintaProductRequest','Required parameter requestParameters.kvintaProductRequest was null or undefined when calling updateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/userSettings/updateProduct`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaProductRequestToJSON(requestParameters.kvintaProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseProductFromJSON(jsonValue));
    }

    /**
     * Update product in user configuration
     * Update product
     */
    async updateProduct(requestParameters: UpdateProductRequest): Promise<KvintaOperationResponseProduct> {
        const response = await this.updateProductRaw(requestParameters);
        return await response.value();
    }

}
