/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaLocationCodeSpecifics,
    KvintaLocationCodeSpecificsFromJSON,
    KvintaLocationCodeSpecificsFromJSONTyped,
    KvintaLocationCodeSpecificsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaLocationUpdateRequest
 */
export interface KvintaLocationUpdateRequest {
    /**
     * Location identifier
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    id: string;
    /**
     * Display name
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    name: string;
    /**
     * Unique location free text identifier (siteId, gln, sgln)
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    locationId: string;
    /**
     * Economic operator identifier
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    eoId: string;
    /**
     * Facility identifier
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    facilityId: string;
    /**
     * 
     * @type {KvintaLocationCodeSpecifics}
     * @memberof KvintaLocationUpdateRequest
     */
    codeSpecifics: KvintaLocationCodeSpecifics;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    readPoint: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationUpdateRequest
     */
    machineId: string;
}

export function KvintaLocationUpdateRequestFromJSON(json: any): KvintaLocationUpdateRequest {
    return KvintaLocationUpdateRequestFromJSONTyped(json, false);
}

export function KvintaLocationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'locationId': json['locationId'],
        'eoId': json['eoId'],
        'facilityId': json['facilityId'],
        'codeSpecifics': KvintaLocationCodeSpecificsFromJSON(json['codeSpecifics']),
        'readPoint': json['readPoint'],
        'machineId': json['machineId'],
    };
}

export function KvintaLocationUpdateRequestToJSON(value?: KvintaLocationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'locationId': value.locationId,
        'eoId': value.eoId,
        'facilityId': value.facilityId,
        'codeSpecifics': KvintaLocationCodeSpecificsToJSON(value.codeSpecifics),
        'readPoint': value.readPoint,
        'machineId': value.machineId,
    };
}


