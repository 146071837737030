/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTaskDefinitionActionRequest
 */
export interface KvintaTaskDefinitionActionRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionActionRequest
     */
    taskDefinitionId: string;
}

export function KvintaTaskDefinitionActionRequestFromJSON(json: any): KvintaTaskDefinitionActionRequest {
    return KvintaTaskDefinitionActionRequestFromJSONTyped(json, false);
}

export function KvintaTaskDefinitionActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskDefinitionActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskDefinitionId': json['taskDefinitionId'],
    };
}

export function KvintaTaskDefinitionActionRequestToJSON(value?: KvintaTaskDefinitionActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskDefinitionId': value.taskDefinitionId,
    };
}


