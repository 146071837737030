/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaCodeSource {
    Autogenerated = 'AUTOGENERATED',
    Pool = 'POOL'
}

export function KvintaCodeSourceFromJSON(json: any): KvintaCodeSource {
    return KvintaCodeSourceFromJSONTyped(json, false);
}

export function KvintaCodeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCodeSource {
    return json as KvintaCodeSource;
}

export function KvintaCodeSourceToJSON(value?: KvintaCodeSource | null): any {
    return value as any;
}

