import React, { FC } from 'react';
import * as DateFns from 'date-fns';
import { IntlShape } from 'react-intl';

type TDurationsTimeField = {
  start?: number;
  end: number;
  intl: IntlShape;
};

export const DurationsTimeField: FC<TDurationsTimeField> = ({ start = 0, end, intl }) => {
  let duration = '';
  if (end) {
    duration =
      DateFns.formatDuration(
        DateFns.intervalToDuration({
          start: start,
          end: end,
        }),
        {
          format: ['years', 'months', 'days', 'hours', 'minutes'],
        },
      ) || intl.formatMessage({ id: 'task.logs-list.lessThanMinute' });
  }

  return <span>{duration}</span>;
};
