/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaProductPackLevel,
    KvintaProductPackLevelFromJSON,
    KvintaProductPackLevelFromJSONTyped,
    KvintaProductPackLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTaskDefinitionProductCreateUpdateRequest
 */
export interface KvintaTaskDefinitionProductCreateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    id?: string | null;
    /**
     * retail stock keeping unit (product)
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    rsku: string;
    /**
     * local stock keeping unit
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    lsku: string;
    /**
     * the factory location id
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    locationId: string;
    /**
     * optional field to be used in case of shipping event is required, target location for shipping,
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    shipToLocationId?: string | null;
    /**
     * indicates the case when Location (Factory) is a factory warehouse and eASN is required to be sent to QAD
     * @type {boolean}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    createStockInQad: boolean;
    /**
     * registration of the EU blend
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    tpId: string;
    /**
     * registration of the EU blend
     * @type {string}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    tpPn: string;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    shippingAndReceiving: boolean;
    /**
     * 
     * @type {Array<KvintaProductPackLevel>}
     * @memberof KvintaTaskDefinitionProductCreateUpdateRequest
     */
    hierarchy: Array<KvintaProductPackLevel>;
}

export function KvintaTaskDefinitionProductCreateUpdateRequestFromJSON(json: any): KvintaTaskDefinitionProductCreateUpdateRequest {
    return KvintaTaskDefinitionProductCreateUpdateRequestFromJSONTyped(json, false);
}

export function KvintaTaskDefinitionProductCreateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskDefinitionProductCreateUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rsku': json['rsku'],
        'lsku': json['lsku'],
        'locationId': json['locationId'],
        'shipToLocationId': !exists(json, 'shipToLocationId') ? undefined : json['shipToLocationId'],
        'createStockInQad': json['createStockInQad'],
        'tpId': json['tpId'],
        'tpPn': json['tpPn'],
        'shippingAndReceiving': json['shippingAndReceiving'],
        'hierarchy': ((json['hierarchy'] as Array<any>).map(KvintaProductPackLevelFromJSON)),
    };
}

export function KvintaTaskDefinitionProductCreateUpdateRequestToJSON(value?: KvintaTaskDefinitionProductCreateUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rsku': value.rsku,
        'lsku': value.lsku,
        'locationId': value.locationId,
        'shipToLocationId': value.shipToLocationId,
        'createStockInQad': value.createStockInQad,
        'tpId': value.tpId,
        'tpPn': value.tpPn,
        'shippingAndReceiving': value.shippingAndReceiving,
        'hierarchy': ((value.hierarchy as Array<any>).map(KvintaProductPackLevelToJSON)),
    };
}


