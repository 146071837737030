/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaLocationCodeSpecifics {
    None = 'NONE',
    CodePairing = 'CODE_PAIRING',
    Codentify = 'CODENTIFY'
}

export function KvintaLocationCodeSpecificsFromJSON(json: any): KvintaLocationCodeSpecifics {
    return KvintaLocationCodeSpecificsFromJSONTyped(json, false);
}

export function KvintaLocationCodeSpecificsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocationCodeSpecifics {
    return json as KvintaLocationCodeSpecifics;
}

export function KvintaLocationCodeSpecificsToJSON(value?: KvintaLocationCodeSpecifics | null): any {
    return value as any;
}

