import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './TextInput_Exp.module.scss';
import { Button, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import moduleStyle from '../../../../assets/css/partials/modules/button.module.scss';

export type TUploadInputProps = {
  intl: any;
  onChange: (id: string, val: File) => void;
  onBlur: (id: string, val: string) => void;
  value: File | null;
  id: string;
  label: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
  acceptedFileType?: string;
};

const UploadInputBase: React.FunctionComponent<TUploadInputProps> = (props) => {
  const {
    id,
    value,
    onChange,
    onBlur,
    touched,
    showError,
    label,
    intl,
    style,
    disabled = false,
    isRequiredField,
    acceptedFileType,
  } = props;
  console.log('id', id);
  return (
    <FormControl variant="outlined" style={style} error={!!(showError && props.errors.length)}>
      <Button variant="contained" color="primary" component="label">
        {intl.formatMessage({ id: label })}
        <input
          accept={acceptedFileType}
          id={id}
          hidden
          type="file"
          onChange={(event) => {
            const file = event.target.files[0];
            onChange(id, file);
          }}
        />
      </Button>
      <Typography className={moduleStyle.helperText}>{value && value.name}</Typography>
      <div className={styles.errorCenter}>
        {props.errors.map((error) => (
          <span key={error}>{intl.formatMessage({ id: error })}</span>
        ))}
      </div>
    </FormControl>
  );
};

export const UploadInput = injectIntl(observer(UploadInputBase));
