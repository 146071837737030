export function getEnv(environmentVariableName: string) {
  const env = (window as any)._env_;
  if (!env) {
    console.warn('_env_ is not defined, will be using the dev. cloud defaults');
    return '';
  }
  const value = env[environmentVariableName];
  //console.info(`Environment '${environmentVariableName}' = '${value}'`);
  if (!value) {
    console.error(`Environment '${environmentVariableName}' NOT FOUND`);
  }
  return value as string || '';
}

export const version = process.env.VERSION || 'xxx';
console.log('config:version', version);
export const baseDomain = getEnv('BASE_DOMAIN');
export const apiPath = getEnv('KVINTA_API_URL');
const keycloakUrl = getEnv("KVINTA_ID_URL")
const keycloakRealm = getEnv("KVINTA_ID_REALM")
const keycloakClientId = getEnv("KVINTA_ID_CLIENT_ID")
export const temporalUiUrl = getEnv("TEMPORAL_UI_URL")


export const keycloakConfig = {
  realm: keycloakRealm,
  url: keycloakUrl,
  clientId: keycloakClientId,
};

export const KEYCLOAK_INIT_CONFIG = {
  pkceMethod: 'S256',
  checkLoginIframe: false,
  onLoad: 'check-sso',
  enableLogging: true,
};

export const cookieName = 'kvinta_auth';
export const localStorageAuthKey = 'AUTH';

export const defaultDateTimeFormatting = 'dd.MM.yyyy HH:mm';
export const defaultDateFormatting = 'dd.MM.yyyy';

export const build = 'app-kvinta-dev';
export const availableLanguages = ['en', 'ru'];
