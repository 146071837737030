/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaBlackHours,
    KvintaBlackHoursFromJSON,
    KvintaBlackHoursToJSON,
    KvintaOperationResponseBlackHours,
    KvintaOperationResponseBlackHoursFromJSON,
    KvintaOperationResponseBlackHoursToJSON,
    KvintaOperationResponseUnit,
    KvintaOperationResponseUnitFromJSON,
    KvintaOperationResponseUnitToJSON,
} from '../models';

export interface SaveRequest {
    kvintaBlackHours: KvintaBlackHours;
}

/**
 * 
 */
export class ConfApi extends runtime.BaseAPI {

    /**
     * Get black hours cron expression
     * Get black hours cron expression
     */
    async getBlackHoursRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseBlackHours>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appconf/blackhours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseBlackHoursFromJSON(jsonValue));
    }

    /**
     * Get black hours cron expression
     * Get black hours cron expression
     */
    async getBlackHours(): Promise<KvintaOperationResponseBlackHours> {
        const response = await this.getBlackHoursRaw();
        return await response.value();
    }

    /**
     * Update black hours (HH:mm)
     * Update black hours (HH:mm)
     */
    async saveRaw(requestParameters: SaveRequest): Promise<runtime.ApiResponse<KvintaOperationResponseUnit>> {
        if (requestParameters.kvintaBlackHours === null || requestParameters.kvintaBlackHours === undefined) {
            throw new runtime.RequiredError('kvintaBlackHours','Required parameter requestParameters.kvintaBlackHours was null or undefined when calling save.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appconf/blackhours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaBlackHoursToJSON(requestParameters.kvintaBlackHours),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseUnitFromJSON(jsonValue));
    }

    /**
     * Update black hours (HH:mm)
     * Update black hours (HH:mm)
     */
    async save(requestParameters: SaveRequest): Promise<KvintaOperationResponseUnit> {
        const response = await this.saveRaw(requestParameters);
        return await response.value();
    }

}
