import { action, makeObservable, observable } from 'mobx';
import { History } from 'history';
import { temporalUiUrl } from '../../config';

export enum EPaths {
  LogsExternal = 'https://temporal.dev.kvinta.kvinta.io/namespaces/itgtt/workflows/',
  //LogsExternal = temporalUiUrl,
  MainPath = '/',
  TasksPath = '/tasks',
  TaskPath = '/task/:id',
  TaskProductListPath = '/task/:taskId/products',
  TaskResultListPath = '/task/:taskId/results',
  TaskLogsListPath = '/task/:taskId/logs',
  TaskProductPath = '/task/:taskId/products/:productId',
  NewTaskPath = '/tasks/new',
  NewTaskProductPath = '/task/:taskId/products/new',
  LogsPath = '/logs',
  LocationListPath = '/configuration/locations',
  NewLocationPath = '/configuration/locations/new',
  LocationPath = '/configuration/location/:id',
  CodeFormatsPath = '/configuration/code-formats',
  CodeFormatPath = '/configuration/code-format/:id',
  NewCodeFormatPath = '/configuration/code-formats/new',
  InactivityTimePath = '/configuration/inactivity-time',
  CodePoolPath = '/configuration/code-pool',
  ProductListPath = '/configuration/products',
  ProductPath = '/configuration/product/:id',
  NewProductPath = '/configuration/products/new',
  NotFoundPath = '*',
}

export class HistoryStore {
  private _history: History<unknown>;
  private _currentRoute;

  constructor(history) {
    this._history = history;

    makeObservable(this, {
      navigateNewLocationPath: action.bound,
      navigateLocationSummaryPath: action.bound,
      navigateLocationListPath: action.bound,
      navigateCodeFormatSummaryPath: action.bound,
      navigateNewCodeFormatPath: action.bound,
      navigateCodeFormatListPath: action.bound,
      navigateTaskListPath: action.bound,
      navigateNewTaskPath: action.bound,
      navigateTaskSummaryPath: action.bound,
      navigateTaskProductListPath: action.bound,
      navigateTaskProductPath: action.bound,
      navigateNewTaskProductPath: action.bound,
      navigateProductListPath: action.bound,
      navigateProductSummaryPath: action.bound,
      navigateNewProductPath: action.bound,

      saveCurrentRoute: action.bound,
    });
  }

  saveCurrentRoute = (route) => {
    this._currentRoute = route;
  };

  getCurrentRoute = () => {
    return this._currentRoute;
  };

  get history() {
    return this._history;
  }

  navigateNewLocationPath() {
    this._history.push(EPaths.NewLocationPath);
  }

  navigateLocationSummaryPath(id: string) {
    this._history.push(EPaths.LocationPath.replaceAll(':id', id));
  }

  navigateLocationListPath() {
    this._history.push(EPaths.LocationListPath);
  }

  navigateCodeFormatSummaryPath(id: string) {
    this._history.push(EPaths.CodeFormatPath.replaceAll(':id', id));
  }

  navigateNewCodeFormatPath() {
    this._history.push(EPaths.NewCodeFormatPath);
  }

  navigateCodeFormatListPath() {
    this._history.push(EPaths.CodeFormatsPath);
  }

  navigateTaskListPath() {
    this._history.push(EPaths.TasksPath);
  }

  navigateNewTaskPath() {
    this._history.push(EPaths.NewTaskPath);
  }

  navigateTaskSummaryPath(id: string) {
    this._history.push(EPaths.TaskPath.replaceAll(':id', id));
  }

  navigateTaskProductListPath(taskId: string) {
    this._history.push(EPaths.TaskProductListPath.replaceAll(':taskId', taskId));
  }

  navigateTaskResultListPath(taskId: string) {
    this._history.push(EPaths.TaskResultListPath.replaceAll(':taskId', taskId));
  }
  navigateTaskLogsListPath(taskId: string) {
    this._history.push(EPaths.TaskLogsListPath.replaceAll(':taskId', taskId));
  }

  navigateTaskProductPath(taskId: string, productId: string) {
    this._history.push(EPaths.TaskProductPath.replaceAll(':taskId', taskId).replaceAll(':productId', productId));
  }

  navigateNewTaskProductPath(taskId: string) {
    this._history.push(EPaths.NewTaskProductPath.replaceAll(':taskId', taskId));
  }

  navigateProductListPath() {
    this._history.push(EPaths.ProductListPath);
  }

  navigateProductSummaryPath(productId: string) {
    this._history.push(EPaths.ProductPath.replaceAll(':id', productId));
  }

  navigateNewProductPath() {
    this._history.push(EPaths.NewProductPath);
  }
}

export const STORE_ID = 'historyStore';
