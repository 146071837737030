import { Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { CodeFormatsStore, STORE_ID } from './CodeFormatsStore';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from '../../../../assets/css/partials/modules/CodeFormats.module.scss';
import { CodeFormatSummaryView } from './CodeFormatSummaryView';
import { CodeFormatSummaryForm } from './CodeFormatSummaryForm';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';

interface CodeFormatSummaryProps {
  historyStore: HistoryStore;
  intl: IntlShape;
  codeFormatsStore?: CodeFormatsStore;
  sidebarStore?: KSidebarStore;
  id: string;
}

class CodeFormatSummaryImpl extends Component<CodeFormatSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.codeFormatsStore.fetchCodeFormatById(this.props.id);
    this.disposer = reaction(
      () => this.props.codeFormatsStore.currentCodeFormat,
      (comp) => {
        if (comp) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateCodeFormatListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.codeFormatsStore.closeUpdateCodeFormatForm();
    this.disposer();
  }

  handleEditCancel = () => {
    this.props.codeFormatsStore.closeUpdateCodeFormatForm();
    this.props.codeFormatsStore.fetchCodeFormatById(this.props.id);
  };

  render() {
    const intl = this.props.intl;
    const {
      currentCodeFormat,
      sampleCodes,
      updateCodeFormatData,
      updateCodeFormatFormOpen,
      isLoading,
      handleFormBlur,
      handleFormChange,
      addMember,
      removeMember,
      generateSampleCodeForView,
      generateSampleCodeForForm,
    } = this.props.codeFormatsStore;

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<unknown>, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateCodeFormatListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(updateCodeFormatData)}
            formIsOpen={updateCodeFormatFormOpen}
            onCancel={this.handleEditCancel}
            onConfirm={this.props.codeFormatsStore.submitUpdateCodeFormat}
            onOpen={this.props.codeFormatsStore.openUpdateCodeFormatForm}
          />
          {updateCodeFormatFormOpen
            ? updateCodeFormatData &&
              sampleCodes && (
                <CodeFormatSummaryForm
                  intl={intl}
                  onBlur={handleFormBlur}
                  onChange={handleFormChange}
                  formData={updateCodeFormatData}
                  sampleCodes={sampleCodes}
                  addMember={addMember}
                  removeMember={removeMember}
                  generateSampleCodes={generateSampleCodeForForm}
                />
              )
            : currentCodeFormat &&
              sampleCodes && (
                <CodeFormatSummaryView
                  generateSampleCodes={generateSampleCodeForView}
                  formData={currentCodeFormat}
                  sampleCodes={sampleCodes}
                  intl={intl}
                />
              )}
        </div>
      </div>
    );
  }
}

export const CodeFormatSummary = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(CodeFormatSummaryImpl)),
);
