import {
  Assignment,
  AvTimerOutlined,
  CloudUploadOutlined,
  CodeSharp,
  HomeOutlined,
  ListOutlined,
  LocationOn,
  SvgIconComponent,
  Timelapse
} from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import {EPaths,} from './kvinta/common/HistoryStore';
import {temporalUiUrl} from "./config";


export enum EMenuItemType {
  DIVIDER = "divider",
  PARENT_ITEM = "parentItem",
  CHILD_ITEM = "childItem",
  EXTERNAL_ITEM="externalItem"
}

type TCommonPropertyItems = {
  label: string;
  Icon: SvgIconComponent | null
  isClickable: boolean;
}
type TChildItemProperty = {
  selectOnPaths: EPaths[];
  path: EPaths;
}

export type TDividerMainMenu = { type: EMenuItemType.DIVIDER }
export type TChildItemMainMenu = { type: EMenuItemType.CHILD_ITEM | EMenuItemType.EXTERNAL_ITEM} & TCommonPropertyItems & TChildItemProperty
export type TParentItemMainMenu = { type: EMenuItemType.PARENT_ITEM } & TCommonPropertyItems & { items: TItemMenu[] }


export type TItemMenu = TDividerMainMenu | TChildItemMainMenu | TParentItemMainMenu

export const MainMenu: TItemMenu[] = [
  {
    type: EMenuItemType.CHILD_ITEM,
    path: EPaths.MainPath,
    label: 'menu.home',
    Icon: HomeOutlined,
    isClickable: true,
    selectOnPaths: [EPaths.MainPath],
  },
  {
    type: EMenuItemType.DIVIDER,
  },
  {
    type: EMenuItemType.CHILD_ITEM,
    path: EPaths.TasksPath,
    label: 'menu.tasks',
    Icon: Assignment,
    isClickable: true,
    selectOnPaths: [EPaths.TasksPath, EPaths.NewTaskPath, EPaths.TaskPath,
      EPaths.TaskProductListPath, EPaths.NewTaskProductPath, EPaths.TaskProductPath],
  },
  {
    type: EMenuItemType.DIVIDER,
  },
  {
    type: EMenuItemType.EXTERNAL_ITEM,
    path: temporalUiUrl as EPaths,
    label: 'menu.logs',
    Icon: AvTimerOutlined,
    isClickable: true,
    selectOnPaths: [EPaths.LogsExternal],
  },
  {
    type: EMenuItemType.DIVIDER,
  },
  {
    type: EMenuItemType.PARENT_ITEM,
    label: 'menu.configuration',
    Icon: ListOutlined,
    isClickable: false,
    items: [
      {
        type: EMenuItemType.CHILD_ITEM,
        path: EPaths.LocationListPath,
        label: 'menu.locations',
        Icon: LocationOn,
        isClickable: true,
        selectOnPaths: [EPaths.LocationListPath, EPaths.NewLocationPath, EPaths.LocationPath],
      },
      {
        type: EMenuItemType.CHILD_ITEM,
        path: EPaths.CodeFormatsPath,
        label: 'menu.code-formats',
        Icon: CodeSharp,
        isClickable: true,
        selectOnPaths: [EPaths.CodeFormatsPath, EPaths.CodeFormatPath, EPaths.NewCodeFormatPath],
      },
      {
        type: EMenuItemType.CHILD_ITEM,
        path: EPaths.ProductListPath,
        label: 'menu.products',
        Icon: CategoryIcon,
        isClickable: true,
        selectOnPaths: [EPaths.ProductListPath, EPaths.ProductPath, EPaths.NewProductPath],
      },
      {
        type: EMenuItemType.CHILD_ITEM,
        path: EPaths.CodePoolPath,
        label: 'menu.code-pool',
        Icon: CloudUploadOutlined,
        isClickable: true,
        selectOnPaths: [EPaths.CodePoolPath],
      },
      {
        type: EMenuItemType.CHILD_ITEM,
        path: EPaths.InactivityTimePath,
        label: 'menu.inactivity-time',
        Icon: Timelapse,
        isClickable: true,
        selectOnPaths: [EPaths.InactivityTimePath],
      },
    ],
  },
];

