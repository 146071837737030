import { Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { IEditState } from 'kvinta/common';
import { LocationStore, STORE_ID } from './LocationStore';
import { LocationSummaryForm } from './LocationSummaryForm';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from '../../../../assets/css/partials/modules/Locations.module.scss';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';

interface ViewLocationSummaryProps {
  intl: any;
  locationsStore?: LocationStore;
  sidebarStore?: KSidebarStore;
  id: string;
  historyStore: HistoryStore;
}

class NewLocationImpl extends Component<ViewLocationSummaryProps, IEditState> {
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.openCreateLocationForm();
  }

  componentWillUnmount() {
    this.props.locationsStore.closeUpdateLocationForm();
  }

  render() {
    const { locationFormData, onChangeLocationFormField, onBlurLocationFormField, cancelCreateLocationForm } =
      this.props.locationsStore;
    const isLoading = this.props.locationsStore.isLoading || !locationFormData;
    const intl = this.props.intl;

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<unknown>, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateLocationListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(locationFormData)}
            formIsOpen={true}
            onCancel={this.props.locationsStore.cancelCreateLocationForm}
            onConfirm={this.props.locationsStore.submitCreateLocation}
            onOpen={() => {
              return null;
            }}
          />
          <LocationSummaryForm
            formData={locationFormData}
            onChange={onChangeLocationFormField}
            onBlur={onBlurLocationFormField}
          />
        </div>
      </div>
    );
  }
}

export const NewLocation = injectIntl(inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(NewLocationImpl)));
