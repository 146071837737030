/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSsccExecutionLogStatusResponse {
    Completed = 'COMPLETED',
    InProgress = 'IN_PROGRESS',
    Error = 'ERROR'
}

export function KvintaSsccExecutionLogStatusResponseFromJSON(json: any): KvintaSsccExecutionLogStatusResponse {
    return KvintaSsccExecutionLogStatusResponseFromJSONTyped(json, false);
}

export function KvintaSsccExecutionLogStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSsccExecutionLogStatusResponse {
    return json as KvintaSsccExecutionLogStatusResponse;
}

export function KvintaSsccExecutionLogStatusResponseToJSON(value?: KvintaSsccExecutionLogStatusResponse | null): any {
    return value as any;
}

