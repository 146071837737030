import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import React, { FC } from 'react';
import { Tab, Tabs } from '@mui/material';
import { TTaskData } from './TaskProductStore';

export enum EHistoryFunctionName {
  SummaryPath = 'navigateTaskSummaryPath',
  ProductListPath = 'navigateTaskProductListPath',
  ResultListPath = 'navigateTaskResultListPath',
  LogsListPath = 'navigateTaskLogsListPath',
}

interface ITaskHeaderTabsImpl {
  historyStore?: HistoryStore;
  intl: any;
  taskId: string;
  currentTask: TTaskData | null;
  initialValue: EHistoryFunctionName;
}
const TaskHeaderTabsImpl: FC<ITaskHeaderTabsImpl> = ({ historyStore, intl, taskId, currentTask, initialValue }) => {
  return (
    <Tabs
      value={initialValue}
      indicatorColor="secondary"
      textColor="primary"
      onChange={(event, newValue: EHistoryFunctionName) => {
        if (newValue === EHistoryFunctionName.SummaryPath) {
          historyStore.navigateTaskSummaryPath(taskId);
        } else if (newValue === EHistoryFunctionName.ResultListPath) {
          historyStore.navigateTaskResultListPath(taskId);
        } else if (newValue === EHistoryFunctionName.ProductListPath) {
          historyStore.navigateTaskProductListPath(taskId);
        } else if (newValue === EHistoryFunctionName.LogsListPath) {
          historyStore.navigateTaskLogsListPath(taskId);
        }
      }}
      aria-label="simple tabs example"
    >
      <Tab
        label={intl.formatMessage({ id: 'common.summary' })}
        id={EHistoryFunctionName.SummaryPath}
        value={EHistoryFunctionName.SummaryPath}
      />
      <Tab
        label={intl.formatMessage({ id: 'task.tabs.product' })}
        id={EHistoryFunctionName.ProductListPath}
        value={EHistoryFunctionName.ProductListPath}
      />
      <Tab
        label={intl.formatMessage({ id: 'task.tabs.results' })}
        value={EHistoryFunctionName.ResultListPath}
        id={EHistoryFunctionName.ResultListPath}
      />
      <Tab
        label={intl.formatMessage({ id: 'task.tabs.logs' })}
        value={EHistoryFunctionName.LogsListPath}
        id={EHistoryFunctionName.LogsListPath}
      />
    </Tabs>
  );
};

export const TaskHeaderTabs = injectIntl(inject(HISTORY_STORE_ID)(observer(TaskHeaderTabsImpl)));
