import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { CodePoolStore, ICodePoolRow, STORE_ID as CODE_POOL_STORE_ID } from './codePoolStore';
import { KSidebarStore, SIDEBAR_STORE_ID } from '../../../components/Sidebar';
import { PAGE_CONTENT_STORE_ID, PageContentStore } from '../../../components/PageContent';
import { Button, Checkbox, Menu, MenuItem, Paper } from '@mui/material';
import CloudUpload from '@mui/icons-material/CloudUpload';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { ITableActionsStateGeneric } from '../../../common';
import { tableIcons } from '../../../components';
import MaterialTable from 'material-table';
import styles from '../../../../assets/css/partials/modules/CodePool.module.scss';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { PROMPT_POPUP_STORE_ID, PromptPopupStore } from '../../../components/PromptPopup/PromptPopupStore';
import { GtinListPopupBody } from '../../../components/PromptPopup/GtinListPopupBody';
import { CreateCsvSummaryForm } from './CreateCsvSummaryForm';

interface ICodePoolProps {
  historyStore?: HistoryStore;
  sidebarStore?: KSidebarStore;
  pageContentStore?: PageContentStore;
  codePoolStore?: CodePoolStore;
  promptPopupStore?: PromptPopupStore;
  intl: IntlShape;
}

type ITableActionsState = ITableActionsStateGeneric<ICodePoolRow | null>;

class CodePoolImpl extends Component<ICodePoolProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ICodePoolProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    } as ITableActionsState;
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.codePoolStore.fetchIdPoolList();
    this.props.sidebarStore.clearMainTitle();
  }

  render(): React.ReactNode {
    const intl = this.props.intl;
    const { listData, isLoading, checked, isUploadCsvFormOpen } = this.props.codePoolStore;

    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    return (
      <>
        <Paper square>
          {isLoading && <LoadingBackground />}
          <div className={styles.headerButtonContainer}>
            <Button disabled variant="contained" color="secondary" startIcon={<DeleteOutline color="secondary" />}>
              {intl.formatMessage({ id: 'codePool.list.remove-button' })}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloudUpload color="secondary" />}
              onClick={(e) => {
                this.props.codePoolStore.openUploadCsvForm();
              }}
            >
              {intl.formatMessage({ id: 'codePool.create-form.field.button-upload' })}
            </Button>
          </div>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: (
                  <Checkbox
                    id={'check-all'}
                    checked={checked > 0}
                    onChange={() => {
                      this.props.codePoolStore.toggleAll();
                    }}
                    checkedIcon={checked == 2 ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                  />
                ),
                field: 'isSelected',
                sorting: false,
                width: null,
                cellStyle: { width: 60, fontSize: null },
                render: (rowData: any) => (
                  <Checkbox
                    id={rowData.id}
                    checked={selected[rowData.id]}
                    onChange={() => {
                      this.props.codePoolStore.toggleRow(rowData.id);
                    }}
                  />
                ),
              },
              {
                title: intl.formatMessage({ id: 'codePool.list.field.gtin' }),
                field: 'gtin',
                defaultSort: 'desc',
                width: null,
                cellStyle: { minWidth: 160, fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'codePool.list.field.available' }),
                field: 'available',
                defaultSort: 'desc',
                width: '60px',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'codePool.list.field.reserved' }),
                field: 'reserved',
                defaultSort: 'desc',
                width: '60px',
                cellStyle: { fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'codePool.list.field.used' }),
                field: 'used',
                defaultSort: 'desc',
                width: 'auto',
                cellStyle: { fontSize: null },
              },
            ]}
            data={listData}
            options={{
              rowStyle: { wordBreak: 'break-word' },
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              return;
            }}
            actions={[
              {
                icon: () => <MoreVertIcon />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: (props) => <></>,
            }}
          />
        </Paper>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              this.props.promptPopupStore.createPopup({
                title: intl.formatMessage({ id: 'codePool.list.delete-all-button-message' }),
                children: <GtinListPopupBody listNames={[this.state.rowData]} />,
                onHandlerApply: () => {
                  this.props.codePoolStore.submitDeleteIdPool(this.state.rowData.id);
                  this.onMenuClose();
                },
                onHandlerReject: () => {
                  this.onMenuClose();
                },
              });
            }}
          >
            <DeleteOutline color="primary" />
            {intl.formatMessage({ id: 'codePool.list.delete-all-button' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.props.promptPopupStore.createPopup({
                title: intl.formatMessage({ id: 'codePool.list.delete-used-button-message' }),
                children: <GtinListPopupBody listNames={[this.state.rowData]} />,
                onHandlerApply: () => {
                  this.props.codePoolStore.submitClearPool(this.state.rowData.id);
                  this.onMenuClose();
                },
                onHandlerReject: () => {
                  this.onMenuClose();
                },
              });
            }}
          >
            <DeleteOutline color="primary" />
            {intl.formatMessage({ id: 'codePool.list.delete-used-button' })}
          </MenuItem>
        </Menu>
        {isUploadCsvFormOpen && <CreateCsvSummaryForm />}
      </>
    );
  }
}

export const CodePoolList = injectIntl(
  inject(
    CODE_POOL_STORE_ID,
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
    PROMPT_POPUP_STORE_ID,
  )(observer(CodePoolImpl)),
);
