import { controlDigitIsValid } from './utils';
import { KvintaLocationCodeSpecifics } from '../../apis/kvinta-load-test-toolkit';

const regExpForTpId = RegExp(/^[0-9\-]*$/);
const regExpForTpIdExactly = RegExp(/^[0-9]{5}-[0-9]{2}-[0-9]{5}/);

const regExpForInteger = RegExp(/^[0-9]*$/);

export function isNumber(options: { errorMessage: string }) {
  return function (id, data) {
    return Number.isFinite(parseFloat(data[id].value)) ? undefined : options.errorMessage;
  };
}

export function isInteger(options: { errorMessage: string }) {
  return function (id, data) {
    return regExpForInteger.test(data[id].value) ? null : options.errorMessage;
  };
}
export function isIntegerForTpId(options: { errorMessage: string }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return regExpForInteger.test(data[id].value) ? null : options.errorMessage;
  };
}

export function tpidIsCorrect(options: { errorMessage: string }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return regExpForTpId.test(data[id].value) ? null : options.errorMessage;
  };
}
export function tpIdIsExactly(options: { errorMessage: string }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return regExpForTpIdExactly.test(data[id].value) ? null : options.errorMessage;
  };
}

export function isGreaterThan(options: { errorMessage: string; greaterThan: number }) {
  return function (id, data) {
    return parseFloat(data[id].value) > options.greaterThan ? undefined : options.errorMessage;
  };
}

export function isLessThan(options: { errorMessage: string; lessThan: number }) {
  return function (id, data) {
    return parseFloat(data[id].value) < options.lessThan ? undefined : options.errorMessage;
  };
}

export function isGeoCoordinate(options: { errorMessage: string }) {
  return function (id, data) {
    const isValidNumber = !Boolean(isNumber(options)(id, data));
    // todo: now we're only checking for general format validity.
    // Need to check for validity as a geo coordinate (eg max 180degrees in the integer part etc).

    return isValidNumber && new RegExp(/^[\+\-]?\d+(\.\d+)?$/).test(data[id].value) ? undefined : options.errorMessage;
  };
}

export function isValidGln13(options: { errorMessage: string }) {
  return function (id, data) {
    const value = data[id].value.trim();
    return new RegExp(/^\d{13}$/).test(value) && controlDigitIsValid(value) ? undefined : options.errorMessage;
  };
}

export function hasMinMembers(options: { errorMessage: string; atLeast: number }) {
  return function (id, data) {
    return data[id].children.length >= options.atLeast ? undefined : options.errorMessage;
  };
}

export function childrenAreValid(options: { errorMessage: string }) {
  return function (id, data) {
    let childHasError = false;

    for (const childId of data[id].children) {
      if (data[childId].errors.length !== 0) {
        childHasError = true;
        break;
      }
    }

    return childHasError ? options.errorMessage : undefined;
  };
}

export function isNotEmpty(options: { errorMessage: string }) {
  return function (id, data) {
    return data[id].value.trim() !== '' ? undefined : options.errorMessage;
  };
}
export function isNotEmptyForTpId(options: { errorMessage: string }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return data[id].value.trim() !== '' ? undefined : options.errorMessage;
  };
}

export function maxLength(options: { errorMessage: string; maxLength: number }) {
  return function (id, data) {
    return data[id].value.trim().length <= options.maxLength ? undefined : options.errorMessage;
  };
}
export function maxLengthForTpId(options: { errorMessage: string; maxLength: number }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return data[id].value.trim().length <= options.maxLength ? undefined : options.errorMessage;
  };
}

export function minLength(options: { errorMessage: string; minLength: number }) {
  return function (id, data) {
    return data[id].value.trim().length >= options.minLength ? undefined : options.errorMessage;
  };
}
export function minLengthForTpId(options: { errorMessage: string; minLength: number }) {
  return function (id, data) {
    if (checkOnCodentify(data)) {
      return undefined;
    }
    return data[id].value.trim().length >= options.minLength ? undefined : options.errorMessage;
  };
}
export function hasLength(options: { errorMessage: string; requiredLength: number }) {
  return function (id, data) {
    return data[id].value.trim().length !== options.requiredLength ? options.errorMessage : null;
  };
}

export function isValidEmailAddress(options: { errorMessage: string }) {
  return function (id, data) {
    const localAndDomain = data[id].value.trim().split('@');

    if (localAndDomain.length === 2) {
      const [localPart, domainPart] = localAndDomain;

      const localPartIsValid = new RegExp(/^\w+(\.?[\w\-\+]+)*$/).test(localPart);
      const domainPartIsValid = new RegExp(/^(\w+)(\.\w+)*(\.\w{2,})$/).test(domainPart);

      return localPartIsValid && domainPartIsValid ? undefined : options.errorMessage;
    } else {
      return options.errorMessage;
    }
  };
}

function checkOnCodentify(data: any) {
  if (
    data['productFormRoot.codeSpecifics'] &&
    data['productFormRoot.codeSpecifics'].value === KvintaLocationCodeSpecifics.Codentify
  ) {
    return true;
  } else return false;
}
