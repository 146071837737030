import React, { FC } from 'react';
import { TLocationFormData } from './LocationStore';
import styles from '../../../../assets/css/partials/modules/Locations.module.scss';
import { TextInput } from '../../../components/FormFields/Fields_Exp/TextInput_Exp';
import { SelectInput } from '../../../components/FormFields/Fields_Exp/SelectInput_Exp';
import { KvintaLocationCodeSpecifics } from '../../../apis/kvinta-load-test-toolkit';

interface ILocationSummaryForm {
  onChange: (id: string, value: any) => void;
  onBlur: (id: string, value: any) => void;
  formData: TLocationFormData;
}

export const LocationSummaryForm: FC<ILocationSummaryForm> = ({ formData, onChange, onBlur }) => {
  if (!formData) return null;
  const hide = formData['updateLocationForm.codeSpecifics'].value === KvintaLocationCodeSpecifics.Codentify;
  return (
    <div className={styles.formContainer}>
      <TextInput
        {...formData['updateLocationForm.name']}
        label="location-create-form.field.name"
        onBlur={onBlur}
        onChange={onChange}
      />
      <TextInput
        {...formData['updateLocationForm.locationId']}
        label="location-create-form.field.locationId"
        onBlur={onBlur}
        onChange={onChange}
      />
      <TextInput
        {...formData['updateLocationForm.facilityId']}
        hide={hide}
        label="location-create-form.field.facilityId"
        onBlur={onBlur}
        onChange={onChange}
      />
      <TextInput
        {...formData['updateLocationForm.eoId']}
        hide={hide}
        label="location-create-form.field.eoId"
        onBlur={onBlur}
        onChange={onChange}
      />
      <TextInput
        {...formData['updateLocationForm.readPoint']}
        label="location-create-form.field.read-point"
        onBlur={onBlur}
        onChange={onChange}
      />
      <TextInput
        {...formData['updateLocationForm.machineId']}
        hide={hide}
        label="location-create-form.field.machineId"
        onBlur={onBlur}
        onChange={onChange}
      />
      <SelectInput
        {...formData['updateLocationForm.codeSpecifics']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-create-form.field.codeSpecifics"
      />
    </div>
  );
};
