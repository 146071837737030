/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEndMarket,
    KvintaEndMarketFromJSON,
    KvintaEndMarketFromJSONTyped,
    KvintaEndMarketToJSON,
    KvintaTaskDefinitionProductCreateUpdateRequest,
    KvintaTaskDefinitionProductCreateUpdateRequestFromJSON,
    KvintaTaskDefinitionProductCreateUpdateRequestFromJSONTyped,
    KvintaTaskDefinitionProductCreateUpdateRequestToJSON,
    KvintaTaskPriority,
    KvintaTaskPriorityFromJSON,
    KvintaTaskPriorityFromJSONTyped,
    KvintaTaskPriorityToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTaskDefinitionCreateUpdateRequest
 */
export interface KvintaTaskDefinitionCreateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionCreateUpdateRequest
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinitionCreateUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {KvintaEndMarket}
     * @memberof KvintaTaskDefinitionCreateUpdateRequest
     */
    endMarket?: KvintaEndMarket | null;
    /**
     * 
     * @type {KvintaTaskPriority}
     * @memberof KvintaTaskDefinitionCreateUpdateRequest
     */
    priority?: KvintaTaskPriority | null;
    /**
     * 
     * @type {Array<KvintaTaskDefinitionProductCreateUpdateRequest>}
     * @memberof KvintaTaskDefinitionCreateUpdateRequest
     */
    products: Array<KvintaTaskDefinitionProductCreateUpdateRequest>;
}

export function KvintaTaskDefinitionCreateUpdateRequestFromJSON(json: any): KvintaTaskDefinitionCreateUpdateRequest {
    return KvintaTaskDefinitionCreateUpdateRequestFromJSONTyped(json, false);
}

export function KvintaTaskDefinitionCreateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskDefinitionCreateUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'endMarket': !exists(json, 'endMarket') ? undefined : KvintaEndMarketFromJSON(json['endMarket']),
        'priority': !exists(json, 'priority') ? undefined : KvintaTaskPriorityFromJSON(json['priority']),
        'products': ((json['products'] as Array<any>).map(KvintaTaskDefinitionProductCreateUpdateRequestFromJSON)),
    };
}

export function KvintaTaskDefinitionCreateUpdateRequestToJSON(value?: KvintaTaskDefinitionCreateUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'endMarket': KvintaEndMarketToJSON(value.endMarket),
        'priority': KvintaTaskPriorityToJSON(value.priority),
        'products': ((value.products as Array<any>).map(KvintaTaskDefinitionProductCreateUpdateRequestToJSON)),
    };
}


