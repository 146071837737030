/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaLocationActionRequest
 */
export interface KvintaLocationActionRequest {
    /**
     * Location identifier
     * @type {string}
     * @memberof KvintaLocationActionRequest
     */
    locationId: string;
}

export function KvintaLocationActionRequestFromJSON(json: any): KvintaLocationActionRequest {
    return KvintaLocationActionRequestFromJSONTyped(json, false);
}

export function KvintaLocationActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocationActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
    };
}

export function KvintaLocationActionRequestToJSON(value?: KvintaLocationActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
    };
}


