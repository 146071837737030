import React, { FC } from 'react';
import { TextInput } from '../../../components/FormFields/Fields_Exp/TextInput_Exp';
import styles from '../../../../assets/css/partials/modules/CodeFormats.module.scss';
import { SelectInput } from '../../../components/FormFields/Fields_Exp/SelectInput_Exp';
import { TCodeFormatFormData, transformCodeFormatFormData, TSampleCodes } from './CodeFormatsStore';
import {
  KvintaEpcCodeCreateRequest,
  KvintaEpcCodeType,
  KvintaEpcCodeVariableType,
} from '../../../apis/kvinta-load-test-toolkit/models';
import { Button } from '@mui/material';
import { DeleteButton } from '../../../components/IconButtons';
import { IntlShape } from 'react-intl';
import { toJS } from 'mobx';

interface ICode {
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  sampleCode: string;
  formData: TCodeFormatFormData;
  keyForData: string;
  label: string;
  intl: IntlShape;
}

const Code: FC<ICode> = ({ onChange, onBlur, sampleCode, formData, label, intl, keyForData }) => {
  return (
    <div>
      <TextInput {...formData[keyForData]} label={label} onBlur={onBlur} onChange={onChange} />
      {sampleCode && (
        <div className={styles.codeNodeHelpText}>
          {intl.formatMessage({ id: label })}: {sampleCode}
        </div>
      )}
    </div>
  );
};

type TSampleCode = {
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  sampleCodes: TSampleCodes;
  formData: TCodeFormatFormData;
  intl: IntlShape;
};
const SampleCode: FC<TSampleCode> = ({ onChange, onBlur, sampleCodes, formData, intl }) => {
  switch (formData['codeFormatForm.codeFormatType'].value) {
    case KvintaEpcCodeType.Pk: {
      const sampleShortCode = sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.sampleShortCode : '';
      const samplePrimaryCode =
        sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.samplePrimaryCode : '';
      const sampleShortPrimaryCode =
        sampleCodes.codeFormatType === KvintaEpcCodeType.Pk ? sampleCodes.sampleShortPrimaryCode : '';
      return (
        <div className={styles.formContainerSection}>
          <Code
            keyForData="codeFormatForm.codeFormatType.longCode"
            onChange={onChange}
            onBlur={onBlur}
            sampleCode={sampleCodes.sampleLongCode}
            formData={formData}
            intl={intl}
            label={'code-format.create-form.field.sample-code-format-long'}
          />
          <Code
            keyForData="codeFormatForm.codeFormatType.shortCode"
            onChange={onChange}
            onBlur={onBlur}
            sampleCode={sampleShortCode}
            formData={formData}
            intl={intl}
            label={'code-format.create-form.field.sample-code-format-short'}
          />
          <Code
            keyForData="codeFormatForm.codeFormatType.primaryCode"
            onChange={onChange}
            onBlur={onBlur}
            sampleCode={samplePrimaryCode}
            formData={formData}
            intl={intl}
            label={'code-format.create-form.field.sample-code-format-primary'}
          />
          <Code
            keyForData="codeFormatForm.codeFormatType.shortPrimaryCode"
            onChange={onChange}
            onBlur={onBlur}
            sampleCode={sampleShortPrimaryCode}
            formData={formData}
            intl={intl}
            label={'code-format.create-form.field.sample-code-format-shortPrimary'}
          />
        </div>
      );
    }
    case KvintaEpcCodeType.Sgtin:
    case KvintaEpcCodeType.Sscc:
      return (
        <div className={styles.formContainerSection}>
          <Code
            keyForData="codeFormatForm.codeFormatType.longCode"
            onChange={onChange}
            onBlur={onBlur}
            sampleCode={sampleCodes.sampleLongCode}
            formData={formData}
            intl={intl}
            label={'code-format.create-form.field.sample-code-format-long'}
          />
        </div>
      );
    default:
      return <div>Empty</div>;
  }
};

type TVariableData = {
  formData: TCodeFormatFormData;
  arrayMemberData: any;
  arrayMemberId: string;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
};
const VariableData: FC<TVariableData> = ({ formData, arrayMemberData, arrayMemberId, onChange, onBlur }) => {
  return (
    <>
      {arrayMemberData.value === KvintaEpcCodeVariableType.Constant && (
        <TextInput
          {...formData[`${arrayMemberId}.value`]}
          label="code-formats.create-form.field.value"
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {arrayMemberData.value === KvintaEpcCodeVariableType.UniqueSequence && (
        <>
          <SelectInput
            {...formData[`${arrayMemberId}.uniqueSequenceType`]}
            onChange={onChange}
            onBlur={onBlur}
            label="code-formats.create-form.field.unique-sequence-type"
          />
          <TextInput
            {...formData[`${arrayMemberId}.length`]}
            label="code-formats.create-form.field.length"
            onChange={onChange}
            onBlur={onBlur}
          />
        </>
      )}
    </>
  );
};

type TVariable = {
  formData: TCodeFormatFormData;
  arrayMemberData: any;
  arrayMemberId: string;
  removeMember: (memberId: string) => void;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
};
const Variable: FC<TVariable> = ({ formData, arrayMemberData, removeMember, arrayMemberId, onChange, onBlur }) => {
  return (
    <div className={styles.rowContainerSection}>
      <DeleteButton disabled={false} onClick={() => removeMember(arrayMemberId)} />
      <TextInput
        {...formData[`${arrayMemberId}.name`]}
        label="code-format.create-form.field.name"
        onChange={onChange}
        onBlur={onBlur}
      />
      <SelectInput
        {...formData[`${arrayMemberId}.variableType`]}
        onChange={onChange}
        onBlur={onBlur}
        label="code-formats.create-form.field.variable-type"
      />
      <VariableData
        onBlur={onBlur}
        onChange={onChange}
        formData={formData}
        arrayMemberData={arrayMemberData}
        arrayMemberId={arrayMemberId}
      />
    </div>
  );
};

type TCodeFormatSummaryFormBase = {
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  addMember: (parentId: string, place: number) => void;
  removeMember: (memberId: string) => void;
  sampleCodes: TSampleCodes;
  formData: TCodeFormatFormData;
  intl: IntlShape;
  generateSampleCodes: (codeFormatData: KvintaEpcCodeCreateRequest) => void;
};
const CodeFormatSummaryFormBase: FC<TCodeFormatSummaryFormBase> = ({
  generateSampleCodes,
  onChange,
  onBlur,
  sampleCodes,
  formData,
  intl,
  addMember,
  removeMember,
}) => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <TextInput
          {...formData['codeFormatForm.name']}
          label="code-format.create-form.field.name"
          onBlur={onBlur}
          onChange={onChange}
        />
        <SelectInput
          {...formData['codeFormatForm.codeFormatType.type']}
          onChange={onChange}
          onBlur={onBlur}
          label="code-format.create-form.field.type"
        />
      </div>
      <SampleCode onChange={onChange} onBlur={onBlur} sampleCodes={sampleCodes} formData={formData} intl={intl} />
      <div>
        <Button
          onClick={() => addMember('codeFormatForm.variables', formData['codeFormatForm.variables'].children.length)}
          color="primary"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'button.add-variable' })}
        </Button>
      </div>
      <div className={styles.formContainerSection}>
        {formData['codeFormatForm.variables'].children.map((arrayMemberId) => {
          const arrayMemberData = formData[arrayMemberId];
          return (
            <Variable
              key={arrayMemberId}
              onBlur={onBlur}
              onChange={onChange}
              formData={formData}
              removeMember={removeMember}
              arrayMemberData={arrayMemberData}
              arrayMemberId={arrayMemberId}
            />
          );
        })}
      </div>
      <div>
        <Button
          onClick={() => generateSampleCodes(transformCodeFormatFormData(formData))}
          color="primary"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'button.generate-sample-code' })}
        </Button>
      </div>
    </div>
  );
};

export const CodeFormatSummaryForm = CodeFormatSummaryFormBase;
