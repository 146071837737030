/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEndMarket {
    De = 'DE',
    Pl = 'PL',
    Uk = 'UK'
}

export function KvintaEndMarketFromJSON(json: any): KvintaEndMarket {
    return KvintaEndMarketFromJSONTyped(json, false);
}

export function KvintaEndMarketFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEndMarket {
    return json as KvintaEndMarket;
}

export function KvintaEndMarketToJSON(value?: KvintaEndMarket | null): any {
    return value as any;
}

