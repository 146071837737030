import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { CodeFormatsStore, STORE_ID } from './CodeFormatsStore';
import { KSidebarStore, SIDEBAR_STORE_ID } from '../../../components';
import React, { Component } from 'react';
import { IEditState } from '../../../common';
import { Tab, Tabs } from '@mui/material';
import { CodeFormatSummaryForm } from './CodeFormatSummaryForm';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from '../../../../assets/css/partials/modules/CodeFormats.module.scss';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';

interface NewCodeFormatProps {
  historyStore: HistoryStore;
  intl: any;
  codeFormatsStore?: CodeFormatsStore;
  sidebarStore?: KSidebarStore;
  id: string;
}

class NewCodeFormatImpl extends Component<NewCodeFormatProps, IEditState> {
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.codeFormatsStore.openCreateCodeFormatForm();
  }

  componentWillUnmount(): void {
    this.props.codeFormatsStore.closeUpdateCodeFormatForm();
  }

  render() {
    const intl = this.props.intl;
    const {
      sampleCodes,
      updateCodeFormatData,
      isLoading,
      handleFormBlur,
      handleFormChange,
      addMember,
      removeMember,
      generateSampleCodeForForm,
    } = this.props.codeFormatsStore;

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateCodeFormatListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(updateCodeFormatData)}
            formIsOpen={true}
            onCancel={this.props.codeFormatsStore.cancelCreateCodeFormat}
            onConfirm={this.props.codeFormatsStore.submitCreateCodeFormat}
            onOpen={() => {
              return null;
            }}
          />
          {updateCodeFormatData && sampleCodes && (
            <CodeFormatSummaryForm
              intl={intl}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              formData={updateCodeFormatData}
              sampleCodes={sampleCodes}
              addMember={addMember}
              removeMember={removeMember}
              generateSampleCodes={generateSampleCodeForForm}
            />
          )}
        </div>
      </div>
    );
  }
}

export const NewCodeFormat = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(NewCodeFormatImpl)),
);
