import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export function Loading() {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress />
    </Backdrop>
  );
}
