import React from 'react';
import Root from './Root';
import {IntlProvider} from 'react-intl';
import localeRu from 'kvinta/locales/ru-ru.json';
import localeEn from 'kvinta/locales/en-en.json';
import {inject, observer} from 'mobx-react';
import {History} from 'history';
import {HistoryStore, STORE_ID as HISTORY_STORE_ID} from './kvinta/common/HistoryStore';
import {AuthProvider} from "./kvinta/service/auth/AuthProvider";

const mergedTranslations = {
  en: {
    ...localeEn,
  },
  ru: {
    ...localeRu,
  },
};

interface AppProps {
  history: History<unknown>,
  historyStore?: HistoryStore;
}

class app extends React.Component<AppProps> {
  render() {
    const userInfo = {lang: 'en'}//this.props.userStore.userInfo;
    if (!userInfo) {
      return null;
    }
    const lang = userInfo.lang;
    return (
      <IntlProvider locale={lang} messages={mergedTranslations[lang]}>
        <AuthProvider>
          <Root history={this.props.history} historyStore={this.props.historyStore}/>
        </AuthProvider>
      </IntlProvider>
    );
  }
}

export const App = inject(HISTORY_STORE_ID)(observer(app));

