import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { injectIntl } from 'react-intl';

type TSelectInput = {
  intl: any;
  id: string;
  value: string | number;
  isEditing: boolean;
  onChange: (id: string, value: string) => void;
  options: { [key: string]: string };
  label?: string;
  showError: boolean;
  errorRequired: (id, data) => boolean;
  style?: { [key: string]: string };
};

const noOp = () => undefined;

const SelectInputBase: React.FunctionComponent<TSelectInput> = ({
  intl,
  id,
  label,
  value,
  isEditing,
  onChange,
  options,
  showError,
  errorRequired,
  style,
}) => (
  <FormControl variant="outlined" style={style}>
    {label && (
      <InputLabel style={{ backgroundColor: 'white', padding: '0 3px' }} htmlFor={id}>
        {intl.formatMessage({ id: label })}
      </InputLabel>
    )}

    <Select
      id={id}
      value={value}
      style={style}
      onChange={
        isEditing
          ? (e) => {
              onChange(id, e.target.value as string);
            }
          : noOp
      }
    >
      {Object.entries(options || []).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const SelectInput = injectIntl(SelectInputBase);
