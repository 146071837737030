import React, { FC } from 'react';
import { KvintaHierarchyLevelType } from '../../apis/kvinta-load-test-toolkit';
import { ArrowDownButton, ArrowUpButton, DeleteButton } from '../IconButtons';
import { IntlShape } from 'react-intl';
import {
  getOptionLabel,
  TFormHierarchyLevelView,
  TProductDataView,
  TProductFormData,
} from '../../modules/configuration/products/ProductStore';
import styles from './ProductHierarchyLevel.module.scss';
import { TextField } from '../FormFields/TextField';
import { CheckBoxField } from '../FormFields/Fields_Exp/CheckBoxField';
import { ECheckboxState } from '../../common/formUtils/types';
import { CheckBoxInput } from '../FormFields/Fields_Exp/CheckBoxInput_Exp';
import { SelectInput } from '../FormFields/Fields_Exp/SelectInput_Exp';
import { TextInput } from '../FormFields/Fields_Exp/TextInput_Exp';
import { AutocompleteSelectInputExp } from '../FormFields/Fields_Exp/AutocompleteSelectInputExp';

type THierarchyLevelButtons = {
  disabled: boolean;
  formData: TProductDataView;
  index: number;
};

const HierarchyLevelButtonsExp: FC<THierarchyLevelButtons> = ({ disabled, formData, index }) => {
  const isFirstElement = index === 0;
  const isLastElement = index === formData.hierarchy.length - 1;
  return (
    <div className={styles.levelButtonsContainer}>
      <DeleteButton
        disabled={disabled}
        onClick={() => {
          return null;
        }}
      />
      <div className={styles.arrowButtonsContainer}>
        {!isFirstElement && (
          <ArrowUpButton
            disabled={disabled}
            onClick={() => {
              return null;
            }}
          />
        )}
        {!isLastElement && (
          <ArrowDownButton
            disabled={disabled}
            onClick={() => {
              return null;
            }}
          />
        )}
      </div>
    </div>
  );
};

type TCodeSourceSwitcher = {
  data: TFormHierarchyLevelView;
};
const CodeSourceSwitcher: FC<TCodeSourceSwitcher> = ({ data }) => {
  switch (data.codeSource) {
    case ECheckboxState.CHECKED: {
      return <TextField value={data.gtin} label="product.create-form.field.gtin" />;
    }
    case ECheckboxState.NOT_SET:
    case ECheckboxState.UNCHECKED: {
      return (
        <div className={styles.subRow3}>
          <TextField
            value={getOptionLabel(data.epcCodeIdOptions, data.epcCodeId)}
            label="product.create-form.field.codeFormat"
          />
          <TextField value={data.gtin} label="product.create-form.field.gtin" />
        </div>
      );
    }
  }
};

type TGenerateSsccSwitcher = {
  data: TFormHierarchyLevelView;
};
const GenerateSsccSwitcher: FC<TGenerateSsccSwitcher> = ({ data }) => {
  switch (data.generateSscc) {
    case ECheckboxState.CHECKED: {
      return <TextField value={data.gcp} label="product.create-form.field.gcp" />;
    }
    case ECheckboxState.NOT_SET:
    case ECheckboxState.UNCHECKED: {
      return (
        <div className={styles.subRow3}>
          <TextField
            value={getOptionLabel(data.epcCodeIdOptions, data.epcCodeId)}
            label="product.create-form.field.codeFormat"
          />
          <TextField value={data.gcp} label="product.create-form.field.gcp" />
        </div>
      );
    }
  }
};

type THierarchyLevelTypeSwitcher = {
  data: TFormHierarchyLevelView;
};
const HierarchyLevelTypeSwitcher: FC<THierarchyLevelTypeSwitcher> = ({ data }) => {
  switch (data.hierarchyLevelType) {
    case KvintaHierarchyLevelType.Pk: {
      return (
        <div>
          <CodeSourceSwitcher data={data} />
          <CheckBoxField value={data.codeSource} label="product.create-form.field.codeSource" />
        </div>
      );
    }
    case KvintaHierarchyLevelType.Sgtin: {
      return (
        <div className={styles.subRow3}>
          <TextField
            value={getOptionLabel(data.epcCodeIdOptions, data.epcCodeId)}
            label="product.create-form.field.codeFormat"
          />
          <TextField value={data.gtin} label="product.create-form.field.gtin" />
        </div>
      );
    }
    case KvintaHierarchyLevelType.Sscc: {
      return (
        <div>
          <GenerateSsccSwitcher data={data} />
          <CheckBoxField value={data.generateSscc} label="product.create-form.field.generateSscc" />
        </div>
      );
    }
    default:
      return <div />;
  }
};

type THierarchyLevelProps = {
  intl: IntlShape;
  data: TFormHierarchyLevelView;
  formData: TProductDataView;
  index: number;
  showSingleEpcisDocument: boolean;
};

export const ProductHierarchyLevelView: FC<THierarchyLevelProps> = ({
  intl,
  index,
  formData,
  data,
  showSingleEpcisDocument,
}) => {
  return (
    <div className={styles.hierarchyLevelContainer}>
      <div className={styles.row1}>
        <HierarchyLevelButtonsExp disabled={true} index={index} formData={formData} />
        <div>
          <TextField value={data.hierarchyLevelType} label="product.create-form.field.levelType" />
          {showSingleEpcisDocument && (
            <CheckBoxField value={data.singleEpcisDocument} label="product.create-form.field.singleEpcisDocument" />
          )}
        </div>
        <TextField value={data.name} label="product.create-form.field.name" />
      </div>
      <div className={styles.row2}>
        <TextField value={String(data.packagingLevelType)} label="product.create-form.field.packagingLevel" />
        <TextField value={String(data.quantity)} label="product.create-form.field.quantity" />
      </div>
      <div className={styles.row3}>
        <HierarchyLevelTypeSwitcher data={data} />
      </div>
    </div>
  );
};
