import { AppBar, AppBarProps, Drawer, IconButton, styled, Toolbar } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component, FC } from 'react';
import { SidebarItem } from './SidebarItem';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserMenu } from 'kvinta/components/Appbar/UserMenuComp';
import { KSidebarStore, SIDEBAR_STORE_ID } from './KSidebarStore';
import { HistoryStore, STORE_ID as HISTORY_STORE } from '../../common/HistoryStore';
import { injectIntl, IntlShape } from 'react-intl';
import { version } from '../../../config';
import styles from '../../../assets/css/partials/modules/Sidebar.module.scss';

type TAppBarIcon = {
  isOpen: boolean;
  currentTitle: string;
  toggleOpen: () => void;
};

const AppBarIcon: FC<TAppBarIcon> = ({ isOpen, currentTitle, toggleOpen }) => {
  const containerStyles = isOpen ? styles.appBarIconContainer : styles.appBarIconContainerClose;
  return (
    <div className={containerStyles}>
      <IconButton color="inherit" onClick={() => toggleOpen()}>
        <MenuIcon />
      </IconButton>
      <a className={styles.appBarIconLinkText} href="./">
        {currentTitle}
      </a>
    </div>
  );
};

type TMainTitleAndNav = {
  hasNavigation: boolean;
  mainTitle: string;
  navigateFunc: () => void;
};
const MainTitleAndNav: FC<TMainTitleAndNav> = ({ mainTitle, hasNavigation, navigateFunc }) => {
  return (
    <div className={styles.flexContainer}>
      {hasNavigation && navigateFunc !== undefined && (
        <IconButton color="inherit" onClick={() => navigateFunc()}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <div className={styles.appBarIconLinkText} onClick={() => navigateFunc()}>
        {mainTitle}
      </div>
    </div>
  );
};

interface ISidebarProps {
  sidebarStore?: KSidebarStore;
  historyStore?: HistoryStore;
  intl: IntlShape;
}

class KSidebar extends Component<ISidebarProps> {
  render() {
    const { isOpen, hasNavigation, mainTitle, currentTitle, navigateFunc } = this.props.sidebarStore;
    const toggleOpen = this.props.sidebarStore.toggleOpen;
    const listItems = this.props.sidebarStore.menuItems.map((sidebarItem: any, index) => (
      <SidebarItem
        key={`${sidebarItem.name}${index}`}
        history={this.props.historyStore.history}
        paddingLeft={0}
        isExpanded={false}
        item={sidebarItem}
        onClick={(label) => {
          this.props.sidebarStore.updateTitle(label);
        }}
        selectedItem={this.props.historyStore.getCurrentRoute().path}
      />
    ));
    return (
      <div id="sidebar" className={styles.sideBarContainer}>
        <AppBar color="primary" position="fixed" className={styles.appBarContainer}>
          <Toolbar disableGutters className={styles.appBarContainer}>
            <AppBarIcon isOpen={isOpen} currentTitle={currentTitle} toggleOpen={toggleOpen} />
            <MainTitleAndNav hasNavigation={hasNavigation} mainTitle={mainTitle} navigateFunc={navigateFunc} />
            <div className={styles.grow} />
            <div className={styles.userMenuContainer}>
              <UserMenu />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" className={isOpen ? styles.drawerContainerOpen : null} open={isOpen}>
          <div className={styles.drawerHeaderContainer}>
            <IconButton color="inherit" onClick={() => this.props.sidebarStore.toggleOpen()}>
              <MenuOpenIcon />
            </IconButton>
            {isOpen && (
              <a className={styles.appBarIconLinkText} href="./">
                {this.props.sidebarStore.currentTitle}
              </a>
            )}
          </div>
          {listItems}
          <div className={styles.versionContainer}>version: {version}</div>
        </Drawer>
      </div>
    );
  }
}

export default injectIntl(inject(SIDEBAR_STORE_ID, HISTORY_STORE)(observer(KSidebar)));
