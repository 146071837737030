import { Paper } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { STORE_ID, TaskProductStore } from './TaskProductStore';
import { TaskSummaryForm } from './TaskSummaryForm';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { EHistoryFunctionName, TaskHeaderTabs } from './TaskHeaderTabs';
import { FormControlButtons } from '../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../common/formUtils/utils';
import { TaskSummaryView } from './TaskSummaryView';

interface ViewTaskSummaryProps {
  historyStore?: HistoryStore;
  intl: any;
  taskProductStore?: TaskProductStore;
  sidebarStore?: KSidebarStore;
  id: string;
}

class TaskSummaryImpl extends Component<ViewTaskSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.taskProductStore.fetchTask(this.props.id);
    this.disposer = reaction(
      () => this.props.taskProductStore.currentTask,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateTaskListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const taskId = this.props.id;
    const {
      currentTask,
      taskFormData,
      taskFormIsOpen,
      cancelUpdateTask,
      submitUpdateTaskForm,
      openUpdateTaskForm,
      isLoading,
      handleTaskFormBlur,
      handleTaskFormChange,
    } = this.props.taskProductStore;

    return (
      <div className={styles.summaryContainer}>
        <Paper square className={styles.summaryTabMenuHeader}>
          <TaskHeaderTabs currentTask={currentTask} taskId={taskId} initialValue={EHistoryFunctionName.SummaryPath} />
        </Paper>
        <Paper square className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(taskFormData)}
            formIsOpen={taskFormIsOpen}
            onCancel={() => cancelUpdateTask(taskId)}
            onConfirm={() => submitUpdateTaskForm(taskId)}
            onOpen={openUpdateTaskForm}
          />
          {taskFormIsOpen ? (
            <TaskSummaryForm onBlur={handleTaskFormBlur} onChange={handleTaskFormChange} formData={taskFormData} />
          ) : (
            <TaskSummaryView formData={currentTask} />
          )}
        </Paper>
      </div>
    );
  }
}

export const TaskSummary = injectIntl(inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(TaskSummaryImpl)));
