import { makeObservable, observable, action, computed } from 'mobx';

export interface ResizeEvent {
  width: number;
  height: number;
}

export const MinHeight = 400;
export const DefaultTitleHeight = 70;

export class PageContentStore {
  height: number | undefined;
  width: number | undefined;

  constructor() {
    makeObservable(this, {
      height: observable,
      width: observable,
      updateDimensions: action,
      tableContentHeightDefault: computed,
      tableContentHeightThin: computed,
    });
    this.height = 0;
    this.width = 0;
  }

  updateDimensions(width: number, height: number) {
    this.height = height;
    this.width = width;
    const ev = new CustomEvent('page-resize', {
      detail: { width, height } as ResizeEvent,
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(ev);
  }

  tableContentHeight(titleMargin: number = DefaultTitleHeight, withTitle = true, withFooter = true): number {
    if (!this.height || isNaN(this.height) || this.height === 0) {
      return getTableHeight(MinHeight, titleMargin, withTitle, withFooter);
    }
    return getTableHeight(this.height, titleMargin, withTitle, withFooter);
  }

  get tableContentHeightDefault(): number {
    if (!this.height || isNaN(this.height) || this.height === 0) {
      return getTableHeight(MinHeight, DefaultTitleHeight, false);
    }
    return getTableHeight(this.height, DefaultTitleHeight, false);
  }

  get tableContentHeightThin(): number {
    if (!this.height || isNaN(this.height) || this.height === 0) {
      return getTableHeight(MinHeight, DefaultTitleHeight, false, false);
    }
    return getTableHeight(this.height, DefaultTitleHeight, false, false);
  }

  get tableContentHeightWithTab(): number {
    if (!this.height || isNaN(this.height) || this.height === 0) {
      return getTableHeight(MinHeight, DefaultTitleHeight, true, false);
    }
    return getTableHeight(this.height, DefaultTitleHeight, true, false);
  }
}

export function getTableHeight(
  pageContentHeight: number,
  titleMargin: number,
  withTitle = false,
  withFooter = false,
): number {
  return (
    pageContentHeight - titleMargin - (withTitle ? 64 : 0) - (withFooter ? 56 : 0) - 2 //  Padding
  );
}
