import { action, makeObservable, observable } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  CodePoolApi,
  DefaultApi,
  KvintaCodePoolStatistic,
  KvintaOperationResponseListTaskExecutionLogResponse,
  KvintaSsccExecutionLogStatusResponse,
  KvintaSsccGenerationStep,
  KvintaTaskExecutionLogResponse,
  ProductApi,
} from '../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../common/HistoryStore';
import { downloadFile } from '../../service/fileExport';
import { TApis } from '../../../stores';
import { normalizeApiResponse } from '../../apis/apis';

type TTaskLogs = {
  rsku: string;

  additionalProp: string;

  step: KvintaSsccGenerationStep;
  startTimeMillis: number;
  endTimeMillis?: number | null;
  status: KvintaSsccExecutionLogStatusResponse;
  error: string;
};

export type TTaskLogsListDefinition = TTaskLogs & { id: string };

export interface ITaskLogsRow extends TTaskLogsListDefinition, ISelectableRow {}

export class TaskLogsStore extends SelectableStore<ITaskLogsRow> {
  private _config: TAppOptionsConfig;
  private _tasksApi: DefaultApi;
  private _historyStore: HistoryStore;
  private _productApi: ProductApi;
  private _codePoolApi: CodePoolApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      isLoading: observable,

      setIsLoading: action.bound,
      fetchLogsListOfTask: action.bound,
    });
    this._tasksApi = apis.defaultApi;
    this._productApi = apis.productApi;
    this._historyStore = apis.historyStore;
    this._codePoolApi = apis.codePoolApi;
    this._config = config;
    this._notificationManager = apis.notificationManager;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async fetchLogsListOfTask(taskDefinitionId: string) {
    this.setListData([]);
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaTaskExecutionLogResponse[] | null>(
      () => this.getListLogs(taskDefinitionId),
      'An error occurred when trying to fetch Id Pool list',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    }

    const listDada: ITaskLogsRow[] = [];
    for (const element of resultData.result || []) {
      for (const additionalProp in element.ssccLog) {
        for (const elementAdditionalProp of element.ssccLog[additionalProp]) {
          listDada.push({
            id: `${element.rsku}&${additionalProp}&${elementAdditionalProp.step}`,
            rsku: element.rsku,
            additionalProp,
            step: elementAdditionalProp.step,
            status: elementAdditionalProp.status,
            endTimeMillis: elementAdditionalProp.endTimeMillis || new Date().getTime(),
            startTimeMillis: elementAdditionalProp.startTimeMillis,
            isSelected: false,
            error: elementAdditionalProp.error || '',
          });
        }
      }
    }
    this.setListData(listDada);
    this.setIsLoading(false);
  }

  getListLogs(taskDefinitionId: string) {
    return this._tasksApi.getExecutionLog({
      kvintaTaskDefinitionActionRequest: {
        taskDefinitionId: taskDefinitionId,
      },
    });
  }
}

export function exportLogs(data: ITaskLogsRow[]) {
  if (data && data.length) {
    let exportData = data
      .map(
        (result) =>
          `${result.rsku},${result.additionalProp},${result.step},${result.status},${result.startTimeMillis},${result.endTimeMillis}`,
      )
      .join('\n');

    exportData = `rsku,levelCode,action,status,startTimeMillis,endTimeMillis\n${exportData}`;
    return downloadFile('logs.csv', 'text/csv', exportData);
  }
}

export const STORE_ID = 'taskLogsStore';
