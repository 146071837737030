import { Button, FormControl } from '@mui/material';
import React, { FC } from 'react';
import { notNeeded, TProductFormData } from './ProductStore';
import styles from '../../../../assets/css/partials/modules/Products.module.scss';
import { TextInput } from '../../../components/FormFields/Fields_Exp/TextInput_Exp';
import { SelectInput } from '../../../components/FormFields/Fields_Exp/SelectInput_Exp';
import { CheckBoxInput } from '../../../components/FormFields/Fields_Exp/CheckBoxInput_Exp';
import { ProductHierarchyLevelForm } from '../../../components/ProductHierarchyLevel/ProductHierarchyLevelForm';

interface DialogCreateTaskProps {
  intl: any;
  formData: TProductFormData;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  addMember: (parentId: string, place: number) => void;
  removeMember: (memberId: string) => void;
  moveMemberDown: (memberId: string) => void;
  moveMemberUp: (memberId: string) => void;
}

const UpdateProductFormBase: FC<DialogCreateTaskProps> = ({
  formData,
  intl,
  onChange,
  onBlur,
  addMember,
  removeMember,
  moveMemberDown,
  moveMemberUp,
}) => {
  if (!formData) return null;
  const hideShippingAndReceiving = formData['productFormRoot.shipToLocationId'].value === 'None';
  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <TextInput
          {...formData['productFormRoot.rsku']}
          label="product.create-form.field.rsku"
          onBlur={onBlur}
          onChange={onChange}
        />
        <TextInput
          {...formData['productFormRoot.tpId']}
          label="product.create-form.field.tpId"
          disabled={formData['productFormRoot.tpPn']?.value === notNeeded}
          onBlur={onBlur}
          onChange={onChange}
        />
        <TextInput
          {...formData['productFormRoot.tpPn']}
          label="product.create-form.field.tpPn"
          disabled={formData['productFormRoot.tpPn']?.value === notNeeded}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      <div className={styles.formRow}>
        <TextInput
          {...formData['productFormRoot.lsku']}
          label="product.create-form.field.lsku"
          onBlur={onBlur}
          onChange={onChange}
        />
        <div>
          <SelectInput
            {...formData['productFormRoot.locationId']}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.locationId"
          />
          <CheckBoxInput
            {...formData['productFormRoot.createStockInQad']}
            label="product.create-form.field.createStockInQad"
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
        <div>
          <SelectInput
            {...formData['productFormRoot.shipToLocationId']}
            onChange={onChange}
            onBlur={onBlur}
            label="product.create-form.field.shipToLocationId"
          />
          {hideShippingAndReceiving ? null : (
            <CheckBoxInput
              {...formData['productFormRoot.shippingAndReceiving']}
              disabled={formData['productFormRoot.shipToLocationId'].value === 'None'}
              label="product.create-form.field.shippingAndReceiving"
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        </div>
      </div>
      <div className={styles.hierarchySection}>
        <Button onClick={() => addMember('productFormRoot.hierarchy', 0)} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'product.create-form.add-level' })}
        </Button>

        <FormControl fullWidth>
          {formData['productFormRoot.hierarchy'].children.map((arrayMemberId, index) => {
            return (
              <ProductHierarchyLevelForm
                key={arrayMemberId}
                intl={intl}
                formData={formData}
                arrayMemberId={arrayMemberId}
                showSingleEpcisDocument={!(index === formData['productFormRoot.hierarchy'].children.length - 1)}
                onChange={onChange}
                onBlur={onBlur}
                moveMemberDown={moveMemberDown}
                moveMemberUp={moveMemberUp}
                removeMember={removeMember}
              />
            );
          })}
        </FormControl>
      </div>
    </div>
  );
};

export const ProductSummaryForm = UpdateProductFormBase;
