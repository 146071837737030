import { Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { ProductStore, STORE_ID } from './ProductStore';
import styles from '../../../../assets/css/partials/modules/Products.module.scss';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ProductSummaryView } from './ProductSummaryView';
import { ProductSummaryForm } from './ProductSummaryForm';

interface IProductSummaryProps {
  historyStore?: HistoryStore;
  intl: any;
  productStore?: ProductStore;
  sidebarStore?: KSidebarStore;
  id: string;
}

class ProductSummaryBase extends Component<IProductSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.productStore.fetchProduct(this.props.id);

    this.disposer = reaction(
      () => this.props.productStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.rsku, () => {
            this.props.historyStore.navigateProductListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.productStore.closeCreateOrUpdateProduct();
    this.disposer();
  }

  render() {
    const {
      currentProduct,
      productFormData,
      productFormIsOpen,
      isLoading,
      addMember,
      moveMemberUp,
      moveMemberDown,
      removeMember,
      handleFormBlur,
      handleFormChange,
    } = this.props.productStore;
    const intl = this.props.intl;
    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateProductListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(productFormData)}
            formIsOpen={productFormIsOpen}
            onCancel={this.props.productStore.cancelUpdateProduct}
            onConfirm={this.props.productStore.submitUpdateProduct}
            onOpen={this.props.productStore.openUpdateProductForm}
          />
          {productFormIsOpen ? (
            <ProductSummaryForm
              intl={intl}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              formData={productFormData}
              addMember={addMember}
              removeMember={removeMember}
              moveMemberDown={moveMemberDown}
              moveMemberUp={moveMemberUp}
            />
          ) : (
            <ProductSummaryView formData={currentProduct} intl={intl} />
          )}
        </div>
      </div>
    );
  }
}

export const ProductSummary = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ProductSummaryBase)),
);
