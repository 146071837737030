import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import { transformCheckboxState } from './CheckBoxInput_Exp';
import { ECheckboxState } from '../../../common/formUtils/types';

type TCheckBoxFieldProps = {
  intl: IntlShape;
  value: ECheckboxState;
  label: string;
  style?: React.CSSProperties;
};

const CheckBoxFieldBase: React.FunctionComponent<TCheckBoxFieldProps> = ({ intl, value, label, style }) => (
  <FormControl variant="outlined" style={style}>
    <FormControlLabel
      style={{ backgroundColor: 'white' }}
      htmlFor={label}
      control={
        <Checkbox indeterminate={value === ECheckboxState.NOT_SET} id={label} checked={transformCheckboxState(value)} />
      }
      label={intl.formatMessage({ id: label })}
    />
  </FormControl>
);

export const CheckBoxField = injectIntl(CheckBoxFieldBase);
