import { Button, FormControl, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { action, computed, makeObservable, observable } from 'mobx';
import FilterInputLabel from './FilterInputLabel';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';

export interface IFilterColumn {
  label: string;
  field: string;
  value: string;
  isActive: boolean;
  isApplied?: boolean;
  isFieldEmpty?: boolean;
  render?: (props: IFilterColumnStore) => JSX.Element;
}

export interface IFilterColumnStore extends IFilterColumn {
  store: IFilter;
  intl: any;
  fieldId: string;
  translatedLabel: string;
  onChangeValue: (field: string, value: string) => void;
  showRemoveButton: boolean;
}

export class IFilter {
  columns: IFilterColumn[];
  private _executeFilter: () => Promise<void>;
  private _hideFilterButton: boolean;
  constructor(columns: IFilterColumn[], executeFilter: () => Promise<void>, hideFilterButton?: boolean) {
    makeObservable(this, {
      columns: observable,

      showAddFilterButton: computed,
      visibleFilters: computed,
      hiddenFilters: computed,
      showDoFilterButton: computed,

      hideFilter: action,
      showFilter: action,
      onChangeValue: action,
      onFilter: action,
      onClear: action,
    });

    this.columns = columns.map((value) => {
      return { ...value, isApplied: true, isFieldEmpty: true } as IFilterColumn;
    });
    this._executeFilter = executeFilter;
    this._hideFilterButton = hideFilterButton || false;
  }

  get showDoFilterButton(): boolean {
    if (this._hideFilterButton) {
      return false;
    }
    return this.visibleFilters.length > 0;
  }

  get showAddFilterButton(): boolean {
    for (const col of this.columns) {
      if (!col.isActive) {
        return true;
      }
    }
    return false;
  }

  get visibleFilters(): IFilterColumn[] {
    const cols: IFilterColumn[] = new Array<IFilterColumn>();
    for (const col of this.columns) {
      if (col.isActive) {
        cols.push(col);
      }
    }
    return cols;
  }

  get hiddenFilters(): IFilterColumn[] {
    const cols: IFilterColumn[] = new Array<IFilterColumn>();
    for (const col of this.columns) {
      if (!col.isActive) {
        cols.push(col);
      }
    }
    return cols;
  }

  onChangeValue(field: string, value: string) {
    this.columns.forEach((val, idx) => {
      if (val.field === field) {
        this.columns[idx].value = value;
        this.columns[idx].isApplied = false;
        this.columns[idx].isFieldEmpty = value === '';
        return;
      }
    });
  }

  onClear(field: string) {
    this.columns.forEach((val, idx) => {
      if (val.field === field) {
        this.columns[idx].value = '';
        this.columns[idx].isApplied = true;
        this.columns[idx].isFieldEmpty = true;
        this._executeFilter();
        return;
      }
    });
  }

  onFilter(field: string) {
    this.columns.forEach((val, idx) => {
      if (val.field === field) {
        this.columns[idx].isApplied = true;
        this._executeFilter();
        return;
      }
    });
  }

  hideFilter(field: string) {
    this.columns.forEach((val, idx) => {
      if (val.field === field) {
        this.columns[idx].isActive = false;
        this.columns[idx].value = '';
        this._executeFilter();
        return;
      }
    });
  }

  showFilter(field: string) {
    this.columns.forEach((val, idx) => {
      if (val.field === field) {
        this.columns[idx].isActive = true;
      }
    });
  }
}

interface IFilterBarProps {
  filter: IFilter;
  intl: any;
}

interface IFilterBarState {
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
  isFieldEmpty: boolean;
}

class FilterBarImpl extends Component<IFilterBarProps, IFilterBarState> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      isFieldEmpty: true,
    };
  }
  onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
    });
  };

  onMenuClicked = (field: string) => {
    this.props.filter.showFilter(field);
    this.onMenuClose();
  };

  onChangeValue = (field: string, value: string) => {
    this.props.filter.onChangeValue(field, value);
  };

  onClear = (field: string) => {
    this.props.filter.onClear(field);
  };
  render() {
    const showAddFilterButton = this.props.filter.showAddFilterButton;
    const showDoFilterButton = this.props.filter.showDoFilterButton;
    const cols = this.props.filter.visibleFilters;
    const hiddenCols = this.props.filter.hiddenFilters;
    const intl = this.props.intl;
    const filterBy = intl.formatMessage({ id: 'filter.by' });

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {cols.map((val, idx) => {
          const filterFieldId = 'filter-' + val.field;
          const translatedLabel = (filterBy + ' ' + intl.formatMessage({ id: val.label })) as any;
          const render = val.render;
          const showRemoveButton = cols.length > 1;

          return (
            <div
              className="ZstFilter-root"
              style={{
                marginLeft: '5px',
                // maxWidth: translatedLabel.length * 6 + 'px' not working properly in many cases
              }}
              key={val.field}
            >
              <FormControl variant="outlined" key={val.field} style={{ height: '40px' }}>
                <FilterInputLabel htmlFor={filterFieldId} child={translatedLabel} />
                {render ? (
                  render({
                    ...val,
                    store: this.props.filter,
                    intl,
                    onChangeValue: this.onChangeValue,
                    fieldId: filterFieldId,
                    translatedLabel,
                    showRemoveButton,
                  })
                ) : (
                  <OutlinedInput
                    style={{ height: '40px', width: '280px' }}
                    id={filterFieldId}
                    value={val.value}
                    placeholder={translatedLabel}
                    onChange={(e) => {
                      this.onChangeValue(val.field, e.target.value);
                    }}
                    label={translatedLabel}
                    endAdornment={
                      // Render Remove Filter button always
                      // Render Clear Filter button if Filter is applied or dirty
                      // Render Aplly Filter button if Filter is not applied
                      //
                      // Clear Filter resets filter to default and re-renders page
                      val.value === '' && showRemoveButton ? (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ marginLeft: 6 }}
                            className="removeFilter"
                            onClick={(e) => {
                              this.props.filter.hideFilter(val.field);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : val.isApplied ? (
                        <InputAdornment position="end">
                          {val.value !== '' && (
                            <IconButton
                              className="clearFilter"
                              onClick={(e) => {
                                this.onClear(val.field);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                          {showRemoveButton && (
                            <IconButton
                              className="removeFilter"
                              onClick={(e) => {
                                this.props.filter.hideFilter(val.field);
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          {val.value !== '' && (
                            <>
                              <IconButton
                                className="clearFilter"
                                onClick={(e) => {
                                  this.onClear(val.field);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                              <Button
                                variant="contained"
                                color="primary"
                                className="applyFilter"
                                onClick={(e) => {
                                  this.props.filter.onFilter(val.field);
                                }}
                              >
                                {intl.formatMessage({ id: 'button.apply' })}
                              </Button>
                            </>
                          )}
                          {showRemoveButton && (
                            <IconButton
                              className="removeFilter"
                              onClick={(e) => {
                                this.props.filter.hideFilter(val.field);
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }
                  />
                )}
              </FormControl>
            </div>
          );
        })}
        {showAddFilterButton && (
          <div style={{ marginLeft: '5px' }}>
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={this.onMenuOpen}
              style={{ whiteSpace: 'nowrap' }}
            >
              {intl.formatMessage({ id: 'button.add-filter' })}
            </Button>
            <Menu
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              id={'filterMenu'}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.isMenuOpen}
              onClose={this.onMenuClose}
            >
              {hiddenCols.map((val, index) => {
                return (
                  <MenuItem onClick={() => this.onMenuClicked(val.field)} key={val.field}>
                    <FormattedMessage id={val.label} />
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export const FilterBar = injectIntl(observer(FilterBarImpl));
