import { Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { STORE_ID, TaskProductStore } from './TaskProductStore';
import { TaskProductSummaryForm } from './TaskProductSummaryForm';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { FormControlButtons } from '../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../common/formUtils/utils';

interface ViewTaskSummaryProps {
  historyStore?: HistoryStore;
  intl: any;
  taskProductStore?: TaskProductStore;
  sidebarStore?: KSidebarStore;
  taskId: string;
  productId: string;
}

class NewProductImpl extends Component<ViewTaskSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.taskProductStore.openCreateProductForm(this.props.taskId);

    this.disposer = reaction(
      () => this.props.taskProductStore.currentTask,
      (comp) => {
        if (comp) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateTaskListPath();
          });
        }
      },
    );
  }

  componentWillUnmount(): void {
    this.props.taskProductStore.closeUpdateProduct();
  }

  render() {
    const intl = this.props.intl;
    const {
      isLoading,
      productFormData,
      handleFormBlur,
      handleFormChange,
      addMember,
      removeMember,
      moveMemberUp,
      moveMemberDown,
    } = this.props.taskProductStore;
    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateTaskListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(productFormData)}
            formIsOpen={true}
            onCancel={() => this.props.taskProductStore.cancelCreateTaskProduct(this.props.taskId)}
            onConfirm={() => this.props.taskProductStore.submitCreateProduct(this.props.taskId)}
            onOpen={() => {
              return null;
            }}
          />
          {productFormData && (
            <TaskProductSummaryForm
              intl={intl}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              formData={productFormData}
              addMember={addMember}
              removeMember={removeMember}
              moveMemberDown={moveMemberDown}
              moveMemberUp={moveMemberUp}
            />
          )}
        </div>
      </div>
    );
  }
}

export const NewTaskProduct = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(NewProductImpl)),
);
