/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEndMarket,
    KvintaEndMarketFromJSON,
    KvintaEndMarketFromJSONTyped,
    KvintaEndMarketToJSON,
    KvintaTaskDefinitionProduct,
    KvintaTaskDefinitionProductFromJSON,
    KvintaTaskDefinitionProductFromJSONTyped,
    KvintaTaskDefinitionProductToJSON,
    KvintaTaskPriority,
    KvintaTaskPriorityFromJSON,
    KvintaTaskPriorityFromJSONTyped,
    KvintaTaskPriorityToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTaskDefinition
 */
export interface KvintaTaskDefinition {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskDefinition
     */
    name: string;
    /**
     * 
     * @type {KvintaEndMarket}
     * @memberof KvintaTaskDefinition
     */
    endMarket?: KvintaEndMarket | null;
    /**
     * 
     * @type {KvintaTaskPriority}
     * @memberof KvintaTaskDefinition
     */
    priority?: KvintaTaskPriority | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaTaskDefinition
     */
    updated: Date;
    /**
     * 
     * @type {Array<KvintaTaskDefinitionProduct>}
     * @memberof KvintaTaskDefinition
     */
    products: Array<KvintaTaskDefinitionProduct>;
}

export function KvintaTaskDefinitionFromJSON(json: any): KvintaTaskDefinition {
    return KvintaTaskDefinitionFromJSONTyped(json, false);
}

export function KvintaTaskDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'endMarket': !exists(json, 'endMarket') ? undefined : KvintaEndMarketFromJSON(json['endMarket']),
        'priority': !exists(json, 'priority') ? undefined : KvintaTaskPriorityFromJSON(json['priority']),
        'updated': (new Date(json['updated'])),
        'products': ((json['products'] as Array<any>).map(KvintaTaskDefinitionProductFromJSON)),
    };
}

export function KvintaTaskDefinitionToJSON(value?: KvintaTaskDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'endMarket': KvintaEndMarketToJSON(value.endMarket),
        'priority': KvintaTaskPriorityToJSON(value.priority),
        'updated': (value.updated.toISOString()),
        'products': ((value.products as Array<any>).map(KvintaTaskDefinitionProductToJSON)),
    };
}


