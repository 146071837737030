import { Button, Checkbox, Menu, MenuItem, Paper, Typography } from '@mui/material';
import MaterialTable from 'material-table';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { inject, observer } from 'mobx-react';
import React, { Component, CSSProperties, FC, useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import { ITableActionsState } from 'kvinta/common';
import { ITaskRow, STORE_ID, TaskStore } from './TaskStore';
import { KvintaTaskStatusEnum } from '../../apis/kvinta-load-test-toolkit';
import { LoadingBackground } from '../../components/LoadingBackground';
import { CopyStringField } from '../../components/FormFields/CopyStringField';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import {
  PROMPT_POPUP_STORE_ID,
  PromptPopupStore,
  TCreatePopupOptions,
} from '../../components/PromptPopup/PromptPopupStore';
import { NameListPopupBody } from '../../components/PromptPopup/NameListPopupBody';

interface TaskListProps {
  historyStore?: HistoryStore;
  taskStore?: TaskStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  promptPopupStore?: PromptPopupStore;
  intl: any;
}

function shortenId(id: string) {
  if (!id) {
    return '';
  }

  const lastCharIndex = id.length - 1;
  return `${id.slice(0, 6)}...${id.slice(lastCharIndex - 6, lastCharIndex)}`;
}

const ActionButton = ({ action, label, isLoading }) => (
  <Button
    onClick={(e) => {
      e.stopPropagation();
      action();
    }}
    variant="contained"
    color="secondary"
    disabled={isLoading}
  >
    {label}
  </Button>
);

const handlerStopPropagation = (e: any) => {
  e.stopPropagation();
};

const Actions: React.FunctionComponent<{ data: ITaskRow; taskStore: TaskStore; intl: any }> = ({
  data,
  taskStore,
  intl,
}) => {
  switch (data.taskStatus) {
    case KvintaTaskStatusEnum.Created:
      return (
        <div className={styles.actionButtonsContainer} onClick={handlerStopPropagation}>
          <ActionButton
            action={() => taskStore.runTask(data.id)}
            label={intl.formatMessage({ id: 'task.list.actions.start' })}
            isLoading={data.isLoading}
          />
        </div>
      );
    case KvintaTaskStatusEnum.Running:
      return (
        <div className={styles.actionButtonsContainer} onClick={handlerStopPropagation}>
          <ActionButton
            action={() => taskStore.pauseTask(data.id)}
            label={intl.formatMessage({ id: 'task.list.actions.pause' })}
            isLoading={data.isLoading}
          />
          <ActionButton
            action={() => taskStore.stopTask(data.id)}
            label={intl.formatMessage({ id: 'task.list.actions.cancel' })}
            isLoading={data.isLoading}
          />
        </div>
      );
    case KvintaTaskStatusEnum.Paused:
    case KvintaTaskStatusEnum.Scheduled:
      return (
        <div className={styles.actionButtonsContainer} onClick={handlerStopPropagation}>
          <ActionButton
            action={() => taskStore.resumeTask(data.id)}
            label={intl.formatMessage({ id: 'task.list.actions.resume' })}
            isLoading={data.isLoading}
          />
        </div>
      );
    case KvintaTaskStatusEnum.Completed:
    case KvintaTaskStatusEnum.Error:
      return (
        <div className={styles.actionButtonsContainer} onClick={handlerStopPropagation}>
          <ActionButton
            action={() => taskStore.runTask(data.id)}
            label={intl.formatMessage({ id: 'task.list.actions.restart' })}
            isLoading={data.isLoading}
          />
        </div>
      );
    default:
      return <div className={styles.actionButtonsContainer} />;
  }
};

type TStatusStyle = {
  [key in KvintaTaskStatusEnum]: CSSProperties;
};
const statusStyle: TStatusStyle = {
  [KvintaTaskStatusEnum.Scheduled]: {},
  [KvintaTaskStatusEnum.Completed]: {
    color: '#173820',
    fontWeight: 'bold',
  },
  [KvintaTaskStatusEnum.Paused]: {
    color: '#5f5a5a',
    fontWeight: 'bold',
  },
  [KvintaTaskStatusEnum.Running]: {
    color: '#203287',
    fontWeight: 'bold',
  },
  [KvintaTaskStatusEnum.Created]: {},
  [KvintaTaskStatusEnum.Error]: {
    color: 'red',
  },
};
type TUpdateButton = {
  isLoading: boolean;
  intl: any;
  startTime: number;
  action: () => void;
};
const UpdateButton: FC<TUpdateButton> = ({ isLoading, intl, startTime, action }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimer(startTime);
    const intervalId = window.setInterval(() => {
      setTimer((prevState) => prevState - 1);
    }, 1000);
    return () => {
      window.clearInterval(intervalId);
    };
  }, [startTime]);

  return (
    <Button
      variant="contained"
      color="warning"
      disabled={isLoading}
      startIcon={<UpdateIcon color="secondary" />}
      onClick={(e) => {
        action();
      }}
    >
      {intl.formatMessage({ id: 'button.update' })}
      <span
        style={{
          marginLeft: 5,
          minWidth: 20,
        }}
      >
        {startTime && timer ? timer : null}
      </span>
    </Button>
  );
};

class TaskListImpl extends Component<TaskListProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: TaskListProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: ITaskRow) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.taskStore.setStartPolling(true);
    this.props.sidebarStore.clearMainTitle();
    this.props.taskStore.fetchTaskList();
  }

  componentWillUnmount() {
    this.props.taskStore.setStartPolling(false);
    this.props.taskStore.clearPollingTimeouts();
  }

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, checked, pollingTimer } = this.props.taskStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});
    const isChecked = (listData && listData.filter((value) => value.isSelected)) || [];

    return (
      <>
        <Paper square>
          {isLoading && <LoadingBackground />}
          <div className={styles.taskListHeaderButtonContainer}>
            <UpdateButton
              isLoading={isLoading}
              intl={intl}
              startTime={pollingTimer}
              action={() => {
                this.props.taskStore.fetchTaskList();
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.historyStore.navigateNewTaskPath();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>

          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: (
                  <Checkbox
                    id={'check-all'}
                    checked={checked > 0}
                    onChange={() => {
                      this.props.taskStore.toggleAll();
                    }}
                    checkedIcon={checked == 2 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  />
                ),
                field: 'isSelected',
                sorting: false,
                width: null,
                cellStyle: { width: 60, fontSize: null },
                render: (rowData: any) => (
                  <Checkbox
                    id={rowData.id}
                    checked={selected[rowData.id]}
                    onChange={() => {
                      this.props.taskStore.toggleRow(rowData.id);
                    }}
                  />
                ),
              },
              {
                title: intl.formatMessage({ id: 'task.list.name' }),
                field: 'name',
                defaultSort: 'asc',
                width: null,
                cellStyle: { minWidth: 200, fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'task.list.priority' }),
                field: 'priority',
                defaultSort: 'desc',
                width: null,
                cellStyle: { minWidth: 120, fontSize: null },
              },
              {
                title: intl.formatMessage({ id: 'task.list.status' }),
                field: 'taskStatus',
                defaultSort: 'desc',
                width: null,
                cellStyle: { minWidth: 130, fontSize: null },
                render: (rawData: any) => <span style={statusStyle[rawData.taskStatus]}>{rawData.taskStatus} </span>,
              },
              {
                title: intl.formatMessage({ id: 'task.list.rootWorkflowId' }),
                field: 'rootWorkflowId',
                defaultSort: 'desc',
                sorting: false,
                width: null,
                cellStyle: { minWidth: 180, fontSize: null },
                render: (rawData: any) => (
                  <CopyStringField stringToCopy={rawData.rootWorkflowId}>
                    {shortenId(rawData.rootWorkflowId)}
                  </CopyStringField>
                ),
              },
              {
                title: intl.formatMessage({ id: 'task.list.actions' }),
                defaultSort: 'desc',
                sorting: false,
                width: null,
                cellStyle: {
                  maxWidth: '210px',
                  minWidth: 120,
                  fontSize: null,
                  whiteSpace: 'nowrap',
                },
                render: (rowData) => {
                  return (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                      <Actions data={rowData} taskStore={this.props.taskStore} intl={intl} />
                    </div>
                  );
                },
              },
            ]}
            data={listData}
            options={{
              rowStyle: { wordBreak: 'break-word' },
              actionsColumnIndex: 0,
              toolbarButtonAlignment: 'left',
              paging: false,
              sorting: true,
              search: true,
              searchFieldAlignment: 'left',
              draggable: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateTaskSummaryPath(rowData.id);
              }
            }}
            actions={[
              {
                icon: () => <MoreVertIcon />,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  if (Array.isArray(rowData)) {
                    this.handleActionsMenuOpen(event, rowData[0]);
                  } else {
                    this.handleActionsMenuOpen(event, rowData);
                  }
                },
              },
              {
                icon: () => <DeleteOutlineIcon color="primary" />,
                tooltip: `delete ${isChecked.length} row${isChecked.length > 1 ? 's' : ''}`,
                hidden: !isChecked.length,
                isFreeAction: true,
                onClick: () => {
                  this.props.promptPopupStore.createPopup({
                    title: intl.formatMessage({ id: 'popup.confirm.delete.title' }),
                    children: <NameListPopupBody listNames={this.props.taskStore.listChecked} />,
                    onHandlerApply: () => {
                      this.props.taskStore.deleteListTask(isChecked);
                      this.onMenuClose();
                    },
                    onHandlerReject: () => {
                      this.onMenuClose();
                    },
                  });
                },
              },
            ]}
          />
        </Paper>

        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              this.props.promptPopupStore.createPopup({
                title: intl.formatMessage({ id: 'popup.confirm.delete.title' }),
                children: <NameListPopupBody listNames={[this.state.rowData]} />,
                onHandlerApply: () => {
                  this.props.taskStore.deleteTask(this.state.rowData.id);
                  this.onMenuClose();
                },
                onHandlerReject: () => {
                  this.onMenuClose();
                },
              });
            }}
          >
            <DeleteOutlineIcon color="primary" />
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export const TaskList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    STORE_ID,
    SIDEBAR_STORE_ID,
    PROMPT_POPUP_STORE_ID,
  )(observer(TaskListImpl)),
);
