/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaBlackHour,
    KvintaBlackHourFromJSON,
    KvintaBlackHourFromJSONTyped,
    KvintaBlackHourToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaBlackHours
 */
export interface KvintaBlackHours {
    /**
     * The key - day of week value follows the ISO-8601 standard, from 1 (Monday) to 7 (Sunday).
     * @type {{ [key: string]: KvintaBlackHour; }}
     * @memberof KvintaBlackHours
     */
    blackHours: { [key: string]: KvintaBlackHour; };
}

export function KvintaBlackHoursFromJSON(json: any): KvintaBlackHours {
    return KvintaBlackHoursFromJSONTyped(json, false);
}

export function KvintaBlackHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaBlackHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blackHours': (mapValues(json['blackHours'], KvintaBlackHourFromJSON)),
    };
}

export function KvintaBlackHoursToJSON(value?: KvintaBlackHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blackHours': (mapValues(value.blackHours, KvintaBlackHourToJSON)),
    };
}


