import React from 'react';
import styles from './ButtonSecondary.module.scss';
import cn from 'classnames';

import { Spinner } from './Spinner';
import { EButtonState } from '../common';

type TButtonSecondaryProps = {
  id: string;
  onClick: () => void;
  buttonState: EButtonState;
  label: string | React.ReactElement;
  icon?: string | React.ReactElement;
};

function mapStyleToButtonState(buttonState: EButtonState) {
  switch (buttonState) {
    case EButtonState.ACTIVE:
      return styles.active;
    case EButtonState.DISABLED:
      return styles.disabled;
    case EButtonState.LOADING:
      return styles.loading;
  }
}

export const ButtonGreen: React.FunctionComponent<TButtonSecondaryProps> = ({
  id,
  onClick,
  buttonState,
  label,
  icon,
}) => (
  <div className={styles.buttonWrapper}>
    <button
      disabled={buttonState !== EButtonState.ACTIVE}
      id={id}
      onClick={onClick}
      className={cn(styles.base, { [styles.withIcon]: Boolean(icon) }, mapStyleToButtonState(buttonState))}
    >
      {icon ? <span className={styles.buttonContent}>{icon}</span> : null}
      <span className={styles.buttonContent}>{label}</span>
    </button>
    {buttonState === EButtonState.LOADING && <Spinner className={styles.spinner} />}
  </div>
);
