/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaListTasksFilter,
    KvintaListTasksFilterFromJSON,
    KvintaListTasksFilterFromJSONTyped,
    KvintaListTasksFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListTasksRequest
 */
export interface KvintaListTasksRequest {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListTasksRequest
     */
    paging?: KvintaPaging | null;
    /**
     * 
     * @type {KvintaListTasksFilter}
     * @memberof KvintaListTasksRequest
     */
    filters?: KvintaListTasksFilter | null;
}

export function KvintaListTasksRequestFromJSON(json: any): KvintaListTasksRequest {
    return KvintaListTasksRequestFromJSONTyped(json, false);
}

export function KvintaListTasksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListTasksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': !exists(json, 'paging') ? undefined : KvintaPagingFromJSON(json['paging']),
        'filters': !exists(json, 'filters') ? undefined : KvintaListTasksFilterFromJSON(json['filters']),
    };
}

export function KvintaListTasksRequestToJSON(value?: KvintaListTasksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filters': KvintaListTasksFilterToJSON(value.filters),
    };
}


