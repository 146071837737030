import { Autocomplete, createFilterOptions, FormControl, FormHelperText, TextField } from '@mui/material';
import React, { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { TOptions } from '../../../modules/configuration/products/ProductStore';
import styles from './TextInput_Exp.module.scss';

type TTempValue = { title: string; inputValue: string };

function convertAutocompleteOptions(data: TOptions): TTempValue[] {
  return (data || []).map((value) => {
    return {
      inputValue: value.key,
      title: value.label,
    };
  });
}

type TAutocompleteSelectInput = {
  intl: IntlShape;
  id: string;
  value: string | number;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
  options: { key: string; label: string }[];

  label?: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
};

export const AutocompleteSelectInputExpBase: FC<TAutocompleteSelectInput> = ({
  intl,
  id,
  value,
  onChange,
  onBlur,
  options,
  label,
  disabled,
  isRequiredField,
  errors,
  showError,
  touched,
  style,
}) => {
  const filter = createFilterOptions<{ title: string; inputValue: string }>();
  const [tempValue, setTempValue] = React.useState<TTempValue>({
    title: (value && value.toString()) || '',
    inputValue: (value && value.toString()) || '',
  });

  React.useMemo(() => {
    if (tempValue && value && value.toString() !== tempValue.title) {
      setTempValue({
        title: (value && value.toString()) || '',
        inputValue: (value && value.toString()) || '',
      });
    }
  }, [value]);

  const autocompleteOptions = convertAutocompleteOptions(options);
  return (
    <FormControl fullWidth error={showError && !!errors.length}>
      <Autocomplete
        value={tempValue.title}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTempValue({
              inputValue: newValue,
              title: newValue,
            });
            onChange(id, newValue);
          } else if (newValue && newValue.inputValue) {
            setTempValue({
              inputValue: newValue.inputValue,
              title: newValue.inputValue,
            });
            onChange(id, newValue.inputValue);
          } else if (newValue === null) {
            setTempValue({
              inputValue: '',
              title: '',
            });
            onChange(id, '');
          } else {
            setTempValue({
              inputValue: newValue.inputValue,
              title: newValue.title,
            });
            onChange(id, newValue.inputValue);
          }
        }}
        //onBlur={(e) => onBlur(id, e.target.value as string)}
        inputValue={tempValue.inputValue}
        onInputChange={(event, newInputValue) => {
          setTempValue((prevValue) => {
            return { ...prevValue, inputValue: newInputValue };
          });
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = autocompleteOptions.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id}
        options={convertAutocompleteOptions(options)}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            error={showError && !!errors.length}
            variant="outlined"
            className="select-text-field"
            onBlur={(e) => onBlur(id, e.target.value)}
            label={intl.formatMessage({ id: label })}
          />
        )}
      />

      <FormHelperText>
        {showError && errors.map((error) => <span key={error}>{intl.formatMessage({ id: error })}</span>)}
      </FormHelperText>
    </FormControl>
  );
};
export const AutocompleteSelectInputExp = injectIntl(AutocompleteSelectInputExpBase);
