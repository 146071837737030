import { Tab, Tabs } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { LocationStore, STORE_ID } from './LocationStore';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from '../../../../assets/css/partials/modules/Locations.module.scss';
import { LocationSummaryView } from './LocationSummaryView';
import { LocationSummaryForm } from './LocationSummaryForm';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { FormControlButtons } from '../../../components/FormControlButtons/FormControlButtons';

interface ViewLocationSummaryProps {
  intl: any;
  locationsStore?: LocationStore;
  sidebarStore?: KSidebarStore;
  id: string;
  historyStore: HistoryStore;
}

class ViewLocationSummaryImpl extends Component<ViewLocationSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateLocationListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.locationsStore.closeUpdateLocationForm();
    this.disposer();
  }

  handleEditCancel = () => {
    this.props.locationsStore.closeUpdateLocationForm();
    this.props.locationsStore.fetchLocation(this.props.id);
  };

  render() {
    const {
      currentLocation,
      locationFormData,
      updateLocationFormOpen,
      onChangeLocationFormField,
      onBlurLocationFormField,
    } = this.props.locationsStore;
    const isLoading = this.props.locationsStore.isLoading || !currentLocation;
    const intl = this.props.intl;

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <Tabs
            value={'1'}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<unknown>, newValue: number) => {
              if (newValue == 0) {
                this.props.historyStore.navigateLocationListPath();
              }
            }}
            aria-label="simple tabs example"
          >
            <Tab label={intl.formatMessage({ id: 'common.summary' })} value={'1'} id={'1'} />
          </Tabs>
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(locationFormData)}
            formIsOpen={updateLocationFormOpen}
            onCancel={this.handleEditCancel}
            onConfirm={this.props.locationsStore.submitUpdateLocation}
            onOpen={this.props.locationsStore.openUpdateLocationForm}
          />
          {updateLocationFormOpen ? (
            <LocationSummaryForm
              formData={locationFormData}
              onChange={onChangeLocationFormField}
              onBlur={onBlurLocationFormField}
            />
          ) : (
            <LocationSummaryView formData={currentLocation} />
          )}
        </div>
      </div>
    );
  }
}

export const LocationSummary = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ViewLocationSummaryImpl)),
);
