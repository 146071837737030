export enum NotificationTypes {
  Critical = 'error',
  Warning = 'warning',
  Information = 'info',
  Success = 'success',
}

export enum PoolTypes {
  UNKNOWN = 'UNKNOWN',
  GLOBAL = 'GLOBAL',
  FILTERED = 'FILTERED',
  USER = 'USER',
}

export enum EAcceptedFileTypes {
  csv = '.csv',
  pdf = '.pdf',
}

export enum EButtonState {
  ACTIVE = 'active',
  LOADING = 'loading',
  DISABLED = 'disabled',
}
