/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEpcCodeActionRequest
 */
export interface KvintaEpcCodeActionRequest {
    /**
     * Code identifier
     * @type {string}
     * @memberof KvintaEpcCodeActionRequest
     */
    codeId: string;
}

export function KvintaEpcCodeActionRequestFromJSON(json: any): KvintaEpcCodeActionRequest {
    return KvintaEpcCodeActionRequestFromJSONTyped(json, false);
}

export function KvintaEpcCodeActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcCodeActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeId': json['codeId'],
    };
}

export function KvintaEpcCodeActionRequestToJSON(value?: KvintaEpcCodeActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeId': value.codeId,
    };
}


