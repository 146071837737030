import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import { DefaultApi, KvintaLocation, KvintaLocationCodeSpecifics } from '../../../apis/kvinta-load-test-toolkit';
import { HistoryStore } from '../../../common/HistoryStore';
import { TApis } from '../../../../stores';
import { normalizeApiResponse } from '../../../apis/apis';
import { TSelectInputData, TTextInputData, TValidationGroupData } from '../../../common/formUtils/types';
import { validateForm } from '../../../common/formUtils/core';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, select, textInput } from '../../../common/formUtils/formDataGenerators';
import { isNotEmpty } from '../../../common/formUtils/validators';

export type TLocationFormData = {
  updateLocationForm: TValidationGroupData;
  'updateLocationForm.name': TTextInputData;
  'updateLocationForm.eoId': TTextInputData;
  'updateLocationForm.facilityId': TTextInputData;
  'updateLocationForm.locationId': TTextInputData;
  'updateLocationForm.machineId': TTextInputData;
  'updateLocationForm.readPoint': TTextInputData;
  'updateLocationForm.codeSpecifics': TSelectInputData;
};

interface ICommonForLocation {
  name: string;
  id: string;
  locationId: string;
  eoId: string;
  facilityId: string;
  readPoint: string;
  machineId: string;
}

export interface ILocation extends ICommonForLocation {
  codeSpecifics: KvintaLocationCodeSpecifics;
}
export interface ILocationRowTable extends ICommonForLocation {
  codeSpecifics: KvintaLocationCodeSpecifics;
}

interface ILocationRow extends ILocationRowTable, ISelectableRow {}

export class LocationStore extends SelectableStore<ILocationRow> {
  private _config: TAppOptionsConfig;
  private _locationsApi: DefaultApi;
  private _historyStore: HistoryStore;
  private _notificationManager: NotificationManager;
  isLoading: boolean;

  currentLocation?: ILocation | null = null;
  locationFormData?: TLocationFormData;

  updateLocationFormOpen: boolean;
  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, apis: TApis) {
    super();
    makeObservable(this, {
      setIsLoading: action.bound,
      setCurrentLocation: action.bound,
      setLocationFormData: action.bound,
      initLocationFormData: action.bound,

      fetchLocationList: action.bound,
      fetchLocation: action.bound,
      openCreateLocationForm: action.bound,
      cancelCreateLocationForm: action.bound,
      onChangeLocationFormField: action.bound,
      onBlurLocationFormField: action.bound,
      closeUpdateLocationForm: action.bound,
      openUpdateLocationForm: action.bound,

      submitCreateLocation: action.bound,
      submitUpdateLocation: action.bound,

      getLocationList: action.bound,
      geLocationById: action.bound,
      createLocation: action.bound,
      updateLocation: action.bound,
      deleteLocationById: action.bound,

      isLoading: observable,
      currentLocation: observable,
      locationFormData: observable,
      updateLocationFormOpen: observable,
    });
    this._locationsApi = apis.defaultApi;
    this._historyStore = apis.historyStore;
    this._config = config;
    this._notificationManager = apis.notificationManager;
    this.autofocusSearchInList = false;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setCurrentLocation(data: KvintaLocation) {
    const codeSpecificsIsCodentify = data.codeSpecifics === KvintaLocationCodeSpecifics.Codentify;
    this.currentLocation = {
      id: data.id || '',
      locationId: data.locationId || '',
      name: data.name || '',
      eoId: codeSpecificsIsCodentify ? '' : data.eoId || '',
      facilityId: codeSpecificsIsCodentify ? '' : data.facilityId || '',
      codeSpecifics: data.codeSpecifics,
      readPoint: data.readPoint || '',
      machineId: codeSpecificsIsCodentify ? '' : data.machineId || '',
    };
  }

  setLocationFormData(data: ILocation) {
    this.locationFormData = validateForm<TLocationFormData>(generateLocationFormData(LOCATION_FORM_ROOT_ID, data));
  }

  initLocationFormData() {
    const data = {
      id: '',
      locationId: '',
      name: '',
      eoId: '',
      facilityId: '',
      readPoint: '',
      machineId: '',
      codeSpecifics: KvintaLocationCodeSpecifics.None,
    };
    this.locationFormData = validateForm<TLocationFormData>(generateLocationFormData(LOCATION_FORM_ROOT_ID, data));
  }

  async fetchLocationList() {
    this.setIsLoading(true);
    this.setListData([]);
    const resultData = await normalizeApiResponse<KvintaLocation[]>(this.getLocationList, 'error in fetchLocationList');
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      const oldSelection = new Map();
      if (this.listData) {
        this.listData.forEach((row) => {
          oldSelection.set(row.id, row.isSelected);
        });
      }
      this.setListData(
        (resultData.result || []).map((comp) => {
          const selected = oldSelection.get(comp.id) || false;
          return { ...comp, isSelected: selected } as ILocationRow;
        }),
      );
    }
    this.setIsLoading(false);
  }

  async fetchLocation(locationId: string) {
    this.setIsLoading(true);
    this.currentLocation = null;
    const resultData = await normalizeApiResponse<KvintaLocation>(
      () => this.geLocationById(locationId),
      'error in fetchLocation',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      this.setCurrentLocation(resultData.result);
    }
    this.setIsLoading(false);
  }

  async submitDeleteLocationById(locationId: string) {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<any>(
      () => this.deleteLocationById(locationId),
      'error in submitDeleteLocationById',
    );
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchLocationList();
    }
    this.setIsLoading(false);
  }

  async submitUpdateLocation() {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaLocation>(this.updateLocation, 'error in submitUpdateLocation');
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchLocation(resultData.result.id);
      this.closeUpdateLocationForm();
    }
    this.setIsLoading(false);
  }

  async submitCreateLocation() {
    this.setIsLoading(true);
    const resultData = await normalizeApiResponse<KvintaLocation>(this.createLocation, 'error in submitCreateLocation');
    if (resultData.error) {
      this._notificationManager.sendError(resultData.error);
    } else {
      await this.fetchLocation(resultData.result.id);
      this.closeUpdateLocationForm();
      this._historyStore.navigateLocationSummaryPath(resultData.result.id);
    }
    this.setIsLoading(false);
  }

  async getLocationList() {
    return this._locationsApi.listLocations();
  }

  async geLocationById(locationId: string) {
    return this._locationsApi.getLocation({
      kvintaLocationActionRequest: {
        locationId,
      },
    });
  }

  createLocation() {
    return this._locationsApi.createLocation({
      kvintaLocationCreateRequest: transformLocationFormData(this.locationFormData),
    });
  }

  updateLocation() {
    return this._locationsApi.updateLocation({
      kvintaLocationUpdateRequest: {
        ...transformLocationFormData(this.locationFormData),
        id: this.currentLocation.id,
      },
    });
  }

  async deleteLocationById(locationId: string) {
    return this._locationsApi.deleteLocation({
      kvintaLocationActionRequest: {
        locationId: locationId,
      },
    });
  }

  onChangeLocationFormField(id: string, value: any) {
    const formData = toJS(this.locationFormData);
    if (id === 'updateLocationForm.codeSpecifics') {
      formData[id].value = value;
      if (value === KvintaLocationCodeSpecifics.Codentify) {
        formData['updateLocationForm.facilityId'].value = '';
        formData['updateLocationForm.eoId'].value = '';
        formData['updateLocationForm.machineId'].value = '';
      }
      const data = transformLocationFormData(formData);
      this.locationFormData = validateForm<TLocationFormData>(
        generateLocationFormData(LOCATION_FORM_ROOT_ID, { ...data, id: '' }),
      );
    } else {
      this.locationFormData = handleFormChange(formData, id, value);
    }
  }

  onBlurLocationFormField(id: string, value: any) {
    const formData = toJS(this.locationFormData);
    this.locationFormData = handleFormBlur(formData, id);
  }

  openCreateLocationForm() {
    this.setIsLoading(true);
    this.initLocationFormData();
    this.updateLocationFormOpen = true;
    this.setIsLoading(false);
  }

  cancelCreateLocationForm() {
    this._historyStore.navigateLocationListPath();
  }

  openUpdateLocationForm() {
    this.setLocationFormData({
      ...this.currentLocation,
    });
    this.updateLocationFormOpen = true;
  }

  closeUpdateLocationForm() {
    this.updateLocationFormOpen = false;
    this.locationFormData = null;
  }
}

export const STORE_ID = 'locationsStore';
export const LOCATION_FORM_ROOT_ID = 'updateLocationForm';

function generateLocationFormData(rootFormId: string, initialValues: ILocation) {
  return formRoot<TLocationFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'name',
        value: initialValues.name,
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'locationId',
        value: initialValues.locationId,
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'facilityId',
        value: initialValues.facilityId,
        validations: [validateFromCodeSpecifics({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'eoId',
        value: initialValues.eoId,
        validations: [validateFromCodeSpecifics({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'readPoint',
        value: initialValues.readPoint,
        validations: [isNotEmpty({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'machineId',
        value: initialValues.machineId,
        validations: [validateFromCodeSpecifics({ errorMessage: 'validation.error.required' })],
        isRequiredField: true,
      }),
      select({
        path: 'codeSpecifics',
        value: initialValues.codeSpecifics || KvintaLocationCodeSpecifics.None,
        options: Object.values(KvintaLocationCodeSpecifics).map((option) => ({
          key: option,
          label: `location-enum.codeSpecifics.${option}`,
        })),
        validations: [],
        isRequiredField: false,
      }),
    ],
  });
}

function validateFromCodeSpecifics(options: { errorMessage: string }) {
  return function (id, data) {
    console.log('validateFromCodeSpecifics');
    if (data['updateLocationForm.codeSpecifics'].value === KvintaLocationCodeSpecifics.Codentify) {
      console.log('validateFromCodeSpecifics = Codentify');
      return '';
    } else {
      return data[id].value.trim() !== '' ? undefined : options.errorMessage;
    }
  };
}

function transformLocationFormData(locationFormData: TLocationFormData) {
  return {
    locationId: locationFormData['updateLocationForm.locationId'].value.trim(),
    name: locationFormData['updateLocationForm.name'].value.trim(),
    eoId: locationFormData['updateLocationForm.eoId'].value.trim(),
    facilityId: locationFormData['updateLocationForm.facilityId'].value.trim(),
    readPoint: locationFormData['updateLocationForm.readPoint'].value.trim(),
    machineId: locationFormData['updateLocationForm.machineId'].value.trim(),
    codeSpecifics: locationFormData['updateLocationForm.codeSpecifics'].value.trim() as KvintaLocationCodeSpecifics,
  };
}
