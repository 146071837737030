/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaTaskPriority {
    High = 'HIGH',
    Low = 'LOW',
    Standard = 'STANDARD'
}

export function KvintaTaskPriorityFromJSON(json: any): KvintaTaskPriority {
    return KvintaTaskPriorityFromJSONTyped(json, false);
}

export function KvintaTaskPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskPriority {
    return json as KvintaTaskPriority;
}

export function KvintaTaskPriorityToJSON(value?: KvintaTaskPriority | null): any {
    return value as any;
}

