import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID } from 'kvinta/components';
import { reaction } from 'mobx';
import { IEditState } from 'kvinta/common';
import { TaskProductSummaryForm } from './TaskProductSummaryForm';
import { LoadingBackground } from '../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../common/HistoryStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { EHistoryFunctionName, TaskHeaderTabs } from './TaskHeaderTabs';
import { STORE_ID, TaskProductStore } from './TaskProductStore';
import { FormControlButtons } from '../../components/FormControlButtons/FormControlButtons';
import { formDataToButtonState } from '../../common/formUtils/utils';
import { ProductSummaryView } from '../configuration/products/ProductSummaryView';

interface ViewTaskSummaryProps {
  historyStore?: HistoryStore;
  intl: any;
  taskProductStore?: TaskProductStore;
  sidebarStore?: KSidebarStore;
  taskId: string;
  productId: string;
}

class ProductSummaryImpl extends Component<ViewTaskSummaryProps, IEditState> {
  disposer: any;

  componentDidMount() {
    this.props.taskProductStore.fetchProductSummaryData(this.props.taskId, this.props.productId);
    this.disposer = reaction(
      () => this.props.taskProductStore.currentTask,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateTaskListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.taskProductStore.closeUpdateProduct();
    this.disposer();
  }
  render() {
    const {
      currentTask,
      currentProduct,
      productFormData,
      isLoading,
      productFormIsOpen,
      openUpdateProductForm,
      submitUpdateProduct,
      cancelUpdateTaskProduct,
      handleFormBlur,
      handleFormChange,
      addMember,
      removeMember,
      moveMemberDown,
      moveMemberUp,
    } = this.props.taskProductStore;

    const intl = this.props.intl;

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryTabMenuHeader}>
          <TaskHeaderTabs
            currentTask={currentTask}
            taskId={this.props.taskId}
            initialValue={EHistoryFunctionName.ProductListPath}
          />
        </div>
        <div className={styles.summaryMain}>
          {isLoading && <LoadingBackground />}
          <FormControlButtons
            buttonState={formDataToButtonState(productFormData)}
            formIsOpen={productFormIsOpen}
            onCancel={() => cancelUpdateTaskProduct(this.props.taskId, this.props.productId)}
            onConfirm={() => submitUpdateProduct(this.props.taskId, this.props.productId)}
            onOpen={openUpdateProductForm}
          />
          {productFormIsOpen
            ? productFormData && (
                <TaskProductSummaryForm
                  intl={intl}
                  onBlur={handleFormBlur}
                  onChange={handleFormChange}
                  formData={productFormData}
                  addMember={addMember}
                  removeMember={removeMember}
                  moveMemberDown={moveMemberDown}
                  moveMemberUp={moveMemberUp}
                />
              )
            : currentProduct && <ProductSummaryView formData={currentProduct} intl={intl} />}
        </div>
      </div>
    );
  }
}

export const TaskProductSummary = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ProductSummaryImpl)),
);
