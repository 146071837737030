/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGeneratedEpcCode
 */
export interface KvintaGeneratedEpcCode {
    /**
     * 
     * @type {string}
     * @memberof KvintaGeneratedEpcCode
     */
    longCode: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGeneratedEpcCode
     */
    shortCode: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGeneratedEpcCode
     */
    primaryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGeneratedEpcCode
     */
    shortCodeForPrimary?: string | null;
}

export function KvintaGeneratedEpcCodeFromJSON(json: any): KvintaGeneratedEpcCode {
    return KvintaGeneratedEpcCodeFromJSONTyped(json, false);
}

export function KvintaGeneratedEpcCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGeneratedEpcCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longCode': json['longCode'],
        'shortCode': json['shortCode'],
        'primaryCode': !exists(json, 'primaryCode') ? undefined : json['primaryCode'],
        'shortCodeForPrimary': !exists(json, 'shortCodeForPrimary') ? undefined : json['shortCodeForPrimary'],
    };
}

export function KvintaGeneratedEpcCodeToJSON(value?: KvintaGeneratedEpcCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longCode': value.longCode,
        'shortCode': value.shortCode,
        'primaryCode': value.primaryCode,
        'shortCodeForPrimary': value.shortCodeForPrimary,
    };
}


