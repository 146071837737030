/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Load Test Toolkit
 * Swagger for Kvinta Load Test Toolkit
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcCodeType,
    KvintaEpcCodeTypeFromJSON,
    KvintaEpcCodeTypeFromJSONTyped,
    KvintaEpcCodeTypeToJSON,
    KvintaEpcCodeVariable,
    KvintaEpcCodeVariableFromJSON,
    KvintaEpcCodeVariableFromJSONTyped,
    KvintaEpcCodeVariableToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcCodeCreateRequest
 */
export interface KvintaEpcCodeCreateRequest {
    /**
     * 
     * @type {KvintaEpcCodeType}
     * @memberof KvintaEpcCodeCreateRequest
     */
    type: KvintaEpcCodeType;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeCreateRequest
     */
    longCode: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeCreateRequest
     */
    shortCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeCreateRequest
     */
    primaryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcCodeCreateRequest
     */
    shortCodeForPrimary?: string | null;
    /**
     * 
     * @type {Array<KvintaEpcCodeVariable>}
     * @memberof KvintaEpcCodeCreateRequest
     */
    variables: Array<KvintaEpcCodeVariable>;
}

export function KvintaEpcCodeCreateRequestFromJSON(json: any): KvintaEpcCodeCreateRequest {
    return KvintaEpcCodeCreateRequestFromJSONTyped(json, false);
}

export function KvintaEpcCodeCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcCodeCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': KvintaEpcCodeTypeFromJSON(json['type']),
        'name': json['name'],
        'longCode': json['longCode'],
        'shortCode': !exists(json, 'shortCode') ? undefined : json['shortCode'],
        'primaryCode': !exists(json, 'primaryCode') ? undefined : json['primaryCode'],
        'shortCodeForPrimary': !exists(json, 'shortCodeForPrimary') ? undefined : json['shortCodeForPrimary'],
        'variables': ((json['variables'] as Array<any>).map(KvintaEpcCodeVariableFromJSON)),
    };
}

export function KvintaEpcCodeCreateRequestToJSON(value?: KvintaEpcCodeCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': KvintaEpcCodeTypeToJSON(value.type),
        'name': value.name,
        'longCode': value.longCode,
        'shortCode': value.shortCode,
        'primaryCode': value.primaryCode,
        'shortCodeForPrimary': value.shortCodeForPrimary,
        'variables': ((value.variables as Array<any>).map(KvintaEpcCodeVariableToJSON)),
    };
}


