import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

type TTextFieldProps = {
  intl: IntlShape;
  value: string;
  label: string;
  style?: React.CSSProperties;
  hide?: boolean;
};

const TextFieldBase: React.FunctionComponent<TTextFieldProps> = ({ intl, value, label, style, hide }) => (
  <FormControl variant="outlined" fullWidth style={hide ? { ...style, display: 'none' } : style}>
    <InputLabel style={{ backgroundColor: 'white' }}>{intl.formatMessage({ id: label })}</InputLabel>
    <OutlinedInput readOnly={true} label=" " value={value} disabled={value === undefined || value.trim() === ''} />
  </FormControl>
);

export const TextField = injectIntl(TextFieldBase);
