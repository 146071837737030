import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './CheckBoxInput_Exp.module.scss';
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { ECheckboxState } from '../../../common/formUtils/types';

export type TCheckBoxInputProps = {
  intl: any;
  onChange: (id: string, val: string) => void;
  onBlur: (id: string, val: string) => void;
  value: ECheckboxState;
  id: string;
  label: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
};

const CheckBoxInputBase: React.FunctionComponent<TCheckBoxInputProps> = (props) => {
  const {
    id,
    value,
    onChange,
    onBlur,
    touched,
    showError,
    label,
    intl,
    style,
    disabled = false,
    isRequiredField,
  } = props;

  return (
    <FormControl variant="outlined" style={style}>
      <FormControlLabel
        className={styles.formControlLabel}
        htmlFor={id}
        control={
          <Checkbox
            id={id}
            indeterminate={value === ECheckboxState.NOT_SET}
            checked={transformCheckboxState(value)}
            disabled={disabled}
            onChange={(e) => {
              const checked = e.currentTarget.checked;
              onChange(id, generateCheckboxState(checked));
              onBlur(id, generateCheckboxState(checked));
            }}
          />
        }
        label={intl.formatMessage({ id: label })}
      />
      {showError && (
        <div className={styles.error}>
          {props.errors.map((error) => (
            <span key={error}>{intl.formatMessage({ id: error })}</span>
          ))}
        </div>
      )}
    </FormControl>
  );
};

export const CheckBoxInput = injectIntl(observer(CheckBoxInputBase));

export function generateCheckboxState(checked: boolean | undefined | null) {
  switch (checked) {
    case true: {
      return ECheckboxState.CHECKED;
    }
    case false: {
      return ECheckboxState.UNCHECKED;
    }
    case undefined:
    case null: {
      return ECheckboxState.NOT_SET;
    }
  }
}
export function transformCheckboxState(checked: ECheckboxState) {
  switch (checked) {
    case ECheckboxState.CHECKED: {
      return true;
    }
    case ECheckboxState.UNCHECKED: {
      return false;
    }
    case ECheckboxState.NOT_SET: {
      return undefined;
    }
  }
}
