import React, { FC } from 'react';
import { TTaskData } from './TaskProductStore';
import styles from '../../../assets/css/partials/modules/Tasks.module.scss';
import { TextField } from '../../components/FormFields/TextField';

interface DialogCreateTaskProps {
  formData: TTaskData;
}

const TaskSummaryViewBase: FC<DialogCreateTaskProps> = ({ formData }) => {
  if (!formData) return null;
  return (
    <div className={styles.taskContainer}>
      <TextField value={formData.name} label="task.create-form.field.name" />
      <TextField value={formData.priority} label="task.create-form.field.priority" />
    </div>
  );
};

export const TaskSummaryView = TaskSummaryViewBase;
