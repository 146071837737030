import React from 'react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import type { KeycloakProfile } from 'keycloak-js';
import axios from 'axios';
import { Loading } from '../../components/Loading';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import authEnv from 'app-auth-env';
import { useAuth } from './useAuth';
import { baseDomain, cookieName, localStorageAuthKey } from '../../../config';
import { saveTokenToLocalStorage } from '../localStorage';
import { setCookie } from '../cookie';

export interface Profile extends KeycloakProfile {
  accountName: string;
  displayName: string;
  initials: string;
  participantId: string;
  abilityConfig?: any;
  lang: string;
}

const ProfileContext = createContext<Profile>({
  accountName: '',
  displayName: '',
  initials: '',
  participantId: '',
  lang: '',
});

// get Profile data
export const useProfile = () => useContext(ProfileContext);

interface ProfileProps {
  children: ReactNode;
}

export function ProfileProvider({ children }: ProfileProps) {
  const auth = useAuth();
  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    async function initialization() {
      try {
        const [data, tokenData] = await Promise.all([auth.fetchProfile(), auth.fetchToken()]);
        if (!data.emailVerified) {
          throw new Error('User is not enabled');
        }

        const participantId = data.attributes?.participantId?.[0] || '';
        const accountName = data.attributes?.accountName?.[0] || '';
        let displayName = (data.username || data.email || data.id) as string;
        let initials = displayName[0];
        const lang = data.attributes?.lang?.[0] || '';
        if (data.firstName && data.lastName) {
          displayName = `${data.firstName} ${data.lastName}`;
          initials = `${data.firstName[0]}${data.lastName[0]}`;
        }
        const roles = tokenData?.tokenParsed?.realm_access?.roles || [];
        if (!baseDomain) console.error('Environment "KVINTA_BASE_DOMAIN" NOT FOUND');
        setCookie(
          {
            baseDomain: baseDomain,
            cookieName: cookieName,
          },
          tokenData?.token || '',
          1,
        );

        saveTokenToLocalStorage(localStorageAuthKey, tokenData?.token || '');

        const userData = {
          ...data,
          accountName,
          displayName,
          initials,
          participantId,
          lang,
          roles,
        };
        setProfile(userData);
      } catch (e) {
        saveTokenToLocalStorage(localStorageAuthKey, '');
        return auth.logout();
      }
    }

    void initialization();
  }, [auth]);

  if (!profile) {
    return <Loading />;
  }

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
}
