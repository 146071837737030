import React, { FC } from 'react';
import { ILocation, LocationStore } from './LocationStore';
import styles from '../../../../assets/css/partials/modules/Locations.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { useIntl } from 'react-intl';
import { KvintaLocationCodeSpecifics } from '../../../apis/kvinta-load-test-toolkit';

interface ILocationSummaryView {
  formData: ILocation | null;
}

export const LocationSummaryView: FC<ILocationSummaryView> = ({ formData }) => {
  const intl = useIntl();
  if (!formData) return null;
  const hide = formData.codeSpecifics === KvintaLocationCodeSpecifics.Codentify;
  return (
    <div className={styles.formContainer}>
      <TextField value={formData.name} label="location-create-form.field.name" />
      <TextField value={formData.locationId} label="location-create-form.field.locationId" />
      <TextField hide={hide} value={formData.facilityId} label="location-create-form.field.facilityId" />
      <TextField hide={hide} value={formData.eoId} label="location-create-form.field.eoId" />
      <TextField value={formData.readPoint} label="location-create-form.field.read-point" />
      <TextField hide={hide} value={formData.machineId} label="location-create-form.field.machineId" />
      <TextField
        value={intl.formatMessage({
          id: `location-enum.codeSpecifics.${formData.codeSpecifics}`,
          defaultMessage: formData.codeSpecifics,
        })}
        label="location-create-form.field.codeSpecifics"
      />
    </div>
  );
};
